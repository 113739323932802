import React from "react";

import { Box, Heading, Text, Grid, Anchor } from "grommet";

import { DocumentPdf, CircleQuestion } from 'grommet-icons';
import {ShowLegend, roleId} from "../components";

const DocumentCard = ({doc}) => (
  <Anchor
    onClick={(ev) => window.open(doc.url)}
    style={{ borderRadius: "4px" }}
    margin="small"
  >
    <Box round="xsmall" border pad="small" gap="xsmall" background="white">
      <Box direction="row" align="center" justify="between">
        <Text>
          <strong>{doc.name}</strong>
        </Text>
        <DocumentPdf/>
      </Box>
      <Box direction="row" align="center" border='top' pad={{ top: '0.5em' }} basis="8em">
        <Text margin={{ left: "xsmall" }} color="dark" size="xsmall">
          {doc.description}
        </Text>
      </Box>
    </Box>
  </Anchor>
)

const documents = [
  {
    id: 'detailed-onboarding',
    name: 'Patient Onboarding',
    description: "Long-form description for patients to use if the patient onboarding print-out is " +
                 "insufficient. Keeping one printed out for use during patient onboarding is advised.",
    url: 'https://mvp.painpoint.dev/onboarding.pdf',
    role: 'staff'
  },
  {
    id: 'patient-creation',
    name: 'Patient Creation',
    description: "Description of how to create a patient account and print the necessary onboarding " +
                 "documents for the patient to begin using the service",
    url: 'https://mvp.painpoint.dev/staff-onboarding.pdf',
    role: 'staff'
  }
]

export const Help = (props)=> (
  <Box
    breakpoint='medium'
    flex={true}
    fill={true}
    pad={{ left: '1.5em', right: '1.5em' }}
    height='auto'
    background="backgroundBody"
  >
    <Box direction="row-responsive" flex={false} width='100%' height="7em">
      <Heading level="2" margin={{ left: "small", top: 'auto', bottom: 'auto' }}>
        <CircleQuestion size="0.9em" color="brand" style={{ paddingTop: "0.25em", paddingRight: "0.25em" }}/> Help
      </Heading>
    </Box>
    <Grid margin={{ horizontal: "small" }} gap="xsmall" columns="15em">
      {
        documents.filter(doc => roleId(doc.role) <= props.user.role)
                 .map((doc) => (<DocumentCard key={doc.id} doc={doc}/>))
      }
    </Grid>
    {props.user.role >= roleId("provider") &&
      <Box width='medium'>
        <ShowLegend/>
      </Box>
    }
  </Box>
)
