import React, {Component} from "react";
import {render} from "react-dom";

import {Router, Route, Switch, withRouter} from "react-router-dom";
import {createBrowserHistory} from "history";
import ReactGA from 'react-ga4';

import {Box, Button, Grommet, Text} from "grommet";
import {
    Bug,
    ContactInfo,
    Login as LoginIcon,
    Logout as LogoutIcon,
    SettingsOption,
    CheckboxSelected as CheckIcon,
    UserAdmin
} from "grommet-icons";

import {theme} from "./theme";

import {ApiCall, AppHeader, FullName, roleString,
        roleId, RouteFilter, Sidebar, UserContext} from "./components";

import {register} from "./createServiceWorker";
import {DashboardIcon, JournalIcon, HistoryIcon, PatientsIcon, AdminIcon, AddPatientIcon} from "./icons";

import {
    Admin,
    Authentication,
    Dashboard,
    Feed,
    Contact,
    Help,
    History,
    MainForm,
    NotFound,
    Patient,
    PatientPrintout,
    Patients,
    Provider,
    Providers,
    Settings,
    Onboarding,
    TestOnboarding,
    TestDashboard,
    Download,
    Delete
} from "./pages";

import Amplify, {Auth} from "aws-amplify";
import config from "./config";

let items = [
    {
        active: true,
        label: "Pain Summaries",
        path: "/",
        exact: true,
        icon: DashboardIcon,
        role: ['provider']
    },
    {
        active: true,
        label: "Pain Journal",
        path: "/journal",
        exact: true,
        icon: JournalIcon,
        role: ['patient']
    },
    {
        active: true,
        label: "Pain History",
        path: "/history/current",
        exact: true,
        icon: HistoryIcon,
        role: ['patient'],
    },
    {
        active: true,
        label: "Patients",
        path: "/patients",
        icon: PatientsIcon,
        role: ['provider']
    },
    {
        active: true,
        label: "Onboarding",
        path: "/onboarding",
        icon: AddPatientIcon,
        role: ['provider']
    },
    {
        active: true,
        label: "Admin Tools",
        path: "/sovereign",
        icon: AdminIcon,
        role: ['admin']
    }
];

const mobile_width = 800;

class AppBody extends Component {
    constructor(props) {
        super(props);
        this.session = undefined;

        const mobile = window.innerWidth < mobile_width;
        this.state = {
            showSidebar: !mobile,
            appName: "PainPoint Dashboard",
            isAuthenticated: false,
            isAuthenticating: true,
            user: {},
            showLegend: false,
            printing: false,
            mobile: mobile,
            reviewLink: false,
            newVersion: false
        };
    }

    setAppDashboard = dashboard => {
        if (dashboard) {
            this.setState({
                appName: "PainPoint Dashboard"
            });
        } else {
            this.setState({
                appName: "PainPoint Journal"
            });
        }
    }

    updateDimensions = () => {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

        this.setState({windowWidth, windowHeight, mobile: windowWidth < mobile_width});
    }

    async componentDidMount() {
        register((reg) => {
            this.setState({ newVersion: reg });
        });

        try {
            var session = await Auth.currentAuthenticatedUser();
            this.userHasAuthenticated(true, session);
        } catch (e) {
            this.setState({isAuthenticating: false});
        }

        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    attrsFromSession(session) {
        let result = Object.assign({
            attributes: session.signInUserSession.idToken.payload,
            token: async () => await this.fetchToken(),
            session: async () => await this.fetchSession(),
            logout: this.handleLogout,
            update_provider: (provider) => {
                let user = Object.assign({}, this.state.user);
                user.provider = Object.assign(user.provider, provider);
                this.setState({ user: user, reviewLink: provider.google_review_url });
            }
        }, this.state.user);

        if (result.attributes) {
            if (typeof (result.attributes.profile) === "string")
                result.attributes.profile = JSON.parse(result.attributes.profile);
        }
        return result;
    }

    async fetchSession() {
        await this.fetchToken();
        return this.session;
    }

    async fetchToken() {
        const token = this.session.getSignInUserSession().getIdToken();
        if (parseInt(new Date().getTime() / 1000) - 5 > token.getExpiration()) {
            console.log("Token expired.");
            this.session = await Auth.currentAuthenticatedUser();
            this.reauthorize(this.session);
            return this.session.getSignInUserSession().getIdToken();
        }
        return token;
    }

    update_ga() {
        const id = this.state.user?.id;
        ReactGA.set( {user_id: id})
    }

    reauthorize(session) {
        const user_attrs = this.attrsFromSession(session);
        this.setState({user: user_attrs});
    }

    userHasAuthenticated = async (authenticated, session) => {
        var user_attrs = {};
        this.setState({isAuthenticating: false, isAuthenticated: !!authenticated});

        if (!!authenticated) {
            if (typeof (authenticated) === 'string')
                this.props.history.push(authenticated);

            try {
                if (!this.session)
                    this.session = await Auth.currentAuthenticatedUser();

                this.loadUser(this.session);
            } catch (ex) {
                console.log(ex);
            }
        } else {
            user_attrs.role = 0;
            this.setState({user: user_attrs}, () => this.update_ga());
        }
    }

    loadUser = async (session, done) => {
        if (!session)
            session = await Auth.currentAuthenticatedUser();

        const user_attrs = this.attrsFromSession(session);

        try {
            const dbuser = await ApiCall(user_attrs, 'get', '/users/current?features=true&provider=true');
            const user = {...user_attrs, ...dbuser};
            this.setState({user}, () => this.update_ga());
        }
        catch (ex) {
            console.log("......");
            // this.handleLogout();
            this.setState({user: {...user_attrs}}, () => this.update_ga());
            console.log(ex);
        }

        if (!!done)
            done();

    }

    handleLogout = async event => {
        await Auth.signOut();
        this.setState({ user: {} }, () => this.update_ga());

        this.userHasAuthenticated(false);
        this.props.history.push('/login');
    }

    componentDidUpdate() {
        if (this.context && this.context.signInUserSession) {
            const token = this.context.signInUserSession.idToken;
            if (parseInt(new Date().getTime() / 1000) + 500 > token.getExpiration()) {
                this.reauthorize();
            }
        }

        const {printing} = this.state;
        if (printing === true) {
            window.print();
            this.setState({printing: 'done'})
        }
    }

    onToggleSidebar = () => {
        this.setState({showSidebar: !this.state.showSidebar});
    }

    setLegend = (val) =>
        this.setState({showLegend: val})

    setPrinting = (val) =>
        this.setState({printing: val})

    gotoLogin = (history) =>
        history.push("/login")

    topRightMenu = (history) => {
        const goto = (path) => {
            return () => this.props.history.push(path);
        };
        return [
            {
                label: "Pain Summaries",
                icon: (<DashboardIcon color="#5a5a5a" size="20px"/>),
                onClick: goto("/"),
                role: ['provider'],
                mobileOnly: true
            },
            {
                label: "Pain Journal",
                icon: (<JournalIcon color="#5a5a5a" size="20px"/>),
                onClick: goto(`/journal?uuid=${this.state.user.uuid}`),
                role: ['patient'],
                mobileOnly: true
            },
            {
                label: "Pain History",
                icon: (<HistoryIcon color="#5a5a5a" size="20px"/>),
                onClick: goto("/history/current"),
                role: ['patient'],
                mobileOnly: true
            },
            {
                label: "Patients",
                icon: (<PatientsIcon color="#5a5a5a" size="20px"/>),
                onClick: goto("/patients"),
                role: ['provider'],
                mobileOnly: true
            },
            {
                label: "Admin Tools",
                icon: (<UserAdmin color="#5a5a5a" size="20px"/>),
                onClick: goto("/sovereign"),
                role: ['admin'],
                mobileOnly: true
            },
            {
                label: "Provider Info",
                icon: (<ContactInfo color="#5a5a5a" size="20px"/>),
                onClick: goto("/providers/current"),
                role: ['patient', 'provider'],
                mobileOnly: false
            },
            {
                label: "Onboarding",
                icon: (<AddPatientIcon color="#5a5a5a" size="20px"/>),
                onClick: goto("/onboarding"),
                role: ['provider'],
                mobileOnly: true
            },
            {
                label: "Settings",
                icon: (<SettingsOption color="#5a5a5a" size="20px"/>),
                onClick: goto("/settings"),
                role: ['patient', 'provider'],
                mobileOnly: false
            },
            // {
            //     label: "Help",
            //     icon: (<HelpIcon color="brandLight" size="20"/>),
            //     onClick: goto("/help"),
            //     role: ['provider', 'patient'],
            //     mobileOnly: false
            // },
            {
                label: 'Contact Us',
                icon: (<Bug color="#5a5a5a" size="20px"/>),
                onClick: goto("/contact"),
                role: ['noauth', 'patient', 'provider', 'admin'],
                mobileOnly: false
            },
            {
                label: 'Leave a Google Review',
                icon: (<CheckIcon color='#5a5a5a' size='20px'/>),
                onClick: () => window.open(this.state.reviewLink, '_blank'),
                role: ['no_auth', 'patient'],
                condition: () => !!this.state.reviewLink,
                mobileOnly: false
            },
            // {
            //     label: 'Get the App!',
            //     icon: (<DownloadIcon color='brandLight' size='20'/>),
            //     onClick: goto("/download"),
            //     role: ['no_auth', 'patient'],
            //     mobileOnly: false
            // },
            {
                label: 'Logout',
                icon: (<LogoutIcon color='#5a5a5a' size='20px'/>),
                onClick: this.handleLogout,
                role: ['undefined', 'patient', 'provider', 'admin'],
                mobileOnly: false
            },
            {
                label: 'Login',
                icon: (<LoginIcon color="#5a5a5a" size='20px'/>),
                onClick: () => this.gotoLogin(this.props.history),
                role: ['no_auth'],
                mobileOnly: false
            }
        ].filter((item) => {
            return ((this.state.mobile || !item.mobileOnly) && ((item.condition && item.condition()) || !item.condition) &&
                item.role.includes(roleString(this.state.user.role))) || this.state.user.role === roleId('admin');
        })
    }

    updateRole = (id) => {
        let user = Object.assign({}, this.state.user);
        user.role = id;
        this.setState({user});
    }

    setReviewLink = (link) => {
        this.setState({ reviewLink: link });
    };

    render() {
        const {showSidebar, showLegend, printing, user, mobile} = this.state;
        items[1].path = `/journal?uuid=${this.state.user.uuid}`;

        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            setAppDashboard: this.setAppDashboard,
            setLegend: this.setLegend,
            setPrinting: this.setPrinting,
            printing: printing,
            name: FullName(user),
            items: this.topRightMenu,
            history: this.props.history,
            user: user,
            loadUser: this.loadUser,
            mobile: this.state.mobile,
            updateRole: this.updateRole
        };

        return (
            !this.state.isAuthenticating &&
            <Grommet theme={theme} full id='docroot'>
                <Box fill>
                    {!printing && this.state.isAuthenticated &&
                    <AppHeader
                        appName={this.state.appName}
                        screenWidth={this.state.windowWidth}
                        mobile={mobile}
                        userSession={childProps}
                        onToggleSidebar={this.onToggleSidebar}
                        iconsOnly={!mobile && !showSidebar}
                    />
                    }
                    <UserContext.Provider value={this.state.user}>
                        <Box direction="row" flex>
                            {!mobile && !printing && childProps.isAuthenticated && (
                                <Sidebar
                                    items={items}
                                    onToggleSidebar={this.onToggleSidebar}
                                    showLegend={showLegend}
                                    user={this.state.user}
                                    screenWidth={this.state.windowWidth}
                                    mobile={mobile}
                                    iconsOnly={!mobile && !showSidebar}
                                />
                            )}
                            {(this.state.user.role !== undefined || !this.state.isAuthenticated) &&
                            <Box flex>
                                { (this.state.newVersion) &&
                                    <Box style={{
                                             borderRadius: '0.75em',
                                             backgroundColor: '#FAFAFA',
                                             margin: '1em 2em',
                                         }}>
                                        <Box flex={false} align="start" margin="1em"
                                             direction='row-responsive'>
                                            <Text margin='.3em'>
                                                New Version Available!
                                            </Text>
                                            <Button
                                                primary
                                                pad={'0.25em'}
                                                margin={{left: '1em'}}
                                                color={theme.global.primary}
                                                onClick={() => { this.state.newVersion(); }}
                                                label={<Text color='white'>Click Here to Refresh</Text>}
                                                style={{borderRadius: '0.5em', color: '#5a5a5a' }}
                                            />
                                        </Box>
                                    </Box>
                                }
                                <Switch>
                                    <RouteFilter
                                        path="/journal" exact
                                        any={MainForm}
                                        props={{setReviewLink: this.setReviewLink, ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/j" exact
                                        any={MainForm}
                                        props={{setReviewLink: this.setReviewLink, ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/login/reset" exact
                                        patient='/'
                                        no_auth={Authentication}
                                        props={{auth_state: 'reset', ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/login/confirm" exact
                                        patient='/'
                                        no_auth={Authentication}
                                        props={{auth_state: 'confirm_reset', ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/login/change" exact
                                        patient='/'
                                        no_auth={Authentication}
                                        props={{auth_state: 'force_change', ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/login" exact
                                        patient='/'
                                        no_auth={Authentication}
                                        props={{auth_state: 'login', ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/signup" exact
                                        patient='/'
                                        no_auth={Authentication}
                                        props={{auth_state: 'signup', ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/signup/confirm" exact
                                        patient='/'
                                        no_auth={Authentication}
                                        props={{auth_state: 'confirm', ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/signup/resend" exact
                                        patient='/'
                                        no_auth={Authentication}
                                        props={{auth_state: 'resend', ...childProps}}
                                    />
                                    <RouteFilter
                                        path="/" exact
                                        provider={Dashboard}
                                        patient={"/journal?uuid=" + user.uuid}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/feed" exact
                                        provider={Feed}
                                        patient={"/journal?uuid=" + user.uuid}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/download" exact
                                        no_auth={Download}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/delete" exact
                                        no_auth={Delete}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/history/:pid"
                                        provider={History}
                                        patient={History}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/patients/:pid"
                                        provider={Patient}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/providers/:pid"
                                        patient={Provider}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/providers" exact
                                        admin={Providers}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/settings" exact
                                        patient={Settings}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/onboarding" exact
                                        provider={Onboarding}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/onboarding_" exact
                                        provider={TestOnboarding}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/_" exact
                                        provider={TestDashboard}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/patients"
                                        provider={Patients}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/contact"
                                        patient={Contact}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/help" exact
                                        patient={Help}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/settings"
                                        patient={Settings}
                                        no_auth={Authentication}
                                        props={childProps}
                                    />
                                    <RouteFilter
                                        path="/sovereign"
                                        admin={Admin}
                                        props={childProps}
                                    />
                                    <Route component={NotFound}/>
                                </Switch>
                            </Box>}
                        </Box>
                    </UserContext.Provider>
                </Box>
            </Grommet>
        );
    }
}

let history = createBrowserHistory();

class App extends Component {
    componentDidMount() {
        ReactGA.initialize(process.env.REACT_APP_STAGE === 'production' ? 'G-JWF4FM6VZD' :
                                                                                            'G-XNT25XT08D');

        history.listen((location, action) => {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        });
    }

    render() {
        return (<Router history={history}>
            <Grommet theme={theme} full id='docroot'>
                <Switch>
                    <Route
                        path="/patients/:pid/printout"
                        component={PatientPrintout}
                    />
                    <Route component={withRouter(AppBody)}/>
                </Switch>
            </Grommet>
        </Router>);
    }
}

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        authenticationFlowType: config.cognito.FLOW_TYPE
    },
    API: {
        endpoints: [
            {
              name: "PainPointMVP",
              endpoint: config.apiGateway.URL,
              region: config.apiGateway.REGION,
              custom_header: async () => {
                const session = await Auth.currentSession();
                return { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
              }
            },
            {
              name: "PainPointMVP-NoAuth",
              endpoint: config.apiGateway.URL,
              region: config.apiGateway.REGION,
              custom_header: async () => {
                return {}
              }
            },
        ]
    },
    Storage: {
         bucket: config.s3.BUCKET,
         region: config.s3.REGION,
         identityPoolId: config.cognito.IDENTITY_POOL_ID
    }
});

render(
    <App/>
    , document.getElementById("root"));
