import React from "react";
import {Table, Column, AutoSizer} from 'react-virtualized';

import {Box} from "grommet";

import {
    EventDialog,
    ListContainer, ListHeader, ListItem, LoadingItem, PageHeader, PatientItem,
    fillWithDefaults, PatientMobileItem, SearchBar, SearchBarButton,
    ToDate,
    UserContext,
    VerificationDialog,
    DashboardGuidePrompts
} from "../components";

import {Close} from 'grommet-icons';
import FuzzySearch from "fuzzy-search";
import {AddPatientIcon, PrinterIcon, RefreshIcon} from "../icons";
import {Route} from "react-router-dom";

const rightAuto = {right: 'auto'};

const headers = [
    {
        name: 'Alert',
        class: 'priority-1'
    }, {
        name: "Name",
        class: "priority-3"
    }, {
        name: "Last Entry",
        class: "priority-4"
    }, {
        name: 'Entries',
        class: 'priority-1',
        margin: rightAuto
    }, {
        name: 'Well-being',
        align: 'end',
        class: 'priority-1',
        margin: rightAuto
    }, {
        name: 'Pain',
        align: 'center',
        class: 'priority-2',
        margin: rightAuto
    }
];

function genPatients() {
    return [{
        "id": 8,
        "first_name": "Winston",
        "last_name": "K",
        "uuid": "e3f6a7fc-23ef-4a34-9f70-4550f34f176b",
        "peak": null,
        "alert_level": 0,
        "message_count": 0,
        "last_alert": "2021-05-15T20:04:18",
        "last_event": "2021-05-15T20:04:18",
        "num_events": 3,
        "status": {
            "pain:transitions": [{
                "event": 1230,
                "pain:location": "foot",
                "pain:trend": "worse",
                "pain:level": 100,
                "pain:level:delta": 21,
                "event:datetime": "2021-05-15T20:04:18",
                "event:last": 19
            }, {
                "event": 30,
                "pain:location": "shoulder",
                "pain:trend": "worse",
                "pain:level": 78,
                "pain:level:delta": 78,
                "event:datetime": "2019-09-01 01:27:12",
                "event:last": 0
            }, {
                "event": 19,
                "pain:location": "foot",
                "pain:trend": "worse",
                "pain:level": 79,
                "pain:level:delta": 79,
                "event:datetime": "2019-08-26 20:29:32",
                "event:last": 0
            }], "pain:peak": {"foot": 100, "shoulder": 78}
        }
    }, {
        "id": 498,
        "first_name": "S",
        "last_name": "Patel",
        "uuid": "7949c5dc-0ccd-4335-a696-d310588733bd",
        "peak": null,
        "alert_level": 0,
        "message_count": 0,
        "last_alert": "2021-11-12T20:40:14",
        "last_event": "2021-11-12T20:22:35",
        "num_events": 18,
        "status": {
            "pain:transitions": [{
                "event": 1437,
                "pain:location": "wrist",
                "pain:trend": "worse",
                "pain:level": 59,
                "pain:level:delta": 59,
                "event:datetime": "2021-11-12T20:22:35",
                "event:last": null
            }, {
                "event": 1436,
                "pain:location": "neck",
                "pain:trend": "worse",
                "pain:level": 56,
                "pain:level:delta": 56,
                "event:datetime": "2021-11-12T20:18:15",
                "event:last": null
            }, {
                "event": 1435,
                "pain:location": "head",
                "pain:trend": "worse",
                "pain:level": 82,
                "pain:level:delta": -16,
                "event:datetime": "2021-11-12T18:59:19",
                "event:last": 1412
            }, {
                "event": 1434,
                "pain:location": "upper-back",
                "pain:trend": "better",
                "pain:level": 0,
                "pain:level:delta": 0,
                "event:datetime": "2021-11-12T17:17:36",
                "event:last": 1433
            }, {
                "event": 1433,
                "pain:location": "upper-back",
                "pain:trend": "better",
                "pain:level": 0,
                "pain:level:delta": -70,
                "event:datetime": "2021-11-12T17:11:54",
                "event:last": 1425
            }],
            "pain:peak": {
                "arm": "96",
                "chest": "37",
                "head": 82,
                "low back": "49",
                "mid-back": "42",
                "neck": 56,
                "upper-back": 0,
                "wrist": 59
            }
        }
    }, {
        "id": 386,
        "first_name": "Julie",
        "last_name": "S",
        "uuid": "db55465f-c444-4ff3-8869-dae8934cb740",
        "peak": null,
        "alert_level": 0,
        "message_count": 0,
        "last_alert": "2020-10-26T13:41:40",
        "last_event": "2020-10-26T13:41:38",
        "num_events": 5,
        "status": {
            "pain:transitions": [{
                "event": 1045,
                "pain:location": "hip",
                "pain:trend": "better",
                "pain:level": 91,
                "pain:level:delta": 91,
                "event:datetime": "2020-10-26 13:41:38",
                "event:last": 0
            }, {
                "event": 973,
                "pain:location": "shoulder",
                "pain:trend": "same",
                "pain:level": 59,
                "pain:level:delta": 59,
                "event:datetime": "2020-05-05 20:02:07",
                "event:last": 0
            }],
            "pain:events": [{
                "pain:location": "hip",
                "pain:trend": "better",
                "pain:level": 91,
                "event": 1045
            }, {"pain:location": "shoulder", "pain:trend": "same", "pain:level": 59, "event": 973}],
            "pain:peak": {"shoulder": 59, "hip": 91}
        }
    }, {
        "id": 501,
        "first_name": "Hannah",
        "last_name": "L",
        "uuid": "ea44579e-17bb-4cdc-b266-96fb31ac696b",
        "peak": null,
        "alert_level": 0,
        "message_count": 0,
        "last_alert": "2021-11-04T00:51:55",
        "last_event": "2021-10-16T15:35:54",
        "num_events": 1,
        "status": {
            "pain:transitions": [{
                "event": 1420,
                "pain:location": "low back",
                "pain:trend": "worse",
                "pain:level": 90,
                "pain:level:delta": 90,
                "event:datetime": "2021-10-16T15:35:54",
                "event:last": null
            }], "pain:peak": {"low back": 90}
        }
    }, {
        "id": 107,
        "first_name": "Sanderson",
        "last_name": "P",
        "uuid": "c9b114c9-9b5e-44d4-a5e2-f62e87145ba0",
        "peak": null,
        "alert_level": 0,
        "message_count": 0,
        "last_alert": "2020-04-02T17:42:29",
        "last_event": "2020-04-02T17:42:29",
        "num_events": 12,
        "status": {
            "pain:transitions": [{
                "event": 242,
                "pain:location": "arm",
                "pain:trend": "worse",
                "pain:level": 86,
                "pain:level:delta": 86,
                "event:datetime": "2020-04-02 17:42:29",
                "event:last": 0
            }, {
                "event": 240,
                "pain:location": "neck",
                "pain:trend": "worse",
                "pain:level": 84,
                "pain:level:delta": 84,
                "event:datetime": "2020-04-01 17:26:04",
                "event:last": 0
            }, {
                "event": 188,
                "pain:location": "chest",
                "pain:trend": "worse",
                "pain:level": 49,
                "pain:level:delta": 49,
                "event:datetime": "2020-03-24 21:14:25",
                "event:last": 0
            }, {
                "event": 174,
                "pain:location": "upper-back",
                "pain:trend": "worse",
                "pain:level": 89,
                "pain:level:delta": 89,
                "event:datetime": "2020-03-23 14:00:10",
                "event:last": 0
            }],
            "pain:events": [{
                "pain:location": "arm",
                "pain:trend": "worse",
                "pain:level": 86,
                "event": 242
            }, {
                "pain:location": "neck",
                "pain:trend": "worse",
                "pain:level": 84,
                "event": 240
            }, {
                "pain:location": "chest",
                "pain:trend": "worse",
                "pain:level": 49,
                "event": 188
            }, {"pain:location": "upper-back", "pain:trend": "worse", "pain:level": 89, "event": 174}],
            "pain:peak": {"upper-back": 89, "chest": 49, "neck": 84, "arm": 86}
        }
    }];
}

const addPatientMargin = {left: '0em', top: '0em'};

const DashboardContents = ({headers, ...props}) => {
    const {mobile, loading, failed, patients, onClear, onInspect, printing, widths} = props;
    const DashItem = mobile ? PatientMobileItem : PatientItem;

    if (loading || failed)
        return <ListContainer>
            <LoadingItem failed={failed} loading={loading}
                         pad='0.5em'
                         label="Loading patient information..."
                         width='100%'
            />
        </ListContainer>

    return (
        <ListContainer>
            <AutoSizer>
                {({width, height}) => {
                    return <Table
                        noRowsRenderer={() => <ListItem> No patients found. </ListItem>}
                        overscanRowCount={10}
                        height={height}
                        headerHeight={mobile ? 0 : 49}
                        rowGetter={({index}) => patients[index]}
                        rowCount={patients.length}
                        rowHeight={mobile ? 230 : 90}
                        width={width} estimatedRowSize={width}>
                        <Column
                            dataKey="name"
                            cellDataGetter={(rowData) => rowData}
                            headerRenderer={() => <ListHeader headers={headers}/>}
                            cellRenderer={({rowIndex, rowData: patient}) => {
                                return <DashItem mobile={mobile}
                                                 patient={fillWithDefaults(patient)}
                                                 onClear={onClear}
                                                 onInspect={onInspect}
                                                 printing={printing}
                                                 index={rowIndex}
                                                 widths={widths}/>
                            }}
                            width={width}
                        />
                    </Table>;
                }
                }
            </AutoSizer>
        </ListContainer>
    );
}

export class TestDashboard extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            refreshing: false,
            loading: true,
            failed: false,
            verify: {},
            patients: [],
            sorted: 'DESC',
            search: ''
        };
    }

    shouldAutoReload = false;

    async refresh() {
        let {patients, refreshing} = this.state;

        if (refreshing) {
            try {
                patients = JSON.parse(document.getElementById('patients').value);
                for (let x = 0; x < patients.length; ++x) {
                    patients[x].id = x + 1;
                }
            } catch (ex) {
                alert("Invalid JSON.");
            }
        }

        this.setState({
            refreshing: !this.state.refreshing,
            patients: this.sortByEntry(this.state.sorted, patients)
        });
    }

    async componentDidMount() {
        this.props.setLegend(true);
        this.loadData();
    }

    componentWillUnmount() {
        this.props.setLegend(false)
        this.shouldAutoReload = false
    }

    loadData() {
        this.setState({
            refreshing: false,
            loading: false,
            patients: this.sortByEntry(this.state.sorted, genPatients())
        });
    }

    sortEntries = () => {
        let next = {"ASC": "DESC", "DESC": "ASC"}[this.state.sorted];
        this.setState({sorted: next, patients: this.sortByEntry(next, this.state.patients)});
    }

    resetAlertLevel = async (id) => {
        // update user by id
    }

    verifyReset = async (patient, alerts) => {
        this.setState({
            verify: {
                patient: patient,
                limit: alerts,
                alerts: true
            }
        })
    }

    inspectEvent = (patient, eid, location) => {
        this.setState({
            event_selected: {
                event_id: eid,
                patient: patient,
                limit: 5,
                location: location
            }
        });
    }

    searchPatient = (search) => {
        this.setState({search});
    }

    shouldDisplay = (patient) => {
        return true;
    }

    filterPatients = (patients) => {
        if (this.state.search) {
            const searcher = new FuzzySearch(patients, ['short_uuid', 'first_name', 'last_name']);
            return searcher.search(this.state.search);
        }
        return patients;
    }

    sortByEntry = (sort, patients) => {
        if (sort === '')
            return patients;

        const pts = [...patients]
        const descend = (sort === "DESC");
        const descend_val = descend ? -1 : 1;
        console.log(`Sort: ${sort}`);

        return pts.sort((a, b) => {
            if (a.message_count > b.message_count) {
                return descend_val;
            }

            if (a.message_count < b.message_count) {
                return -1 * descend_val;
            }

            if (a.alert_level > b.alert_level) {
                return descend_val;
            }

            if (a.alert_level < b.alert_level) {
                return -1 * descend_val;
            }

            if (a.peak > b.peak) {
                return descend_val;
            }

            if (a.peak < b.peak) {
                return -1 * descend_val;
            }

            if (a.last_event === null) {
                return 1;
            }

            if (b.last_event === null) {
                return -1;
            }

            const l = ToDate(a.last_event), r = ToDate(b.last_event)

            // shuffle nevers to the bottom
            if (a.last_event === l) return 1
            if (b.last_event === r) return -1

            return descend_val * (l - r)
        })
    }

    render() {
        const {refreshing, verify, patients, event_selected, loading, failed} = this.state;
        const {mobile, printing, setPrinting, user} = this.props;
        const widths = (mobile ? ["auto", "auto", "auto", "auto", "auto", "auto"] :
            (printing ? ["2em", "8em", "4em", "10em", "10em", "3em"] :
                ["3.4em", "10em", "6em", "15.5em", "15.5em", "3em"]));

        const local_headers = headers.map((header, i) => {
            header.width = widths[i];
            return header;
        });

        const sorted_patients = this.filterPatients(this.state.patients);

        return (
            <Box
                breakpoint='medium'
                flex={true}
                fill={true}
                height='auto'
                background={printing ? "white" : "backgroundBody"}
            >
                <DashboardGuidePrompts user={user} location={this.props.location}/>

                <PageHeader label="Active Patient">
                    {!printing &&
                    <SearchBar onSearch={(event) => this.searchPatient(event)}>
                        {!mobile && <>
                            <SearchBarButton icon={<PrinterIcon size="16" color='#969696'/>}
                                             onClick={event => setPrinting(!printing)}/>
                        </>}
                        <Route render={({history}) => (
                            <SearchBarButton
                                icon={<Box margin={addPatientMargin}><AddPatientIcon size="16" color='#969696'/></Box>}
                                onClick={event => {
                                    history.push("/onboarding?add=true");
                                }}/>
                        )}/>
                        <SearchBarButton
                            icon={<Box margin={{top: '0.2em'}}><RefreshIcon size="16" color='#969696'/></Box>}
                            onClick={() => this.refresh()}/>
                    </SearchBar>
                    }
                    {printing &&
                    <SearchBar onSearch={false}>
                        <SearchBarButton
                            icon={<Close size="1em" color='brand'/>}
                            id='no-print'
                            onClick={event => setPrinting(false)}
                        />
                    </SearchBar>
                    }
                </PageHeader>

                {refreshing &&
                <textarea id="patients" rows='200'>
                    {JSON.stringify(patients, undefined, 2)}
                </textarea>
                }
                <EventDialog event={event_selected}
                             onClose={() => this.setState({event_selected: false})}
                             mobile={mobile}/>
                <VerificationDialog content={verify}
                                    onClose={() => this.setState({verify: {}})}
                                    onConfirm={() => this.resetAlertLevel(verify.patient.id)}
                                    mobile={mobile}/>

                <DashboardContents mobile={mobile}
                                   headers={local_headers}
                                   loading={loading}
                                   failed={failed}
                                   patients={sorted_patients}
                                   onClear={this.verifyReset}
                                   onInspect={this.inspectEvent}
                                   border={{color: "light-4", side: "bottom"}}
                                   printing={printing}
                                   widths={widths}/>
            </Box>
        );
    }
}
