import React from "react";

import {
    Box, Button, Text, TextInput,
    Menu, FormField, CheckBox, Anchor
} from "grommet";

import {
    FinalizeDialog,
    DaysSinceToday,
    List,
    ListItem,
    ListContainer,
    ListHeader,
    UserContext,
    roleId, LoadingButton,
    MatchPhone, MatchEmail, CleanPhoneInput, CleanEmailInput, OnboardingGuidePrompts
} from "../components";

import {
    More,
    Trash,
    Chat,
    Magic,
    Checkmark
} from 'grommet-icons';

import { PrettyPhone, SearchBar, SearchBarButton, PageHeader } from '../components';
import {AddPatientIcon, RefreshIcon} from '../icons';
import { theme } from "../theme";
import {Route} from "react-router-dom";
import queryString from "query-string";

function ToDate(x) {
    return x;
}

function SignedUp({last, time, past_due}) {
    if (!time)
        return (<li className={past_due ? 'due first' : 'active first'}>
            <Text size='12px'>Creating
                <wbr/>
                Account</Text>
        </li>);

    return (<li className={'complete first'}>
        <Text size='12px'>Account<wbr/>Created</Text>
    </li>);
}

function LoggedIn({last, time, past_due}) {
    if (!time) {
        const incStyle = past_due ? 'due' : (!last ? 'inactive' : 'active');
        return (<li className={incStyle}>
            <Text size='12px'>First
                <wbr/>
                Login</Text>
        </li>);
    }
    return (<li className={'complete'}>
        <Text size='12px'>First<wbr/>Login</Text>
    </li>);
}

function Linked({time, unlinked, rejected, last, past_due}) {
    if (time)
        return (<li className={'complete'}>
            <Text size='12px'>Account<wbr/>Linked</Text>
        </li>);

    if (unlinked)
        return (<li className={'due'}>
            <Text size='12px'>Account<wbr/>Unlinked</Text>
        </li>);

    if (rejected)
        return (<li className={'due'}>
            <Text size='12px'>Invite<wbr/>Rejected</Text>
        </li>);

    const incStyle = past_due ? 'due' : (!last ? 'inactive' : 'active');
    return (<li className={incStyle}>
        <Text size='12px'>Invite<wbr/>Sent</Text>
    </li>);
}

function Journaled({time, last, past_due}) {
    if (!time) {
        const incStyle = past_due ? 'due' : (!last ? 'inactive' : 'active');
        return (<li className={`${incStyle} last`}>
            <Text size='12px'>Journal
                <wbr/>
                Entry</Text>
        </li>);
    }

    return (<li className={!last ? 'inactive last' : 'complete last'}>
        <Text size='12px'>Journal<wbr/>Entry</Text>
    </li>);
}

const check_due = (date) => {
    return date < 0;
};

function Status({created_at, signed, login, linked, unlinked, rejected, journal}) {
    return (<ul className={"progress"}>
        <SignedUp time={signed} past_due={check_due(created_at)}/>
        <LoggedIn time={login} last={!!signed} past_due={check_due(signed)}/>
        <Linked time={linked} unlinked={unlinked} rejected={rejected} last={!!login} past_due={check_due(login)}/>
        <Journaled time={journal} last={!!linked} past_due={check_due(linked)}/>
    </ul>);
}

function ActionMenu({ patient, onFinalize, props }) {
    const [ requested, setRequested ] = React.useState(false);
    return (
        <Menu
          items={[
            {
              label: <Box alignSelf="center">Send AppStore Link</Box>,
              icon: (
                  <Box pad="xsmall">
                      <Chat size="small" color="darkgreen"/>
                  </Box>
              ),
              onClick:
                () => {
                }
            },
            { label: <Box alignSelf="center">Send Magic Link</Box>,
              disabled: requested,
              icon: (
                  <Box pad="xsmall">
                      <Magic size="small" color={requested ? 'red' : 'darkgreen'}/>
                  </Box>
              ),
              onClick:
                () => {
                    setRequested(true);
                }
            },
            {
              label: <Box alignSelf="center">Remove from Onboarding</Box>,
              icon: (
                  <Box pad="xsmall">
                      <Trash size="small" color="green"/>
                  </Box>
              ),
              onClick: () => { onFinalize(patient); }
            },
          ]}
        >
            <Box pad={{horizontal: "small"}}>
                <More size={"24px"}/>
            </Box>
        </Menu>);
}

const percentComplete = (patient) => {
    if (!!patient.journal) {
        if (!patient.linked)
            return "75";
        return "100";
    }

    if (!!patient.linked)
        return "75";

    if (!!patient.login)
        return "50";

    if (!!patient.signup)
        return "25";

    return "0";
};

const rowMargin = { top: 'auto', bottom: 'auto' };
const rowMarginFinal = { top: 'auto', bottom: 'auto', left: 'auto' };
const rowPad = { right: '0.5em' };

const container_style = { width: '44px', height: '44px', marginTop: '20px' };
const icon_size = "18px";

const OnboardingRow = ({patient, index, onFinalize, ...rest}) => {
    const complete = !!patient.journal && !!patient.linked;
    return (
        <ListItem justify="between"
                  align="start"
                  width='100%'
                  className='patient-data-row'
                  round='0.75em'
                  {...rest}
        >
            <Box direction='column' width='100%'>
                <Box direction='row' width='100%' height='4em'>
                    <Box direction="row"
                         align="start"
                         basis="2em"
                         margin={rowMargin}
                         pad={rowPad}
                         flex={false}
                         className="priority-1"
                    >
                        <Text size="14px"
                              style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          { complete &&
                            <Box style={container_style}>
                              <Checkmark size={icon_size} color="Green"/>
                            </Box>
                          }
                        </Text>
                    </Box>
                    <Box direction="column"
                         align="start"
                         basis="16em"
                         margin={rowMargin}
                         pad={rowPad}
                         flex={false}
                         className="priority-1"
                    >
                        <Text size="16px"
                              style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {!!patient.linked &&

                            <Route {...rest} render={props => {
                                return (<Anchor label={patient.name} onClick={() => {
                                    props.history.push("/history/" + patient.user_id);
                                }}/>);
                            }}/>
                            }
                          {!patient.linked && patient.name}
                        </Text>
                        <Text size="13px">
                            {PrettyPhone(patient.phone)}
                        </Text>
                        <Text size="13px"
                              style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {patient.email}
                        </Text>
                    </Box>

                    <Box direction="row"
                        align="start"
                        basis="5em"
                        flex={false}
                        margin={rowMargin}
                        pad={rowPad}
                        className="priority-5"
                        >
                          <Text size="14px">
                              {patient.days}
                          </Text>
                    </Box>

                    <Box direction="row"
                        align="start"
                        basis="5em"
                        flex={false}
                        margin={rowMargin}
                        pad={rowPad}
                        className="priority-5"
                        >
                          <Text size="18px">
                              {percentComplete(patient)}%
                          </Text>
                    </Box>

                    <Box direction="row"
                         align="start"
                         width="22em"
                         flex={false}
                         margin={rowMargin}
                         pad={rowPad}
                         className="priority-1"
                    >
                        <Status created_at={ToDate(patient.created_at)}
                                signed={ToDate(patient.signup)}
                                login={ToDate(patient.login)}
                                linked={ToDate(patient.linked)}
                                unlinked={ToDate(patient.unlinked)}
                                rejected={ToDate(patient.rejected)}
                                journal={ToDate(patient.journal)}/>
                    </Box>

                    <Box direction="row"
                         align="start"
                         basis="4em"
                         margin={rowMarginFinal}
                         flex={false}
                         className="priority-1"
                    >
                        <ActionMenu patient={patient} onFinalize={onFinalize}/>
                    </Box>
                </Box>
            </Box>
        </ListItem>
    );
}

const NewUserPane = ({onCancel, onCreate, show, training, error}) => {
    const [ name, setName ] = React.useState('');
    const [ phone, setPhone ] = React.useState('');
    const [ email, setEmail ] = React.useState('');
    const [ send_link, setSendLink ] = React.useState(false);

    const _userValid = () => {
        return (name || '').length > 0 && MatchPhone(phone) && MatchEmail(email);
    }

    const _setName = (event) => {
        const value = event?.target?.value?.substring(0, 255);
        setName(value);
    }

    const _setEmail = (event) => {
        const value = CleanEmailInput(event?.target?.value?.substring(0, 255));
        setEmail(value);
    }

    const _setPhone = (event) => {
        const value = CleanPhoneInput(event?.target?.value?.substring(0, 32));
        setPhone(value);
    }

    const _setSendLink = (event) => {
        setSendLink(event?.target?.checked);
    }

    if (!show) return null;

    return (
    <Box flex={false} width='100%'>
        <Box direction='row'
             fill={'horizontal'}
             flex={false}
             style={newPatientStyle}
        >
            <Box direction="row-responsive"
                 background='#FBFBFB'
                 pad={'1.5em'}
                 style={{borderRadius: '1em', width: '100%'}}
            >
                <Box direction='row' className="priority-5-block" flex={false}>
                    <Box flex={false}>
                        <FormField label={<Text size="12px">Name</Text>}
                                   htmlFor="name"
                                   style={fieldStyle}
                        >
                            <TextInput
                                placeholder='John Doe'
                                value={name || ''}
                                name='name'
                                onChange={_setName}
                            />
                        </FormField>
                    </Box>
                    <Box flex={false}>
                        <FormField label={<Text size="12px">Phone Number</Text>}
                                   htmlFor="phone"
                                   style={fieldStyle}>
                            <TextInput placeholder='2565551234'
                                       value={PrettyPhone(phone)}
                                       name='phone'
                                       onChange={_setPhone}
                            />
                        </FormField>
                    </Box>
                    <Box flex={false}>
                        <FormField label={<Text size="12px">Email</Text>}
                                   htmlFor="email"
                                   style={fieldStyle}>
                            <TextInput placeholder='email@example.com'
                                       value={email || ''}
                                       name='email'
                                       onChange={_setEmail}
                            />
                        </FormField>
                    </Box>
                </Box>


                <Box direction="column">
                    <Box direction='row'
                         flex={'shrink'}
                         margin={newContainerMargin}
                    >
                        <Button
                            label="Cancel"
                            onClick={() => {
                                setName('');
                                setPhone('');
                                setEmail('');
                                setSendLink(false);
                                onCancel();
                            }}
                            style={cancelStyle}
                        />
                        <LoadingButton
                            primary
                            highlight={training}
                            color={theme.global.colors.brand}
                            type='submit'
                            label={<Text color={'white'}>Create</Text>}
                            disabled={ !_userValid() }
                            onClick={(done) => {
                                onCreate({name, email, phone}, send_link, () => {
                                    setName('');
                                    setPhone('');
                                    setEmail('');
                                    setSendLink(false);
                                    done();
                                });
                            }}
                            style={createStyle}
                        />
                    </Box>
                    <Box direction="row-responsive"
                         flex={false}
                         margin={{left: 'auto', right: 'auto', top: '1.5em'}}>
                            <CheckBox
                                named="send_link"
                                checked={send_link}
                                label={(<Text size='12px' style={{'textTransform': 'capitalize'}}>Send AppStore Link</Text>)}
                                onChange={_setSendLink}
                            />
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box align="end" width="58em">
            <Text color="#D1626D">{error}</Text>
        </Box>
    </Box>);
};

const newContainerMargin = {left: 'auto', right: 'auto', top: '1em'};
const fieldStyle = {marginBottom: 0, marginRight:'1em', marginTop: 0, width: '13em'};
const addPatientMargin = {left: '0em', top: '0em'};

const newPatientStyle = { marginTop: '1.5em', maxWidth: '58em', overflow: 'hidden' };
const createStyle = { marginLeft: '0.5em', marginTop: 'auto', marginBottom: 'auto', borderRadius: '0.5em' };
const cancelStyle = { marginRight: '0.5em', marginTop: 'auto', marginBottom: 'auto', borderRadius: '0.5em', color: '#5a5a5a' };

const leftAuto = {left: 'auto'};

const headers = [{
    name: "",
    width: "2em",
    class: "priority-1"
}, {
    name: "Patient",
    width: "16em",
    class: "priority-1"
}, {
    name: "Elapsed Days",
    width: "5em",
    class: "priority-5",
}, {
    name: "% Complete",
    width: "5em",
    class: "priority-5",
}, {
    name: "Patient Onboarding Progress",
    width: "22em",
    class: "priority-1"
}, {
    name: "Actions",
    width: "4em",
    margin: leftAuto,
    class: "priority-1"
}];

const testPatients = () => {

                   // C   S   L   N  U  R   J
    const args = [  [ 1,  0,  0,  0, 0, 0,  0, "John Doe"],
                    // [-1,  0,  0,  0, 0, 0,  0, "Dinq Creation"],
                    // [ 1,  1,  0,  0, 0, 0,  0, "Await Login"],
                    // [ 1, -1,  0,  0, 0, 0,  0, "Dinq Login"],
                    [ 1,  1,  1,  0, 0, 0,  0, "Jane Doe"],
                    // [ 1,  1, -1,  0, 0, 0,  0, "Dinq Link"],
                    // [ 1,  1,  1,  1, 0, 0,  0, "Await Journal"],
                    // [ 1,  1,  1, -1, 0, 0,  0, "Dinq Journal"],
                    [ 1,  1,  1,  1, 0, 0,  1, "Jeremy Doe"],
                    // [ 1,  1,  1,  0, 1, 0,  0, "Unlinked No Journal"],
                    // [ 1,  1,  1,  0, 0, 1,  0, "Rejected No Journal"],
                    // [ 1,  1,  1,  0, 1, 0,  1, "Unlinked Journal"],
                    // [ 1,  1,  1,  0, 0, 1,  1, "Rejected No Journal"],
                    // [ 1,  0,  0,  1, 0, 0,  0, "[Special] Old User Linked"],
                    // [-1,  0,  0,  1, 0, 0,  0, "[Dinq] Old User Linked"],
                    // [ 1,  0,  0,  0, 1, 0,  0, "[Special] Old User Unlinked"],
                    // [-1,  0,  0,  0, 1, 0,  0, "[Dinq] Old User Unlinked"],
                    // [ 1,  0,  0,  1, 0, 0,  1, "[Special] Old User Linked Journal"],
                    // [-1,  0,  0,  1, 0, 0,  1, "[Dinq] Old User Linked Journal"],
                    // [ 1,  0,  0,  0, 1, 0,  1, "[Special] Old User Unlinked Journal"],
                    // [-1,  0,  0,  0, 1, 0,  1, "[Dinq] Old User Unlinked Journal"]
    ];

    return args.map((arg, index) => {
        let p = {
            id: index,
            name: arg[7],
            email: "A" + index + "@example.com",
            phone: "+155555555" + String(index).padStart(2, '0'),
            days: "< 1 day",
            created_at: arg[0],
            signup:     arg[1],
            login:      arg[2],
            linked:     arg[3],
            unlinked:   arg[4],
            rejected:   arg[5],
            journal:    arg[6]
        }
        console.log(p);
        return p;
    });
}

export class TestOnboarding extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        const values = props.location ? queryString.parse(props.location.search) : {};

        this.state = {
            loading: false,
            refreshing: false,
            failed: false,
            search: '',
            new_user: !!values.add,
            new_error: false,
            patients: testPatients()
        };
    }

    async refresh() {
        let { patients, refreshing } = this.state;

        if ( refreshing ) {
            try {
                patients = JSON.parse(document.getElementById('patients').value);
                for ( let x = 0; x < patients.length; ++x) {
                    patients[x].id = x+1;
                }
            } catch(ex) {
                alert("Invalid JSON.");
            }
        }

        this.setState({
            refreshing: !this.state.refreshing,
            patients
        });
    }

    _createPatient(patient, success) {
        patient = {
            ...patient,
            id: this.state.patients.length + 1,
            created_at: 1,
            signup:     0,
            login:      0,
            linked:     0,
            unlinked:   0,
            rejected:   0,
            journal:    0,
            days: "< 1 day"

        };
        const patients = [patient, ...this.state.patients];
        this.setState({new_user: false, new_error: false, patients});
        success();
    }

    _cancelNewUser() {
        this.setState({new_user: false, user: {}, new_error: false})
    }

    render() {
        const isAdmin = this.props.user.role === roleId('admin');
        const adminAction = isAdmin ? () => this.adminDetach() : undefined;
        const { patients, finalize_user, new_user, new_error, refreshing} = this.state;
        const {user} = this.props;
        const training = !(!user || !user.provider || roleId("provider") >= user.role || user.provider.num_trackers > 0);

        return (
            <Box breakpoint='medium'
                 flex={true} fill={true}
                 pad={{right: '1.5em'}}
            >
                <OnboardingGuidePrompts user={user} location={this.props.location}/>
                <PageHeader label="Onboarding">
                    <SearchBar onSearch={()=>{}}>
                        <SearchBarButton icon={<Box margin={addPatientMargin}><AddPatientIcon size="16px" color='white'/></Box>}
                                         disabled={new_user}
                                         label={" Add Patient"}
                                         highlight={training && !new_user}
                                         primary
                                         onClick={event => {
                                            this.setState({new_user: true});
                                         }}/>
                        <SearchBarButton icon={<Box margin={{top: '0.2em'}}><RefreshIcon size="16" color='#969696'/></Box>}
                                     onClick={() => this.refresh()}/>
                    </SearchBar>
                </PageHeader>

                {refreshing &&
                <textarea id="patients" rows='200'>
                    {JSON.stringify(patients, undefined, 2)}
                </textarea>
                }
                <NewUserPane show={new_user}
                             onCreate={(user, link, success) => {
                                 this._createPatient(user, success);
                             }}
                             onCancel={() => this._cancelNewUser()}
                             training={training}
                             error={new_error} />

                <FinalizeDialog user={finalize_user} adminAction={adminAction}
                                onClose={() => this.setState({finalize_user: false})}
                                onConfirm={this.deletePatient}
                                mobile={this.props.mobile}/>

                <ListContainer>
                    <List flex={false} overlfow={{horizontal: 'scroll'}}>
                        <ListHeader headers={headers}/>
                        {(patients || []).map((patient, index) =>
                            <OnboardingRow
                                key={'key-' + patient.id + patient.email}
                                patient={patient}
                                index={index}
                                onFinalize={patient => this.initFinalizeUser(patient)}
                            />
                        )}
                    </List>
                </ListContainer>
            </Box>
        );
    }
}
