import React, {useState, useContext} from "react";

import {
  Box,
  Text,
  Button,
  TextArea,
} from "grommet";

import {
  Loading, FullName, FirstName, UserContext, JournalLink,
  FeatureBlock, ApiCall, PageHeader, SearchBar, SearchBarButton, EntryContainer,
  DateTime, MessageContainer, PainPanel, EntryDetails, ActionPanel, InfoContainer,
  InfoBadge, CommentBlock
} from "../components";

import {Route} from "react-router-dom";
import {PlusIcon, RefreshIcon, ActivityIcon} from "../icons";
import {Close} from "grommet-icons";
import {theme} from "../theme";

const verticalAuto = {vertical: 'auto'};
const marginBottom = {bottom: '1em'};
const rightAuto = {right: 'auto'};
const borderTop = {size: '1px', color: '#E0E0E0', side: 'top'};
const messageContainerPad = {horizontal: '1em', top: '1.5em'};

const NoEntries = ({events, patient, pid, user, onSubmit}) => (
    <Box width="100%">
      <Box flex={false}
           direction="column"
           margin={entryMargin}
           background="#FCFCFC"
           round="1em"
           width="58em"
           border={entryBorder}>
        <EntryContainer>
          <NoEntriesBanner/>
        </EntryContainer>
        <FeatureBlock user={user} feature='sms-comms'>
          <Box pad={{top: '1em', ...messageContainerPad}}
               border={borderTop}>
            {events.messages.length === 0 ?
                <CommentField patient={patient}
                              pid={pid}
                              onSubmit={onSubmit}/> :
                <PatientCommunications onSubmit={onSubmit} entries={events.messages}
                                       messages={[]} last={false} first={true} patient={patient}
                                       pid={pid} user={user}
                />
            }
          </Box>
        </FeatureBlock>
      </Box>
    </Box>
);

const NoEntriesBanner = () => (
    <Box fill={false} flex={false}
         margin={{top: 'auto', bottom: 'auto'}}>
        No Journal Entries Submitted
    </Box>
);


function CommentField({patient, pid, onSubmit, ...props}) {
  const [comment, setComment] = useState('');
  const [sending, setSending] = useState(false);
  const context = useContext(UserContext);

  const placeholder =
      patient.com_notifications ? (patient.phone.length !== 12 ? "This patient does not have a valid phone number on file."
                                                              : "Type here to send a message to " + patient.first_name)
                                : "This patient has opted out of receiving notifications.";
  const limit = patient.com_notifications ? (patient.phone.length !== 12 ? 0 : 800) : 0;

  const submit = (e)=> {
    e.preventDefault();
    setSending(true)
    const body = {
        message: comment.toString()
    };

    ApiCall(context, 'post', `/users/${pid}/message`, body)
        .then(result => {
          setSending(false)
          setComment('')
          onSubmit();
        })
        .catch(result => {
          console.log(result);
        })
  }

  const text_input_style = {
    borderRadius: "0.5em", backgroundColor: 'white',
    marginBottom: '0.75em',
    borderColor: '#DEDEDE', maxWidth: '55.5em'
  };

  return (
    <form onSubmit={submit}>
      <Box margin={{top: '0em'}}
           fill={true}
           background="#FCFCFC"
           direction='column'
           pad={{bottom: '1.5em', horizontal: '0.25em', top: '0.25em'}}
           {...props}
      >
        <TextArea style={text_input_style}
                   value={comment}
                   onChange={(event)=> setComment(event.target.value.slice(0, limit))}
                   placeholder={placeholder}/>
        <Box>
           <Button type="submit"
                label={<Text color='white'>{sending ? <Loading size="20px" fade={false}/> : "Send"}</Text>}
                primary
                style={{borderRadius: "0.25em"}}
                disabled={sending || !patient.com_notifications || patient.phone.length !== 12 || comment.length === 0}
                margin={{right: 'auto'}}/>
        </Box>
      </Box>
    </form>)
}

function MessageBox({entry, patient, is_patient, box_settings, text_margins, ...props}) {
  const name = is_patient ? FirstName(patient) : ( entry.alias || entry.first_name || 'Provider');
  return (<Box {...box_settings} flex={false}>
    <Box pad='1em'>
      <Box direction='row' margin={{ bottom: '0.5em', ...text_margins}}>
        <Text margin={{ right: '0.75em' }} size='14px'>
          <b>{name}</b>
        </Text>
        <Text  size='14px' color='#BCBCBC'>
          <DateTime date={entry.created_at}/>
        </Text>
      </Box>
      <Text size='14px'>
        {entry.event}
      </Text>
    </Box>
  </Box>)
}

function PatientCommunications({entries, messages, patient, first, last, onSubmit, user, pid, ...props}) {
  let scrolled = false;
  if (entries.length === 0 && messages.length === 0) {
    // Don't bother displaying the fields if there were no messages.
    if (!first)
      return []

    entries.push({
      id: 0,
      no_messages: true
    })
  }

  const renderMap = (entry, i) => {
    /*
     *  From  Is      Is
     *  Staff User    Sender
     *  0     0       1
     *  0     1       0
     *  1     0       0
     *  1     1       1
     */

    const is_staff_entry = ( entry.staff_id !== null );
    const is_patient_sender = ( patient.id === user.id && !is_staff_entry );
    const is_staff_sender = ( user.id === entry.staff_id );
    const is_sender = ( is_patient_sender || is_staff_sender );

    let opt = {
      direction: 'row',
      pad: {left: '0.25em', right: '0.25em', top: '0.25em', bottom: '0.125em'},
      border: {size: '1px', color: '#EFEFEF'},
      style: { maxWidth: '70%' }
    };

    const alignLeft = { margin: {left: '0.5em', right: 'auto', bottom: '1em'} };
    const alignRight = { margin: {left: 'auto', right: '0.5em', bottom: '1em'} };

    if (!is_staff_entry) {
      opt = {
        ...opt,
        ...alignLeft,
        background: '#F5F4F4'
      };
    } else {
      opt = {
        ...opt,
        ...alignRight,
        background: 'white'
      };
    }

    if (!is_sender) {
      opt = {
        ...opt,
        ...alignLeft,
      };
    } else {
      opt = {
        ...opt,
        ...alignRight,
      };
    }

    if (entry.event_read && !is_sender) {
      opt = {
        ...opt,
        border: {size: '1px', color: '#679E2A'},
      }
    }

    opt.round = '0.75em';

    if (entry.no_messages && first)
      return (<MessageContainer key={"message-" + entry.id}>
          <Box
               align="center"
               margin={marginBottom}>
            No Messages
          </Box>
        </MessageContainer>)

    return (<MessageBox key={"message-" + entry.id}
                        entry={entry} box_settings={opt} text_margins={rightAuto}
                        is_patient={!is_staff_entry} patient={patient}/>);
  };

  const renderMessages = () => {
    var results = []
    if (last) {
      if (messages !== undefined)
        results.push(...messages.map(renderMap))
    }
    results.push(...entries.map(renderMap))
    return results
  }

  return (
      <Box height="auto" flex={false}>
          <Box style={{overflow: 'auto'}}
               ref={(ref)=>{ if(!scrolled && ref) { ref.scrollTo(0, ref.scrollHeight); scrolled = true }}}>
              { renderMessages() }
          </Box>
          {first &&
              <CommentField patient={patient}
                            pid={pid}
                            onSubmit={onSubmit}
                            {...props}/>
          }
      </Box>
  );

/*<Box width='100%' pad='0.5em'>
<b>Uploads:</b>
<Grid columns='250px' margin='small' pad='1em' gap='1em'>
{(index == 3 || index == 4) &&
<Box width='90%' flex={false} fill={false}>
<video controls style={{
width: "100%",
position: 'relative',
            top: '-1px',
            left: '-1px',
            marginBottom: '-1px',
            padding: 0
          }}>
            <source
                src={ index == 4 ? 'https://painpoint-test-bucket-2783gsdhf.s3.amazonaws.com/PresentWrap.mp4'
                    : 'https://painpoint-test-bucket-2783gsdhf.s3.amazonaws.com/Vegetables.mp4' }
                type="video/mp4"/>
            Sorry, your browser doesn't support embedded videos.
          </video>
        </Box>
        }
        {(index==2) &&
        <Box width='90%' flex={false} fill={false}>
          <img
              src="https://painpoint-test-bucket-2783gsdhf.s3.amazonaws.com/LoadingDishwasher.png"
              style={{width: "100%"}}/>
        </Box>
        }
      </Grid>
    </Box>
  </Box>  */
}


const entryMargin = {bottom: "2em", right: "2em"};
const entryBorder = {color: '#EDEDED'};

const JournalEntry = ({entry, mobile, ...rest}) => {

  const recent = entry.event['pain:recent'] === "yes";

  const reason = entry.event['pain:exercise:reason'];
  const activity = entry.event['pain:activity'];
  const comment = entry.event['pain:comments'];
  const level = entry.event['pain:level'];
  const trend = entry.event['pain:trend'];
  const location = entry.event['pain:location'];
  const exercise = entry.event['pain:exercise'] === "yes";

  const { user } = rest;

  const has_info = !!activity || !!comment || !!reason;
  const has_badge_and_comment = (!!activity || !!reason) && !!comment;

  return (
    <Box flex={false}
         direction="row-responsive"
         margin={entryMargin}
         background="#FCFCFC"
         round="1em"
         width="58em"
         border={entryBorder}>
      <Box fill={false}
           width="100%"
           style={{overflow: 'hidden'}}
      >
        <Box direction='row-responsive' pad='1em'>
          <PainPanel location={location} level={level} trend={trend}
                     alert={entry.alert} exercise={exercise}
                     action={mobile} recent={recent}
          />
          <EntryDetails event={entry.event} date={entry.created_at}/>
          <ActionPanel hide={mobile}
                       alert={entry.alert} exercise={exercise}
                       recent={recent}/>
        </Box>

        <InfoContainer has_content={has_info}>
          <Box direction={'row'} margin={{bottom: (has_badge_and_comment) ? '1em' : '0'}}>
            <InfoBadge icon={<ActivityIcon size={16}/>} content={activity}/>
            <InfoBadge icon={<Close size="16px" color={theme.global.colors.iconGrey}/>} content={reason}/>
          </Box>
          <CommentBlock content={comment}/>
        </InfoContainer>

        { (entry.messages.length > 0 || rest.first) &&
          <FeatureBlock user={user} feature='sms-comms'>
            <Box pad={messageContainerPad}
                 border={borderTop}>
              <PatientCommunications entries={entry.messages}
                                     {...rest}/>
            </Box>
          </FeatureBlock>
        }
      </Box>
    </Box>);
}

const plusIconMargins = {left: '0.6em', top: '0.0em'};
const containerMargin = {top: '1em'};

export class History extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const pid = props.computedMatch.params.pid;
    const patient = pid === 'current' ? props.user : false;

    this.state = {
      pid,
      patient,
      refreshing: false,
      loading: true,
      failed: false,
      verify: {},
      events: [],
      video: false,
      image: false
    };
  }

  async refresh() {
    this.setState({
      refreshing: true
    }, () => this.loadEvents());
  }

  async loadEvents() {
    var query = [];

    if (this.state.limit) query.push('limit=' + this.state.limit)
    if (this.state.location) query.push('location=' + this.state.location)

    const preprocessEvents = (entries, count) => {
      let current = {};
      current.messages = [];
      current.entries = [];

      for (var x in entries) {
        var event = entries[x];
        delete entries[x];

        if (event.event_type === 'message') {
          const is_user = this.state.patient.id === this.context.id;
          const from_staff = (event.staff_id !== null);

          /*
           *  From  Is      Should
           *  Staff Patient Highlight
           *  0     0       1
           *  0     1       0
           *  1     0       0
           *  1     1       1
           */

          const should_highlight = !(from_staff ^ is_user);

          event.event_read = should_highlight && !event.event_read;
          current.messages.unshift(event)
        } else if (event.event_type === 'journal') {
          event.messages = [...current.messages]
          current.messages = []
          current.entries.push(event)
        }
      }

      return current;
    }

    const resetMessageCount = (id) => {
      ApiCall(this.context, 'post', `/users/${id}/clear/messages`)
        .then(result => {}).catch(error => {});
    };

    const loadEventsWithPatient = (patient) => {
      ApiCall(this.context, 'get', `/users/${this.state.pid}/events?` + query.join('&'))
        .then(result => {
          var ppe = preprocessEvents(result, patient.message_count);
          this.setState({
            events: ppe,
            refreshing: false,
            loading: false,
            patient: patient
          });
          resetMessageCount(this.state.pid);
        }).catch(error => {
          this.setState({
            events: {
              entries: [],
              messages: []
            },
            failed: true,
            refreshing: false,
            loading: false,
            patient});
        });
    };

    try {
      if (!this.state.patient) {
        ApiCall(this.context, 'get', `/users/${this.state.pid}`)
            .then(patient => {
              loadEventsWithPatient(patient);
            }).catch(error => {
          this.setState({failed: true});
        });
      } else {
        loadEventsWithPatient(this.state.patient);
      }


    } catch(ex) {
      console.log(ex);
    }
  }

  async componentDidMount() {
    this.loadEvents();
  }

  async componentDidUpdate() {
  }

  render() {
    const {events, loading, patient, failed, refreshing, pid} = this.state;
    const {mobile} = this.props;
    let entries = events.entries;

    return (
      <Box
        fill={true}
        pad={{horizontal: '1em'}}
        height='auto'
        width={'58em'}
        background="backgroundBody"
      >
        <PageHeader label={`${FullName(patient)} History`} width='58em'>
            <SearchBar onSearch={false}>
              {!mobile &&
              <Route render={({ history }) => (
                  <SearchBarButton
                               icon={<Box margin={plusIconMargins}><PlusIcon size="16" color='#969696'/></Box>}
                               label="Submit Journal"
                               onClick={event => {
                                 history.push(JournalLink(this.state.patient, false));
                               }}/>

              )}/>
              }
              <SearchBarButton
                           primary
                           icon={<Box margin={{top: '0.2em'}}><RefreshIcon size="16" color='white'/></Box>}
                           disabled={refreshing}
                           onClick={() => this.refresh()}/>
            </SearchBar>
        </PageHeader>


        {(loading || failed) &&
          <Box width="58em"
               margin={entryMargin}
          >
            <Loading failed={failed} loading={loading}
                     pad='0.5em'
                     label="Loading patient information..."
                     width='100%'
            />
          </Box>
        }
        {!loading && !failed &&
        <Box flex={true}
             alignItems={"stretch"}
             style={{overflow: 'auto'}}
             margin={containerMargin}
        >
          {entries.length === 0  ?
            <NoEntries events={events} patient={patient} pid={pid}
                       user={this.props.user} onSubmit={() => this.refresh()}/> :
            entries.map((entry, index) =>
                <JournalEntry entry={entry}
                              index={index}
                              key={'journal-' + entry.id}
                              first={index === 0}
                              last={index === entries.length - 1}
                              messages={events.messages}
                              patient={patient}
                              pid={pid}
                              user={this.props.user}
                              onSubmit={() => this.refresh()}
                              mobile={mobile}
                />)
          }
        </Box>
        }
      </Box>
    );
  }
}

