import React from "react";

import {Box, Button, Text} from "grommet";
import {UserMenu} from ".";

import {
    Down as UserIcon
} from "grommet-icons";

import { PainPointLogo } from '../icons';

export const AppHeader = ({
                              appName,
                              userSession,
                              onToggleSidebar,
                              iconsOnly,
                              mobile,
                              screenWidth,
                              ...props
                          }) => {
    return (
        <Box
            tag="header"
            direction="row"
            align="center"
            responsive={false}
            pad={'1em'}
            style={{
                position: "relative",
                marginTop: '1em'
            }}
        >
            <Box fill="vertical"
                 flex={false}
                 width={mobile ? "10em" : "17.25em"}>
                <Button
                    onClick={onToggleSidebar}
                    width="medium"
                    margin={{top: 'auto', bottom: 'auto'}}>
                    <Box
                        flex={false}
                        direction="row"
                        align="center"
                        margin={{left: "small"}}
                        pad={{ top: '1em', bottom: '1em', left: '0.5em', right: '0.1em' }}
                    >
                        <Box height="2em">
                            <PainPointLogo/>
                        </Box>
                    </Box>
                </Button>
            </Box>
            <Box direction='row' fill={true} style={{borderBottom: '1px solid #EDEDED'}}>
                <Box direction="row" align="center" margin={{right: 'auto'}} flex={false} fill={false}>
                    {!userSession.mobile &&
                    <Text margin={{right: 'auto'}} size="24.5px" color="#1D1D1D">
                        {userSession?.user?.provider?.name || ''}
                    </Text>
                    }
                </Box>

                <Box direction="row" align="end" margin={{left: 'auto'}} flex={false} fill={false}>
                    {/* <Box
                            margin={{left: "medium"}}
                            round="xsmall"
                            background={{color: "white", opacity: "weak"}}
                            direction="row"
                            align="center"
                            pad={{horizontal: "small"}}
                            />
                        <FormSearch color="white" />
                        <TextInput plain placeholder="Search" type="search" />
                    */}
                    <UserMenu
                        alignSelf="center"
                        user={{name: userSession.name, thumbnail: UserIcon}}
                        items={userSession.items(props.history)}
                    />
                </Box>
            </Box>
        </Box>
    )
}
