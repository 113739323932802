import React from "react";

import { Box, Heading, Image } from "grommet";

const Pitch = () => (
  <Box
    width="25em"
    height="7em"
    flex={false}
    margin={{ horizontal: "3em", bottom: "1em" }}
  >
    To delete your account and all associated data, email{" "}
    <a href="mailto:info@painpointanalytics.com">info@painpointanalytics.com</a>{" "}
    from the email address associated with your account with a subject line of "Delete Me".
  </Box>
);

const HeaderLogo = ({ embedded }) => (
  <Box
    width="10em"
    height="2.5em"
    margin={
      embedded && { left: "1.5em", top: "1.5em", right: "2em", bottom: "1em" }
    }
  >
    <Image src="/painpoint-patient-logo.png" fit="contain" />
  </Box>
);

export const Delete = ({ embedded, ...props }) => {
  return (
    <Box
      fill
      align="center"
      pad={{ top: "large", horizontal: "small" }}
      margin={embedded && { top: "4em" }}
    >
      <Heading textAlign="center" level="2" margin={{ bottom: "1em" }}>
        <HeaderLogo embedded={embedded} />
      </Heading>
      <Pitch />
    </Box>
  );
};
