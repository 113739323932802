import React, { Component } from "react";

import { Grommet, ResponsiveContext, Box, Grid, Heading, Text, Image } from "grommet";

import { QRCode, AndroidInstructions, AppleInstructions, ApiCall, GlobalContext } from "../components";

import { theme } from "../theme";


export function JournalLinkFromUuid(uuid) {
  return window.location.protocol + '//' + window.location.host + '/journal?uuid=' + uuid
}

function journalLink(patient) {
  return JournalLinkFromUuid(patient.uuid)
}

function loadQrCode(id, link) {
  var el = document.getElementById(id)
  if (!el) return
  el.innerHTML = "";
  new QRCode(el, {
        text: link,
        width: 150,
        height: 150,
        colorDark : "#000000",
        colorLight : "#ffffff",
        correctLevel : QRCode.CorrectLevel.H
    });
}

const Loading = () => (
  <Box
    responsive={true}
    flex
    pad='large'
  >
    <PainPointHeader/>
    <Box direction='row'>
      <Box width="24px" height="24px" margin={{ right: '1em' }}>
        <Image src='/loading.gif' fit="cover"/>
      </Box>
      Loading...
    </Box>
  </Box>
)

const PainPointHeader = ({title}) => (
  <Heading
    level="2"
    style={{
      marginBlockStart: 0,
      marginBlockEnd: 0
    }}
  >
    <Grid
      rows={['xsmall']}
      columns={['xsmall', 'medium', 'flex']}
      gap='small'
      areas={[
        { name: 'icon', start: [0, 0], end: [0, 0] },
        { name: 'title', start: [1, 0], end: [1, 0] },
      ]}
    >
      <Box gridArea="icon" width="xsmall" height="xsmall">
        <Image src="/painpoint-icon.png" fit="contain"/>
      </Box>
      <Box gridArea="title">
        Pain Point
        <Heading
          level='3'
          color="dark-2"
          style={{
            marginBlockStart: 0,
            marginBlockEnd: 0
          }}
        >
          <em>Patient {title}</em>
        </Heading>
      </Box>
    </Grid>
  </Heading>
)

const PrintOut = ({patient, provider}) => (
  <Box
    responsive={true}
    flex
    pad='small'
  >
    <PainPointHeader title={'Onboarding Instructions'}/>
    <Box
      border="all"
      round
      pad="small"
      overflow={{ horizontal: 'hidden' }}
    >
      <Grid
        margin={{ left: "small", vertical: "none" }}
        rows={['auto', 'auto', 'auto']}
        columns={['xsmall', 'flex', 'flex']}
        gap='small'
        areas={[
          { name: 'nameLabel', start: [0, 0], end: [0, 0] },
          { name: 'nameValue', start: [1, 0], end: [1, 0] },
          { name: 'emailLabel', start: [0, 1], end: [0, 1] },
          { name: 'emailValue', start: [1, 1], end: [1, 1] },
          { name: 'providerLabel', start: [0, 2], end: [0, 2] },
          { name: 'providerValue', start: [1, 2], end: [1, 2] },
        ]}
      >
        <Box gridArea='nameLabel'>
          <b>Name:</b>
        </Box>
        <Box gridArea='nameValue'>
          {patient.first_name + ' ' + patient.last_name}
        </Box>
        <Box gridArea='emailLabel'>
          <b>E-mail:</b>
        </Box>
        <Box gridArea='emailValue'>
          <a href={"mailto:" + patient.email}>{patient.email}</a>
        </Box>
        {provider &&
          <>
            <Box gridArea='providerLabel'>
              <b>Provider:</b>
            </Box>
            <Box gridArea='providerValue'>
              {provider}
            </Box>
          </>
        }
      </Grid>
      <Box
        border="top"
        margin={{ top: "small" }}
        pad={{ top: "small" }}
      >
        <Grid
          margin={{ left: "small", vertical: "none" }}
          rows={['auto']}
          columns={['auto', 'flex']}
          gap='small'
          areas={[
            { name: 'qrLabel', start: [0, 0], end: [0, 0] },
            { name: 'qrCode', start: [1, 0], end: [1, 0] },
          ]}
        >
          <Box gridArea='qrLabel'>
            <b>Patient Journal:</b>
          </Box>
          <Box gridArea='qrCode'>
            <Grid
              margin={{ left: "small", vertical: "none" }}
              rows={['auto']}
              columns={['small', 'flex']}
              gap='xsmall'
              areas={[
                { name: 'qrCodeImage', start: [0, 0], end: [0, 0] },
                { name: 'qrUrl', start: [1, 0], end: [1, 0] },
              ]}
            >
              <div id="qrCodeId"/>
              <Box gridArea='qrUrl' pad={{ right: '1em' }}>
                <Text size="12px">
                  <a href={JournalLinkFromUuid(patient.uuid)}>
                    {JournalLinkFromUuid(patient.uuid)}
                  </a>
                </Text>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
    <Grid
      margin={{ vertical: "medium" }}
      rows={['auto']}
      columns={['flex', 'flex']}
      gap='small'
      areas={[
        { name: 'android', start: [0, 0], end: [0, 0] },
        { name: 'iphone', start: [1, 0], end: [1, 0] },
      ]}
    >
      <Box
        gridArea='android' align='center' border='all' round
        overflow={{ horizontal: 'hidden' }}
      >
        <AndroidInstructions patient={patient}/>
      </Box>
      <Box
        gridArea='iphone' align='center' border='all' round
        overflow={{ horizontal: 'hidden' }}
      >
        <AppleInstructions patient={patient}/>
      </Box>
    </Grid>
  </Box>
)

export class PatientPrintout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: false,
      loaded: false,
      provider: {},
      patient: {
        uuid: '[loading...]',
        first_name: '',
        last_name: '',
        email: ''
      }
    };
  }

  async componentDidMount() {
    ApiCall(GlobalContext, 'get', '/users/' + this.props.match.params.pid)
      .then(patient => {
        ApiCall(GlobalContext, 'get', '/providers/current')
          .then(provider => this.setState({ provider, patient, loaded: true }))
          .catch(error => {
            console.log(error.response)
          });
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  componentDidUpdate() {
    loadQrCode('qrCodeId', journalLink(this.state.patient))
  }

  static contextType = ResponsiveContext;

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  render() {
    const { patient, loaded, provider } = this.state;

    return (
      !this.state.isAuthenticating &&
      <Grommet theme={theme} full>
          {!loaded ?
            <Loading/>
          :
            <PrintOut patient={patient} provider={provider.name}/>
          }
      </Grommet>
    );
  }
}
