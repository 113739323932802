import {Auth} from "aws-amplify";
import {Box, Form, FormField, TextInput} from "grommet";
import {Lock as PasswordIcon} from "grommet-icons/es6/icons";
import React from "react";

import {AuthenticationPage} from ".";
import {AuthLink, AuthLinkBox, MessageBanner, FormTitle, PasswordReq} from ".";
import {LoadingButton} from "../Loading";

export class ResetConfirm extends AuthenticationPage {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.email,
            code: "",
            new_password: "",
            confirm_password: "",
            error: null
        };
    }

    validate() {
        return this.state.email.length > 0 && this.state.code.length >= 6 &&
            this.state.new_password.length >= 6 &&
            this.state.new_password === this.state.confirm_password;
    }

    handleReset = async (done) => {
        if ( this.state.error )
            this.setState({ error: false });

        try {
            await Auth.forgotPasswordSubmit(
                this.state.email,
                this.state.code,
                this.state.new_password
            );
            this.props.onLogin();
        } catch (e) {
            this.setState({error: e.message});
        }
        done();
    }

    render() {
        const {error, confirm_password, new_password, code, email} = this.state;
        const {message} = this.props;

        return (
            <>
                <FormTitle title="Reset Password" icon={PasswordIcon}/>
                <MessageBanner error={error} message={message}/>
                <Form>
                    <FormField
                        id="email"
                        name="email"
                        label="E-mail"
                        value={email}
                        onChange={this.handleEmail}
                    />
                    <FormField label="Code">
                        <TextInput
                            value={code}
                            id="code"
                            name="code"
                            type="numeric"
                            onChange={this.genCodeHandler(6)}/>
                    </FormField>
                    <FormField
                        id="new_password"
                        name="new_password"
                        label="New Password"
                        type="password"
                        value={new_password}
                        onChange={this.handleChange}
                    />
                    <Box margin={{bottom: '1em'}}>
                        <PasswordReq password={new_password}/>
                    </Box>
                    <FormField
                        id="confirm_password"
                        name="confirm_password"
                        label="Confirm Password"
                        type="password"
                        value={confirm_password}
                        onChange={this.handleChange}
                    />
                    <Box margin={{bottom: '1em'}}>
                        <PasswordReq password={new_password} confirm_password={confirm_password}/>
                    </Box>
                    <LoadingButton
                        primary
                        type="submit"
                        label="Update Password"
                        disabled={!this.validate()}
                        onClick={this.handleReset}
                    />
                </Form>
                <AuthLinkBox>
                    <AuthLink label="Login" onClick={this.props.onLogin}/>
                    <AuthLink label="Reset Password" onClick={this.props.onResetPassword} side="right"/>
                </AuthLinkBox>
            </>
        );
    }
}
