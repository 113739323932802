
// const edge_pool = {
//   USER_POOL_ID: "us-east-1_u8FozprP7",
//   APP_CLIENT_ID: "70arenfcm5e4o06hn4r9391ba1",
//   IDENTITY_POOL_ID: "us-east-1:edc6a758-119a-4a76-b2a1-d666bb56738e",
//   FLOW_TYPE: 'USER_PASSWORD_AUTH'
// };

const edge_pool = {
  USER_POOL_ID: "us-east-1_RJoyI8R64",
  APP_CLIENT_ID: "jqjdmukknndgrpoatti22gkd",
  IDENTITY_POOL_ID: "us-east-1:e30e976a-7653-4430-b35c-03ad5c547607",
  FLOW_TYPE: 'USER_PASSWORD_AUTH'
};

const prod_pool = {
  USER_POOL_ID: "us-east-1_AtO244gY8",
  APP_CLIENT_ID: "1hscob715vt1b544e2dfb9u9d7",
  IDENTITY_POOL_ID: "us-east-1:6a87268a-7813-422f-a2cf-77eee36f731e",
  FLOW_TYPE: 'USER_PASSWORD_AUTH'
};

const s3_info = {
   BUCKET: "pain-point-drop-e5f7d1",
   REGION: "us-east-1"
};

const dev = {
  cognito: {
    REGION: "us-east-1",
    ...edge_pool
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.painpoint.dev/experimental"
    // URL: "http://192.168.1.104:8000"
  },
  s3: {
    ...s3_info
  }
};

const prod = {
  cognito: {
    REGION: "us-east-1",
    ...prod_pool
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.painpoint.dev/v2"
  },
  s3: {
    ...s3_info
  }
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  shortener: {
    KEY: 'AIzaSyDCykySy6vBq5QmD9nYLhuF4-kKZpNtSFw',
    URL: 'https://www.googleapis.com/urlshortener/v1/url'
  },
  ...config
};
