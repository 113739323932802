import React, {Component, useState, useCallback} from "react";

import {
    Box,
    Button,
    Grid,
    Form,
    FormField,
    TextInput,
    Text, Meter,
    Image
} from "grommet";

import {MapLocation, Domain, Phone, Mail, Spa, Lock, Image as ImageIcon} from "grommet-icons";

import {
    QRCode,
    Loading,
    roleId,
    UserContext,
    ApiCall,
    signUpLink,
    FeatureBlock,
    features,
    LoadingButton, PageHeader, ProviderInfoGuidePrompts
} from "../components";

import { shallowEqualObjects } from "shallow-equal";
import {Storage} from "aws-amplify";
import {useDropzone} from "react-dropzone";

function toLink(url) {
    let re = /^http/;
    if (re.test(url))
        return url;
    return "http://" + url;
}

function loadQrCode(id, link) {
  var el = document.getElementById(id);
  if (!el) return;
  el.innerHTML = "";
  new QRCode(el, {
        text: link,
        width: 150,
        height: 150,
        colorDark : "#000000",
        colorLight : "#ffffff",
        correctLevel : QRCode.CorrectLevel.H
    });
}

function getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    // JavaScript months are 0-based.
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "-" + m + "-" + d + "_" + h + "-" + mi + "-" + s;
}

function LogoDropzone(props) {
  const [ progress, setProgress ] = useState(-1);
  const [ error, setError ] = useState(undefined);
  const [ message, setMessage ] = useState(undefined);
  const onDrop = useCallback(acceptedFiles => {
    Storage.put(getFormattedTime() + "_" + acceptedFiles[0].name, acceptedFiles[0],
        {
            level: 'public',
            contentType: 'image/jpeg',
            progressCallback: (progress) => {
                setProgress(progress.loaded/progress.total*100.0);
            }
        })
        .then(result => {
            props.onSave(result);
            setProgress(100);
            setTimeout(()=> setProgress(-1), 1000);
            setMessage('Upload complete.');
            setTimeout(()=> setMessage(undefined), 3000);
        }).catch(err => {
            console.log(err);
            setError("Unable to upload file!");
        });
  }, [props]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false})

  return (
    <div {...getRootProps()} style={{
        margin: '0.5em',
        padding: '0.5em',
        borderRadius: '0.25em',
        border: 'dashed 1px darkgrey',
        backgroundColor: '#f8f8f8',
        flex: 1,
        cursor: 'pointer'
    }}>
      <Box margin='auto'>
      {error &&
        <Text color='error'>{error}</Text>
      }
      {message &&
        <Text color='brandDark'>{message}</Text>
      }
      { progress >= 0 &&
        <Meter
          width='100%'
          values={[{
              value: progress,
              label: `${progress}`
          }]}
          aria-label="meter"
        />
      }
      <input {...getInputProps()}/>
      {
        isDragActive ?
          <p>Drop your logo here.</p> :
          <p>Drag and drop your logo here or click to select it.</p>
      }
      </Box>
    </div>
  )
}

function ProviderDropzone(props) {
  const [ progress, setProgress ] = useState(-1);
  const [ error, setError ] = useState(undefined);
  const [ message, setMessage ] = useState(undefined);
  const onDrop = useCallback(acceptedFiles => {

    Storage.put(getFormattedTime() + "_" + acceptedFiles[0].name, acceptedFiles[0],
        {
            level: 'private',
            progressCallback: (progress) => {
                setProgress(progress.loaded/progress.total*100.0);
            }
        })
        .then(result => {
            setProgress(100);
            setTimeout(()=> setProgress(-1), 1000);
            setMessage('Upload complete.');
            setTimeout(()=> setMessage(undefined), 3000);
        }).catch(err => {
            console.log(err);
            setError("Unable to upload file!");
        });
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false})

  return (
    <div {...getRootProps()} style={{
        margin: '0.5em',
        padding: '0.5em',
        borderRadius: '0.25em',
        border: 'dashed 1px darkgrey',
        backgroundColor: '#f8f8f8',
        flex: 1,
        cursor: 'pointer'
    }}>
      <Box margin='auto'>
      {error &&
        <Text color='error'>{error}</Text>
      }
      {message &&
        <Text color='brandDark'>{message}</Text>
      }
      { progress >= 0 &&
        <Meter
          width='100%'
          values={[{
              value: progress,
              label: `${progress}`
          }]}
          aria-label="meter"
        />
      }
      <input {...getInputProps()}/>
      {
        isDragActive ?
          <p>Drop your file here.</p> :
          <p>Drag and drop your file here or click to select it.</p>
      }
      </Box>
    </div>
  )
}

const ProviderEdit = ({unchanged, updating, failed, provider: provider_orig, onSubmit, onCancel, ...props}) => {
    const [provider, setProvider] = React.useState(Object.assign({}, provider_orig));

    const onChange = (event) => {
        const re = /[0-9]{5}(-[0-9]{4})?$/;
        const val = event.target.value
        const p2 = Object.assign({}, provider);
        p2[event.target.name] = val
        const match = val.trim().match(re)

        if (match)
            p2.zip = parseInt(match[0])

        setProvider(p2);
    };

    return <Box
        flex={false}
        fill={false}
        width='large'
        background='white'
        pad='small'
    >
        <Form border='1px'>

            <FormField label='Clinic Name:'>
                <TextInput
                    name="name"
                    value={provider.name || ''}
                    onChange={onChange}
                />
            </FormField>

            <FormField label='Email:'>
                <TextInput
                    name="email"
                    value={provider.email || ''}
                    onChange={onChange}
                />
            </FormField>

            <FormField label='Website:'>
                <TextInput
                    name="website"
                    value={provider.website || ''}
                    onChange={onChange}
                />
            </FormField>

            <FormField label='Phone:'>
                <TextInput
                    name="phone"
                    value={provider.phone || ''}
                    onChange={onChange}
                />
            </FormField>

            <FormField label='Address:'>
                <TextInput
                    name="address"
                    value={provider.address || ''}
                    onChange={onChange}
                />
            </FormField>

            <FormField label='Google Review Link:'>
                <TextInput
                    name="google_review_url"
                    value={provider.google_review_url || ''}
                    onChange={onChange}
                />
            </FormField>
            <Box>
                {failed &&
                <Text color='error'>An error occurred while updating clinic information.</Text>
                }
                {unchanged &&
                <Text color={'brandAlt'}>No new changes to save.</Text>
                }
                {!unchanged &&
                <Text color={'brandAlt'}>Changes have not been saved.</Text>
                }
            </Box>
            <Box pad={{ top: 'xxsmall' }}>
                <Grid
                    margin={{top: 'medium', bottom: 0}}
                    rows={['2em']}
                    columns={['small', 'small']}
                    gap='small'
                    areas={[
                        {name: 'submit', start: [0, 0], end: [0, 0]},
                        {name: 'cancel', start: [1, 0], end: [1, 0]}
                    ]}
                >
                    <LoadingButton
                        gridArea='submit' primary label={updating ? 'Saving...' : 'Save'}
                        onClick={(done) => onSubmit(provider, done)}
                        disabled={unchanged || updating}
                    />
                    <LoadingButton gridArea='cancel' label='Cancel' onClick={onCancel}/>
                </Grid>
            </Box>
        </Form>
    </Box>;
}

const clinic_style = {left: "-2em", top: '1em'};
const tab_padding = { top: '1em', bottom: '1em', horizontal: '1em' };

const ProviderInfoBox = ({provider, user, onSave, ...props}) => {
  const is_provider = (user.role >= roleId('provider'));

  const [editing, setEditing] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [logoFailed, setLogoFailed] = React.useState(false);

  const updateData = async (provider, done) => {
        const body = Object.assign({}, provider);
        delete body.code;
        ApiCall(user, 'post', `/providers/current`, body)
            .then((result) => {
                onSave(provider);
                setSaved(true);
                setTimeout(() => setSaved(false), 5000);
                setEditing(false);
                setFailed(false);
                done();
            })
            .catch(result => {
                setFailed(true);
                done();
            });
  };

  const logoUpdate = async (upload) => {
        const body = { logo_url: `${process.env.REACT_APP_PUBLIC_URL}/${upload.key}` };
        ApiCall(user, 'post', `/providers/current`, body)
            .then((result) => {
                setLogoFailed(false);
                const prov = Object.assign({}, provider);
                prov.logo_url = body.logo_url;
                onSave(prov);
            })
            .catch(result => {
                setLogoFailed(true);
            });
  };

  return (<Box
        background='white'
        border={{color: "#ededed"}}
        flex={false}
        fill={false}
        pad={tab_padding}
        round="0.75em"
        width='60em'
        margin={{horizontal: '0.5em'}}
    >
        { is_provider &&
            <LogoDrop user={user}
                    onUpdate={logoUpdate}
                    failed={logoFailed}
                    {...props}/>
        }

      {!editing &&
      <Box gridArea='buttons' flex={false}>
          {saved &&
          <Box>
              <Text color={'brandAlt'}>Clinic information saved.</Text>
          </Box>
          }

          <Box pad='none' margin={{right: 'auto', top: '1em'}} direction='row'>
              <Button label="Edit" onClick={() => setEditing(true)}/>
          </Box>
      </Box>
      }

        {!editing &&
        <Grid
            rows={['1em', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
            columns={['1em', 'flex']}
            fill={true}
            gap="medium"
            areas={[
                {name: 'idValue', start: [0, 0], end: [1, 0]},
                {name: 'nameLabel', start: [0, 1], end: [0, 1]},
                {name: 'nameValue', start: [1, 1], end: [1, 1]},
                {name: 'emailLabel', start: [0, 2], end: [0, 2]},
                {name: 'emailValue', start: [1, 2], end: [1, 2]},
                {name: 'websiteLabel', start: [0, 3], end: [0, 3]},
                {name: 'websiteValue', start: [1, 3], end: [1, 3]},
                {name: 'addressLabel', start: [0, 4], end: [0, 4]},
                {name: 'addressValue', start: [1, 4], end: [1, 4]},
                {name: 'phoneLabel', start: [0, 5], end: [0, 5]},
                {name: 'phoneValue', start: [1, 5], end: [1, 5]},
                {name: 'reviewLabel', start: [0, 6], end: [0, 6]},
                {name: 'reviewValue', start: [1, 6], end: [1, 6]},
                {name: 'buttons', start: [0, 7], end: [1, 7]}
            ]}
        >
            <Box gridArea="nameLabel">
                <Spa size="28px" color="brand"/>
            </Box>
            <Box gridArea="nameValue">
                <Text size="20px">Clinic</Text>
                <Text margin={clinic_style}>{provider && provider.name}</Text>
            </Box>

            <Box gridArea="emailLabel">
                <Mail size="28px" color="brand"/>
            </Box>
            <Box gridArea="emailValue">
                <Text size="20px">Provider Email</Text>
                <Text margin={clinic_style}>
                { provider &&
                    <a href={`mailto:${provider.email}`} rel="noopener noreferrer" target="_blank">{provider.email}</a>
                }
                </Text>
            </Box>

            <Box gridArea="websiteLabel">
                <Domain size="28px" color="brand"/>
            </Box>
            <Box gridArea="websiteValue">
                <Text size="20px">Provider Website</Text>
                <Text margin={clinic_style}>
                { provider ?
                    <a href={toLink(provider.website)} target="_blank" rel="noopener noreferrer">{provider.website}</a>
                    :
                    '<Unspecified>'
                }
                </Text>
            </Box>

            <Box gridArea="phoneLabel">
                <Phone size="28px" color="brand"/>
            </Box>
            <Box gridArea="phoneValue">
                <Text size="20px">Provider Phone</Text>
                <Text margin={clinic_style}>{provider && provider.phone}</Text>
            </Box>

            <Box gridArea="addressLabel">
                <MapLocation size="28px" color="brand"/>
            </Box>
            <Box gridArea="addressValue">
                <Text size="20px">Provider Address</Text>
                <Text margin={clinic_style}>{provider && provider.address}</Text>
            </Box>

            {user.role >= roleId('provider') &&
            <>
                <Box gridArea="reviewLabel">
                    <MapLocation size="28px" color="brand"/>
                </Box>
                <Box gridArea="reviewValue">
                    <Text size="20px">Google Review Link</Text>
                    <Text margin={clinic_style}>
                    { provider &&
                        (
                            ( provider.google_review_url &&
                                <a href={toLink(provider.google_review_url)}>{provider.google_review_url}</a>
                            ) || "Unset"
                        )
                    }
                    </Text>
                </Box>
            </>
            }
        </Grid>
        }
        {editing &&
        <ProviderEdit onSubmit={updateData}
                      onCancel={(done) => {
                          setEditing(false);
                          done();
                      }}
                      failed={failed}
                      provider={provider}
                      {...props}/>
        }
        {/*is_provider &&
        <DataDrop user={user} {...props}/>
        */}
  </Box>);
}


const DataDrop = ({user, ...props}) => (
    <FeatureBlock user={user}
                  feature={features.data_drop}>
        <Box
            margin={{ left: '0.5em', bottom: '1em', top: '2em' }}
            flex={false}
            fill={false}
            background="white">
            <Box width='medium' direction='row'>
                <Lock/> Secure Upload
            </Box>
            <ProviderDropzone/>
        </Box>
    </FeatureBlock>);

const logoMargin = { marginRight: '0.25em' };
const marginVert = { bottom: '1em' };

const LogoDrop = ({user, failed, onUpdate, ...props}) => (
    <Box direction="column" flex={false}>
        { failed &&
            <Text color="error" margin={marginVert}>Failed to set provider logo.</Text>
        }
        {(user && user.provider && user.provider.logo_url) ?
            <Box width="medium">
                <Box basis="xsmall" fill={false} flex={false} border={{color: '#ededed'}} round="1em" pad='1em' margin='1em'>
                    <Image src={user.provider.logo_url} fit="contain" alt="Provider Logo"/>
                </Box>
            </Box>
            :
            <Box width="medium" margin={marginVert}>
                No Logo Set
            </Box>
        }
        <Box
            margin={{ left: '0.5em', vertical: '1em' }}
            flex={false}
            fill={false}
            background="white">
            <Box width='medium' direction='row'>
                <ImageIcon style={logoMargin}/> Upload
            </Box>
            <LogoDropzone onSave={onUpdate} {...props}/>
        </Box>
    </Box>
);

/*
const ProviderCodeBox = ({provider, ...props}) => {
    const code = (!!provider) ? provider.code : '';

    const selectProviderLink = (event) => {
        event.target.select()
    };

    return (<Box
                flex={false}
                fill={false}
                width='60em'
                background='white'
                pad={'small'}
                margin={{left: '0.5em', bottom: '1em'}}
                border={{color: '#ededed'}}
                round="0.5em"
            >
                <Box
                    direction="row"
                    width='100%'>
                    <div style={{marginRight: '2em'}} id="qrCodeId"/>
                    <Box width='100%'>
                        <Box margin={{bottom: '1em'}}>
                            <Text style={{fontWeight: 'bold'}}>Your Provider Code:</Text>
                            <Text style={{fontWeight: 'bold'}} size="3em" margin={{left: '0.2em'}}>
                                {code}
                            </Text>
                        </Box>
                        <Text style={{fontWeight: 'bold'}}>Your Patient SignUp Link:</Text>
                        <TextInput readonly plain
                                   margin={{top: '1em'}}
                                   pad={{horizontal: '1em', vertical: '1em'}}
                                   onMouseOver={selectProviderLink}
                                   value={signUpLink(code)}
                        />
                    </Box>
                </Box>

            </Box>);
};
 */

const scroll = {overflow: 'scroll'};

export class Provider extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        const provider = this.props.user ? this.props.user.provider : {};
        this.state = {
            loading: false,
            failed: false,
            provider: Object.assign({}, provider)
        };
    }

    updateProvider = (provider) => {
        this.context.update_provider(provider);
        this.setState({provider: Object.assign({}, provider)});
    };

    componentDidMount() {
        loadQrCode('qrCodeId', signUpLink(this.state.provider.code))
    }

    render() {
        const {provider, loading, failed, provider_orig, updating} = this.state;
        const {user} = this.props;
        const unchanged = shallowEqualObjects(provider, provider_orig);

        return (
            <Box
                breakpoint='medium'
                flex={true}
                fill={false}
                width='100%'
                background="backgroundBody"
                style={scroll}
            >
                <ProviderInfoGuidePrompts user={user} location={this.props.location}/>

                <PageHeader label={<Text>Provider Information<br/>
                                         <Text size={'14px'} weight='400'>
                                             ID: {provider.code}
                                         </Text>
                                    </Text>}/>

                { loading &&
                <Box
                    width='100%'
                    background='white'
                    pad={'small'}
                    border
                    round="0.5em"
                    margin={{horizontal: '1em'}}
                >
                    <Loading loading={loading} failed={failed}/>
                </Box>
                }
                {!loading &&
                  <ProviderInfoBox  user={this.props.user}
                                    provider={provider}
                                    unchanged={unchanged}
                                    updating={updating}
                                    onSave={this.updateProvider}
                  />
                }
            </Box>
        );
    }
}
