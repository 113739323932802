import React from "react";
import {Box, FormField, Grid, Text, TextInput} from "grommet";
import {ApiCall, ApiCallNoAuth, roleId} from "./ApiHelpers";
import {Clinic} from "./FormHelpers";
import {Loading, LoadingButton} from "./Loading";
import {Auth} from "aws-amplify";
import {theme} from '../theme';

export function FullName(patient) {
  if (!patient)
    return ""

  let name = '';
  if (patient.first_name !== undefined)
    name = `${patient.first_name} `;

  if (patient.last_name !== undefined) {
    name += patient.last_name;
  }

  return name
}

export function FirstName(patient) {
  if (!patient) return "";
  return patient.first_name;
}

export function VerifyBox({patient_id, email, context, use_aws, onVerified, ...props}) {
    const [code, setCode] = React.useState('');
    const [error, setError] = React.useState('');
    const [message, setMessage] = React.useState('');

    const updateCode = (event) => {
        setCode(event.target.value);
    }

    const _errorTrap = (result) => {
        // A 502 indicates a lambda timeout. Keep trying.
        if (result.response && result.response.status === 502) {
            setError("Operation timed out. Please try again.");
        } else if (result.response && result.response.status === 400 && result.response.data) {
            var msg = (<Text color='error'>{result.response.data.errorMessage}</Text>);
            setError(msg);
        } else {
            setError("An unknown error occurred while processing your request.");
        }
    }

    const request_app_link = (done) => {
        ApiCallNoAuth("post", "/auth/app_link", {email})
            .then(() => done()).catch((result) => {
                _errorTrap(result);
                done();
            });
    }

    const request_magic_link = (done) => {
        ApiCallNoAuth("post", "/auth/magic", {email})
            .then(() => done()).catch((result) => {
                _errorTrap(result);
                done();
            });
    }

    const request_api = (done) => {
        ApiCallNoAuth('post', '/auth/request', { email })
            .then((result) => {
                done();
                setMessage('Verification message sent.');
                setError('');
            })
            .catch(result => {
                done();
                _errorTrap(result);
            });
    };

    const request_aws = async (done) => {
        Auth.verifyUserAttribute(await context.session(), 'phone_number')
            .then((result) => {
                done();
                setMessage("Verification code requested.");
                setError('');
            })
            .catch(result => {
                done();
                setError(result.message);
                setMessage('');
            });
    };

    const verify_api = (done) => {
        ApiCallNoAuth('post', '/auth/verify', { email, code })
            .then((result) => {
                done();
                if (result.success) {
                    setMessage("Verification complete!");
                    if (onVerified)
                        onVerified();
                    setError('');
                } else {
                    setMessage("");
                    setError("Invalid code!");
                }
            })
            .catch(result => {
                done();
                _errorTrap(result);
            });
    };

    const verify_aws = async (done) => {
        Auth.verifyUserAttributeSubmit(await context.session(), 'phone_number', code)
            .then((result) => {
                done();
                setMessage("Verification complete!");
                setError('');
                if (onVerified)
                    onVerified();
            })
            .catch(result => {
                done();
                setError(result.message);
                setMessage('');
            });
    };

    const verify = use_aws ? verify_aws : verify_api;
    const request = use_aws ? request_aws : request_api;

    return(
        <SettingsBox title={"Account Verification"}
                     color={theme.global.colors.brandLight}
                     text={'#5a5a5a'}>
            {error &&
            <Box margin={{ bottom: '0.5em' }} height='2em' flex={false}>
                <Loading failed={error}/>
            </Box>
            }
            {message &&
            <Box margin={{ bottom: '0.5em' }} height='2em' flex={false}>
                {message}
            </Box>
            }
            <Box width="25em" height="6em" flex={false}>
                <FormField label={<Text size="18px">Phone Verification Code</Text>}>
                    <TextInput
                        name="code"
                        placeholder="123456"
                        onChange={updateCode}
                    />
                </FormField>
            </Box>
            <Grid
                flex={false}
                margin={{top: 'small'}}
                rows={['2em']}
                columns={['small', 'small']}
                gap='small'
                areas={[
                    {name: 'verify', start: [0, 0], end: [0, 0]},
                    {name: 'request', start: [1, 0], end: [1, 0]}
                ]}
            >
                <Box>
                    <LoadingButton
                        gridArea='verify'
                        primary
                        label='Submit Code'
                        onClick={verify}/>
                </Box>
                <Box>
                    <LoadingButton
                        gridArea='cancel'
                        label='Request Code'
                        onClick={request}
                    />
                </Box>
            </Grid>
            {context.role > roleId("patient") && patient_id !== "current" &&
            <Grid>
                <LoadingButton
                    style={styles.marginTop}
                    label='Send Download Link'
                    onClick={request_app_link}
                />
                <LoadingButton
                    style={styles.marginTop}
                    label='Send Magic Link'
                    onClick={request_magic_link}
                />
            </Grid>
            }
        </SettingsBox>
    );
}

const settings_margin = { horizontal: '1em', bottom: '1em' };

export function SettingsBox({children, title, text, color, ...props}) {
    //  style={{ backgroundColor: color, color: text, ...title_style }}
    return (
        <Box flex={false}
             fill={false}
             border={{color: '#ededed'}} round='0.75em'
             background="white"
             width="30em"
             margin={settings_margin}
             style={{overflow: 'hidden'}}
             {...props}
        >
            { title &&
                <Box pad={{ horizontal: '2em', top: '1.5em', bottom: '0em'}}>
                    <b>{title}</b>
                </Box>
            }
            <Box margin={'0'} pad={'2em'}>
                {children}
            </Box>
        </Box>
    );
}

const Tracker = ({tracker, onAccept, onReject}) => {
    const hasActions = !!tracker.rejected || !!tracker.linked || !!tracker.unlinked;
    const acceptText = hasActions ? "Switch to this provider" : "Accept";
    return (
        <Box margin={{ top: '1em' }}>
            <Clinic provider={tracker} prefix={"provider_"} hideInvalid/>
            <Box direction='row' margin={{right: 'auto', top: '1em'}}>
                {!hasActions &&
                <LoadingButton label={<Text color="white">Reject</Text>}
                        color={theme.dash.bad}
                        onClick={(done) => {
                            done();
                            onReject(tracker);
                        }}
                        primary
                        margin={{right: '1em'}}/>
                }
                <LoadingButton label={<Text color="white">{acceptText}</Text>}
                        primary
                        onClick={(done)=> {
                            done();
                            onAccept(tracker);
                        }}
                        disabled={!!tracker.linked}/>
            </Box>
        </Box>
    );
}

export const ListTrackers = ({ trackers, loading, code, ...actions}) => {
    if (trackers.length === 0)
        return null;

    return (<Box direction='column' margin={{top: '2em'}}>
        <Box margin={{ bottom: '1em' }} direction='row'>
            <Text size='20' weight='bold'>Provider Link Requests</Text>

        {loading &&
          <Loading size='18px' loading={loading} fade margin={{left: '1em'}}/>
        }
        </Box>
        {trackers.map((item) => {
            if (code !== item.provider_code)
                return <Tracker tracker={item} key={'tracker-' + item.id} {...actions}/>
            return null
        })}
    </Box>);
}

export function LinkBox({patient_id, context, current_code, onUpdate, detach_only, ...props}) {
    const default_code = current_code === '000000' ? '' : current_code;

    const [code, setCode] = React.useState(default_code);
    const [error, setError] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [provider, setProvider] = React.useState({});
    const [loading_provider, setLoading] = React.useState(false);
    const [loading_trackers, setLoadingTrackers] = React.useState(false);
    const [trackers, setTrackers] = React.useState([]);

    const isPatient = patient_id === 'current';

    React.useEffect(() => {
        loadProvider(default_code || '');
    }, [default_code]);

    React.useEffect(()=> {
        if (isPatient)
            checkInvites(() => setLoadingTrackers(false));
    }, [isPatient]);

    const checkInvites = (done) => {
        if (!isPatient)
          return false;

        setLoadingTrackers(true);
        ApiCall(context, 'get', `/users/current/trackers`).then((trackers) => {
            setTrackers(trackers);
            setLoadingTrackers(false);
            if (!!done) done();
        }).catch((ex) => {
            setTrackers([]);
            setLoadingTrackers(false);
            if (!!done) done();
        });
        return true;
    }

    const acceptTracker = (trkr) => {
        _processNewCode(trkr.provider_code)
        _relinkApi(trkr.provider_code);
    }

    const rejectTracker = (trkr) => {
        setLoading(true);

        console.log("Reject: ", trkr)
        ApiCall(context, 'post', `/users/current/trackers/${trkr.id}`, {reject: true})
        .then((success) => {
            if (success.success) {
                setMessage('Provider link request has been rejected.');
                setError('');
            } else {
                setError("An error occurred while attempting to reject the link request.");
                setMessage("");
            }

            checkInvites();
            setLoading(false);
        })
        .catch((ex) => {
            checkInvites();
            setError("An error occurred while rejecting the link request.");
            setLoading(false);
            console.log(ex.rep);
        });
    }

    const loadProvider = (pcode) => {
        if (pcode.length !== 6)
            return;
        setLoading(true)
        ApiCallNoAuth('get', `/providers/public/${pcode}`)
            .then(provider => {
                setProvider(provider);
                setLoading(false);
            })
            .catch(() => {
                setProvider({});
                setLoading(false);
            })
    }

    const updateCode = (event) => {
        const new_code = event.target.value;
        _processNewCode(new_code)
    }

    const _processNewCode = (new_code) => {
        setCode(new_code);

        if (new_code.length === 6)
            loadProvider(new_code);
        else
            setProvider({});
    }

    const _errorTrap = (result) => {
        // A 502 indicates a lambda timeout. Keep trying.
        if (result.response && result.response.status === 502) {
            setError("Operation timed out. Please try again.");
        } else if (result.response && result.response.status === 400 && result.response.data) {
            var msg = (<Text color='error'>{result.response.data.errorMessage}</Text>);
            setError(msg);
        } else {
            setError("An unknown error occurred while processing your request.");
        }
    }

    const _detachApi = (done) => {
        ApiCall(context, 'post', `/users/${patient_id}/detach`)
            .then((result) => {
                if (!!done) done();

                if (isPatient)
                    setMessage('You have been detach from your current provider.');
                else
                    setMessage('Patient has been detach from the current provider.');
                if (onUpdate)
                    onUpdate();
                setCode('');
                setProvider({});
                setError('');
                checkInvites();
            })
            .catch(result => {
                if (!!done) done();
                _errorTrap(result);
            });
    };

    const _relinkApiBtn = (done) => {
        _relinkApi(code, done);
    };

    const _relinkApi = (code, done) => {
        ApiCall(context, 'post', `/users/${patient_id}/relink`, { code })
            .then((result) => {
                if (!!done) done();
                if (result.success) {
                    if (isPatient)
                        setMessage('Your account has now been linked to the new provider.');
                    else
                        setMessage('Patient has been linked to the new provider.');
                    if (onUpdate)
                        onUpdate();
                    setError('');
                } else {
                    setMessage("");
                    setError("Invalid provider code!");
                }
                checkInvites();
            })
            .catch(result => {
                if (!!done) done();
                _errorTrap(result);
            });
    };

    return(
        <SettingsBox title={(
            <Box direction={'row'}>
                Provider Link
                {loading_provider &&
                    <Loading size='18px' loading={loading_provider} fade={false} margin={{left: '1em'}}/>
                }
            </Box>)}
                     color={theme.global.colors.brandLight}
                     text={'#5a5a5a'}>
            {error &&
            <Box margin='0.5em'>
                <Loading failed={error}/>
            </Box>
            }
            {message &&
            <Box margin='0.5em'>
                <Text color='brand'>{message}</Text>
            </Box>
            }
            { detach_only &&
            <Text size="18px">Patient Journal Link</Text>
            }
            {!detach_only &&
            <Box width="25em" flex={true}>
                <FormField label={<Text size="18px">Provider Code</Text>}>
                    <TextInput
                        name="code"
                        placeholder="123456"
                        value={code}
                        onChange={updateCode}
                    />
                </FormField>
                {!detach_only &&
                    <Grid>
                        <LoadingButton
                            primary
                            style={styles.marginBottom}
                            label={isPatient ? 'Link to Provider' : 'Link to New Provider'}
                            disabled={code === current_code || code.length !== 6}
                            onClick={_relinkApiBtn}
                        />
                    </Grid>
                }
                {!isPatient &&
                <Text color='error'>
                    Note: Once the patient is linked to another provider or detached from this provider,
                    you will no longer have access to the patient's information. The patient (or other provider)
                    will have to manually relink with you again in order to regain access.
                </Text>
                }
                {provider.name &&
                <Box>
                    <Clinic provider={provider} hideInvalid={true}
                            margin={{bottom: 'small'}}
                            pad='small'
                    />
                </Box>
                }
            </Box>
            }
            <Grid>
                <LoadingButton
                    label={isPatient ? 'Unlink from Current Provider' : "Unlink Patient"}
                    disabled={'000000' === current_code}
                    onClick={_detachApi}
                />
            </Grid>
            <ListTrackers loading={loading_trackers}
                          trackers={trackers}
                          onAccept={acceptTracker} onReject={rejectTracker}
                          code={current_code}/>
        </SettingsBox>
    );
}

const styles = {
    marginBottom: {
        marginBottom: '1em'
    },
    marginTop: {
        marginTop: '1em'
    }
};