import React, {useState} from "react";

import { Box, Image, Button, Text } from "grommet";
import { Alert } from 'grommet-icons';
import {ListItem} from "../components";


export const LoadingItem = ({loading, failed, label, ...rest}) => (
    <ListItem justify="between"
              align="start"
              flex={false}
              pad={{ left: '0.5em' }}
              key="loading-key"
              {...rest}
    >
        <Loading loading={loading} failed={failed} label={label} width={rest.width}/>
    </ListItem>
)

const btnStyle = { overflow: 'hidden', height: '2.2em',
                   borderWidth: '1px', borderRadius: '0.75em' };

export const LoadingButton = ({loading_label, highlight, ...props}) =>
{
    const [loading, setLoading] = useState(!!props.loading);
    const styleColor = props.primary ? 'white' : '#5a5a5a';
    let updated_props = { ...props, style: { color: styleColor, ...props.style, ...btnStyle } };

    if (loading) {
        updated_props.label = (
            <Box direction="row">
                <Box margin='auto'>
                    <Box direction="row">
                        <Box width='24px' height='24px' direction="row">
                            <Image src='/loading.gif' fit="cover"/>
                        </Box>
                        <Box margin={{left: '0.5em', top: '0.15em'}} direction="row">
                            {loading_label || props.label}
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
        updated_props.disabled = true;
    }

    if (highlight && !updated_props.disabled)
        updated_props.className = 'guide-highlight';

    updated_props.onClick = (e) => {
        setLoading(true);
        if (props.onClick)
            props.onClick(() => setLoading(false));
    }

    return (<Button {...updated_props}/>);
}

export const Loading = ({loading, failed, size, label, fade, ...rest}) => {
    if (!size) size="24px";
    return (
        <Box direction="row" {...rest}>
            {failed ?
                <Box direction="row">
                    <Box margin={{top: '0.5em'}}>
                        <Alert size='medium' color='red'/>
                    </Box>
                    <Box margin={{top: '0.5em', right: '1em', left: '1em'}}>
                        {(typeof (failed) === 'string' || typeof (failed) === 'object') ?
                            failed :
                            "Oops! We are unable to retrieve the requested data at this time."
                        }
                    </Box>
                </Box>
                :
                <>
                    <Box direction="row" className={(fade !== false) ? "fade-in" : ""}>
                        <Box width={size} height={size}>
                            <Image src='/loading.gif' fit="cover"/>
                        </Box>
                        {label &&
                            <Text margin={{vertical: 'auto', left: '0.5em'}}>{label}</Text>
                        }
                    </Box>
                </>
            }
        </Box>
    )
}
