export const patients = [
  {
    id: "1",
    uuid: "931A2CAB-E7BC-49B9-86AF-26857CC1F4D0",
    name: "Jane A.",
    status: "Good",
    watched: true
  },
  {
    id: "4",
    uuid: "0D645602-1E6C-4D25-8331-FDF905A05954",
    name: "John D.",
    status: "Moderate",
    watched: true
  },
  {
    id: "14",
    uuid: "4CAA82AA-082A-465A-AE1E-11BC4E0BAA16",
    name: "Jason X.",
    status: "Better",
    watched: true
  },
  {
    id: "24",
    uuid: "96408952-8733-4F0B-BF12-14AA7F805D59",
    name: "John W.",
    status: "Worse",
    watched: true
  },
  {
    id: "25",
    uuid: "F7838EF0-93AE-4C31-B2A8-B3680C21F2CD",
    name: "Andrew S.",
    status: "Can't Move",
    watched: true
  },
  {
    id: "67",
    uuid: "F3C49349-EA1C-4054-B1A4-25551BF8F250",
    name: "Becka C.",
    status: "Much Worse",
    watched: true
  },
  {
    id: "99",
    uuid: "575F0F9D-AF64-44AD-921C-CE6CA8F42B9E",
    name: "Richard G.",
    status: "No Motion",
    watched: true
  },
  {
    id: "16266",
    uuid: "0CAABF66-016A-4D3F-9D20-842A61C699B5",
    name: "Bob S.",
    status: "In Traction",
    watched: true
  }
];
