import React from "react";
import {Box, Button, Text} from "grommet";
import {Route} from "react-router-dom";
import {roleId} from ".";


export function TopNotification({display, color, ...props}) {
    if (display)
        return <Box style={{
                 borderRadius: '0.75em',
                 backgroundColor: '#FAFAFA',
                 margin: '1em',
                 width: '58em'
             }} flex={false}>
            <Box flex={false} align="start" margin="1em"
                 direction='row-responsive'>
                <Text margin='.3em'>
                    {props.children}
                </Text>
            </Box>
        </Box>
    return null;
}



function newClinic({ user, location }) {
    if (!user || !user.provider || roleId("provider") >= user.role)
        return false;

    return (!user.provider.address);
}

function trainOnboarding({ user, location }) {
    return !(!user || !user.provider || roleId("provider") >= user.role || user.provider.num_trackers > 0);
}

export function DashboardGuidePrompts(props) {
    if ( newClinic(props) )
        return (
            <TopNotification display color='brandLightAlt'
                             {...props}
            >
                <Text>Thank you for creating an account with PainPoint. Please update your clinic's information on the
                    <Route render={({history}) => (
                        <Button onClick={() => history.push('/providers/current')}
                                margin={{left: '0.25em'}} color='brand'>
                            Clinic Information
                        </Button>)}
                    /> page.
                </Text>
            </TopNotification>
        );
    return null;

    if ( trainOnboarding(props) )
        return (
            <TopNotification display color='brandLightAlt'
                             {...props}
            >
                <Text>Now that you've setup your Clinic Information, start adding patients using your
                    <Route render={({history}) => (
                        <Button onClick={() => history.push('/onboarding?add=true')}
                                margin={{left: '0.25em'}} color='brand'>
                            Patient Onboarding
                        </Button>)}
                    /> page.
                </Text>
            </TopNotification>
        );

    return null;
}


export function ProviderInfoGuidePrompts(props) {
    return null;
    if ( newClinic(props) )
        return (
            <TopNotification display color='brandLightAlt'
                             {...props}
            >
                <Text>Please complete your clinic information below. The more information you provide here the better.</Text>
            </TopNotification>
        );

    if ( trainOnboarding(props) )
        return (
            <TopNotification display color='brandLightAlt'
                             {...props}
            >
                <Text>Now that you've setup your Clinic Information, start adding patients using your
                    <Route render={({history}) => (
                        <Button onClick={() => history.push('/onboarding?add=true')}
                                margin={{left: '0.25em'}} color='brand'>
                            Patient Onboarding
                        </Button>)}
                    /> page.
                </Text>
            </TopNotification>
        );

    return null;
}

const guideList = {
  lineHeight: '2em'
};

export function OnboardingGuidePrompts(props) {
    return null;
    if ( trainOnboarding(props) )
        return (
            <TopNotification display color='brandLightAlt'
                             {...props}
            >
                <Text>
                    <ol style={guideList}>
                        <li>To create your first patient, adding their Name, Phone Number, and Email address.</li>
                        <li>If the patient wishes to receive an appstore download link to assist in downloading the app, click the check box labeled "Send AppStore Link".</li>
                        <li>Press "Create" to add the patient to your Onboarding dashboard.</li>
                    </ol>
                </Text>
            </TopNotification>
        );

    return null;
}
