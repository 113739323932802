import {Auth} from "aws-amplify";
import {Form, FormField} from "grommet";
import React from "react";

import {AuthenticationPage} from ".";
import {AuthLink, AuthLinkBox, MessageBanner, FormTitle} from ".";
import {LoadingButton} from "../Loading";

export class ForceChange extends AuthenticationPage {
    constructor(props) {
        super(props);

        this.state = {
          email: props.email,
          password: "",
          new_password: "",
          confirm_password: "",
          error: null,
        };
    }

    validate() {
        return this.state.email.length > 0 && this.state.password.length > 0 &&
            this.state.new_password.length > 0 &&
            this.state.new_password === this.state.confirm_password;
    }

    handleForceChange = async (done) => {
        if ( this.state.error )
            this.setState({ error: false });

        try {
            await Auth.completeNewPassword(
                this.props.user,
                this.state.new_password
            );
            done();
            this.props.onConfirmHasAuth();

        } catch (e) {
            done();
            this.setState({error: e.message});
        }
    }

    render() {
        const {error, confirm_password, new_password, password, email} = this.state;
        const {message} = this.props;
        return (
          <>
            <FormTitle title="Login" icon="LoginIcon" />
            <MessageBanner error={error} message={message} />
            <Form>
              <FormField id="email" name="email" label="e-mail" value={email} />
              <FormField
                id="password"
                name="password"
                label="password"
                type="password"
                value={password}
                onChange={this.handleChange}
              />
              <FormField
                id="new_password"
                name="new_password"
                label="new password"
                type="password"
                value={new_password}
                onChange={this.handleChange}
              />
              <FormField
                id="confirm_password"
                name="confirm_password"
                label="confirm password"
                type="password"
                value={confirm_password}
                onChange={this.handleChange}
              />
              <LoadingButton
                primary
                type="submit"
                label="save"
                disabled={!this.validate()}
                onClick={this.handleForceChange}
              />
            </Form>
            <AuthLinkBox>
              <AuthLink onClick={this.props.onLogin} label="Login" />
            </AuthLinkBox>
          </>
        );
    }
}
