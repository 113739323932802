import React from "react";

import { Menu, Text } from "grommet";

import { Avatar } from "./";

export const UserMenu = ({ user = {}, items = [], ...rest }) => (
  <Menu
    dropAlign={{ top: "bottom", right: "right" }}
    icon={false}
    items={items.map(item => ({
      ...item,
      label: <Text size="small" margin={{left: '0.5em'}}>{item.label}</Text>
    }))}
    label={<Avatar name={user.name} url={user.thumbnail} />}
    {...rest}
  />
);
