import React from "react";

import {Box, Stack, Anchor, Text} from "grommet";
import {Alert, LinkDown, LinkUp} from "grommet-icons";

import {
    DaysSinceToday,
    FullName,
    ListItem,
    LookupColor,
    PainDescription,
    PartsFileList
} from "../components";

import { CheckIcon, AlertIcon } from "../icons";

import { theme } from "../theme";

import {Route} from "react-router-dom";

// import { shallowEqualObjects } from "shallow-equal";

export function EventKey(event) {
    var key = event.event || Math.random() * 100000 % 100000
    return "event:" + key
}

const cursorPointer = {cursor: 'pointer'};
const messageStackStyle = {
    backgroundColor: theme.dash.good,
    width: '0.9em', height: '0.9em',
    textAlign: 'center', alignContent: 'middle',
    paddingTop: '0.09em',
    marginTop: '0.7em',
    marginLeft: '0.7em'
};

const messageStackTopStyle = {
    backgroundColor: theme.dash.good,
    width: '0.9em', height: '0.9em',
    textAlign: 'center', alignContent: 'middle',
    paddingTop: '0.1em',
    marginBottom: '1em',
    marginLeft: '0.7em'
};

const alertStackStyle = {
    backgroundColor: theme.dash.bad,
    width: '0.9em', height: '0.9em',
    textAlign: 'center', alignContent: 'middle',
    paddingTop: '0.09em',
    marginTop: '0.7em',
    marginLeft: '0.7em'
};

const printStyle = { width: '30px', height: '30px' };
const nonprintStyle = { marginRight: 'auto', width: '2em', height: '2em' };

export const AlertLevel = ({alert_level, message_count, patient, onClear, printing, size='18px'}) => {
    const container_style = printing ? printStyle : nonprintStyle;

    const displayNumber = (c) => {
        if ( c > 99 )
            return (<Alert size='12px' color="white"/>);
        return c;
    }
    if ( alert_level === 0 && message_count === 0 )
        return (
            <Box style={container_style}>
                <CheckIcon size={size} color={theme.dash.good}/>
            </Box>
        );

    if ( alert_level === 0 && message_count > 0 )
        return (
            <Box style={container_style}>
                <Stack anchor="top-left">
                    <CheckIcon size={size} color={theme.dash.good}/>
                    <Box
                        flex={false}
                        style={messageStackStyle}
                        round='1em'
                    >
                        <Text size='9px' color='white'>{displayNumber(message_count)}</Text>
                    </Box>
                </Stack>
            </Box>
        );

    if ( alert_level > 0 && message_count === 0 )
        return (
            <Box style={container_style}>
                <Box
                    style={cursorPointer}
                    onClick={event=> onClear(patient, alert_level)}
                >
                    <Stack anchor="top-left">
                        <AlertIcon size={size} color={theme.dash.bad}/>
                        <Box
                            flex={false}
                            style={alertStackStyle}
                            round='1em'
                        >
                            <Text size='9px' color='white'>{displayNumber(alert_level)}</Text>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        );

    return (
        <Box style={container_style}>
            <Box
                style={cursorPointer}
                onClick={event=> onClear(patient, alert_level)}
            >
                <Stack anchor="bottom-left">
                    <Stack anchor="top-left">
                        <AlertIcon size={size} color={theme.dash.bad}/>
                        <Box flex={false}
                             style={alertStackStyle}
                             round='1em'>
                            <Text size='9px' color='white'>{displayNumber(alert_level)}</Text>
                        </Box>
                    </Stack>
                    <Box flex={false}
                         style={messageStackTopStyle}
                         round='1em'>
                        <Text size='9px' color='white'>{displayNumber(message_count)}</Text>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

const mobilePad = { left: '0.5em', top: '1em', bottom: '1em' };
const nonMobilePad = { left: '0.5em' };
const verticalAuto = {vertical: 'auto'};
const verticalRightAuto = {vertical: 'auto', right: 'auto'};
const rowMargin = { vertical: '0.5em' };

const iconListMargin = { right: 'small' };
const iconListMarginPrint = { left: '0em', right: '0em' };

export const PatientItem = ({ patient, onClear, onInspect, printing, widths, index, ...rest }) => {
    let painLevel = {};
    let {status} = patient;

    const iconMargins = printing ? iconListMarginPrint : iconListMargin;

    let event = status['pain:transitions'][0];
    painLevel[event['pain:location']] = event['pain:level'];

    const mobile_pad = rest.mobile ? mobilePad : nonMobilePad;
    return (
        <ListItem justify="between"
                  align="start"
                  flex={false}
                  height={rest.mobile ? '' : '5em'}
                  pad={mobile_pad}
                  className='patient-data-row'
                  margin={rowMargin}
                  round={'0.75em'}
        >
            <Box direction='row'
                 width='100%'
                 flex={false}
                 margin={verticalAuto}
            >
                <Box direction="row-responsive"
                     align="start"
                     width={widths[0]}
                     margin={{ top: '0.6em' }}
                     flex={false}
                     fill={false}
                >
                    <AlertLevel alert_level={patient.alert_level || 0}
                                message_count={patient.message_count || 0}
                                patient={patient}
                                onClear={onClear}
                                printing={printing} />
                </Box>

                <Box align="start"
                     basis={widths[1]}
                     margin={verticalAuto}
                     flex={false}
                     fill={false} direction="row-responsive"
                >
                    <Route {...rest} render={props => {
                      return (<Anchor label={FullName(patient)} onClick={() => {
                        props.history.push("/history/" + patient.id);
                      }}/>);
                    }}/>
                </Box>

                <Box align="start"
                     basis={widths[2]}
                     margin={verticalAuto}
                     flex={false}
                     fill={false} direction="row-responsive"
                >
                    {DaysSinceToday(patient.last_event)}
                </Box>

                <Box direction="row-responsive"
                     align="start"
                     margin={verticalRightAuto}
                     flex={false}
                     fill={false}
                     basis={widths[3]}
                >
                    {(status['pain:transitions'] || []).slice(0).reverse().map((event, index) => {
                        return (<Box margin={iconMargins}
                                     key={`pain-ind-${event.event ? event.event : '-' + index}`}
                                     flex={false}>
                            <BodyPainBlock
                                index={index}
                                pain_level={event['pain:level']}
                                pain_location={event['pain:location']}
                                onClick={() => onInspect(patient, event.event, event['pain:location'])}
                                printing={printing}
                                />
                        </Box>)
                     }
                    )}
                </Box>

                <Box direction="row"
                     align="start"
                     border={false}
                     margin={verticalRightAuto}
                     fill={false}
                     flex={false}
                     basis={widths[4]}
                >
                    {(status['pain:transitions'] || []).slice(0).reverse().map((event, index) =>
                        <Box key={EventKey(event)}
                             margin={iconMargins}
                             flex={false}>
                            <PainTrendBlock index={index}
                                            pain_trend={event['pain:trend']}
                                            printing={printing}
                            />
                        </Box>
                    )}
                </Box>

                <Box direction="row-responsive"
                     align="start"
                     margin={verticalRightAuto}
                     fill={false}
                     flex={false}
                     basis={widths[5]}
                >
                    <PainPeak peak={painLevel}
                              onClick={(location) => onInspect(patient, 0, location)}
                              printing={printing} />
                </Box>
            </Box>
        </ListItem>
    );
}


const rightOne = {right: '0.5em'};
const leftAuto = {left: 'auto'};


export const PatientMobileItem = ({ patient, onClear, onInspect, printing, widths, index, ...rest }) => {
    let painLevel = {};
    let { status } = patient;

    const transitions = (status['pain:transitions'] || []).slice(0).reverse();
    const iconMargins = { right: '1em' };

    let event = status['pain:transitions'][0];
    painLevel[event['pain:location']] = event['pain:level'];

    return (
        <Box direction='column'
             flex={false}
             margin='1em'
             border={{ color: '#E0E0E0' }}
             pad='1em'
             round='0.75em'
        >
            <Box direction='row' margin={{ horizontal: '1em', bottom: '1em' }}>
                <Box direction="row"
                     margin={{ right: '1em', top: '0.5em', bottom: 'auto'}}
                     flex={false}
                     fill={false}
                >
                    <AlertLevel alert_level={patient.alert_level || 0}
                                message_count={patient.message_count || 0}
                                patient={patient}
                                onClear={onClear}
                                size='1.25em'/>
                </Box>

                <Box margin={verticalAuto}
                     flex={false}
                     fill={false} direction="column"
                >
                    <Route {...rest} render={props => {
                      return (<Anchor label={FullName(patient)} onClick={() => {
                        props.history.push("/history/" + patient.id);
                      }}/>);
                    }}/>
                    <Text size='13px' color='#848484' margin={{top: '0.5em'}}>
                        {DaysSinceToday(patient.last_event)}
                    </Text>
                </Box>
                <Box direction='row' margin={{...verticalAuto, ...leftAuto}}>
                    <Box margin={rightOne}>
                        <PainTrendBlock index={4}
                                        pain_trend={event['pain:trend']}
                        />
                    </Box>
                    <Box margin={rightOne}>
                        <BodyPainBlock
                            index={4}
                            pain_level={event['pain:level']}
                            pain_location={event['pain:location']}
                            onClick={() => onInspect(patient, event.event, event['pain:location'])}
                            />
                    </Box>
                    <Box>
                        <PainPeak peak={painLevel}
                                  onClick={(location) => onInspect(patient, 0, location)}/>
                    </Box>
                </Box>
            </Box>

            <Box flex={false} width='100%' direction='row' margin={{ horizontal: '1em', bottom: '1em' }}>
                {transitions.map((event, index) => {
                    return (<Box margin={iconMargins}
                                 key={`pain-ind-${event.event ? event.event : '-' + index}`}
                                 flex={false} direction='row'>
                        <BodyPainBlock
                            index={index}
                            pain_level={event['pain:level']}
                            pain_location={event['pain:location']}
                            onClick={() => onInspect(patient, event.event, event['pain:location'])}
                            printing={printing}
                            />
                    </Box>)
                 }
                )}
            </Box>

            <Box direction="row"
                 margin={{ horizontal: '1em', bottom: '1em' }}
                 fill={false}
                 flex={false}
                 style={{ overflow: 'scroll' }}
            >
                {(status['pain:transitions'] || []).slice(0).reverse().map((event, index) =>
                    <Box key={EventKey(event)}
                         margin={iconMargins}
                         flex={false}>
                        <PainTrendBlock index={index}
                                        pain_trend={event['pain:trend']}
                                        printing={printing}
                        />
                    </Box>
                )}
            </Box>

        </Box>
    );
}

export const BodyPainBlock = ({ pain_location: location, pain_level: level, printing, index, onClick, ...props }) => {
    const options = { height: '2.3em', width: '2.3em' };
    const clickable = !!(location && onClick);

    return (
        <Box direction="row"
             align="start"
        >
          <Box align='start'
               {...options}
               onClick={clickable ? onClick : undefined}
               round='100em'
            >
              <img style={options} alt={level + " pain in the " + location}
                   src={"/parts/" + PainDescription(level) + "-" + PartsFileList(location)}
              />
            </Box>
        </Box>
    );
}

export const PainPeak = ({ peak, printing, onClick, ...props }) => {
    var peak_max = -1, peakPart = '';
    if (typeof(peak) === 'object') {

        for ( var k in peak ) {
            if (peak[k] > peak_max) {
                peak_max = peak[k];
                peakPart = k;
            }
        }
    } else {
        peak_max = peak;
        peakPart = '';
    }

    const dims = "2.3em";

    const clickable = peak && onClick;
    const onclick = () => onClick(peakPart);

    return (
        <Box
            flex={false}
            fill={false}
            direction='row'
            background={LookupColor(peak_max)}
            width={dims} height={dims}
            round='50em'
            pad="3px"
            onClick={clickable ? onclick : undefined}
        >
            { peak_max >= 0 &&
                <Text margin='auto' size='0.9em' color='white'>{Math.round(peak_max/10.0)}</Text>
            }
        </Box>
    )
}

export const PainTrendBlock = ({ pain_trend, index, printing, ...props }) => {
    const [trend] = React.useState(pain_trend);

    const icon_size = "16px";
    const options = {
        pad: "0.65em",
        round: "2em",
        width: "2.3em",
        height: "2.3em"
    };

    if (props.margin)
        options.margins = props.margins;

    const color = printing ? "black" : "white";

    // const trendIcon = {
    //   'better': <LinkUp size="32px" color="Green"/>,
    //   'worse': <LinkDown size="32px" color="DarkRed"/>,
    //   'same': <LinkNext size="32px" color="DarkGreen" />,
    // }[trend] || <FormSubtract size="32px" color="Black" />


    const trendIcon = {
        'better': <LinkUp size={icon_size} color={color}/>,
        'worse': <LinkDown size={icon_size} color={color}/>,
        'same': <Box margin={{ horizontal: 'auto', top: '-0.5em'}}>
            <Text size='1.5em' color={color}>=</Text>
        </Box>,
    }[trend] || null


    const trendColor = {
        'better': theme.dash.good,
        'worse': theme.dash.bad,
        'same': theme.dash.mid
    }[trend] || '#F0F0F3'


    return (
        <Box direction="row"
             flex={false}
             align="start"
             background={trendColor}
             {...options}>
            {trendIcon}
        </Box >
    )
}


export const fillWithDefaults = (patient) => {
    var trans = patient.status['pain:transitions'] || []
    var add = 5 - trans.length
    for (var i = 0; i < add; ++i) {
        trans.push({})
    }
    patient.status['pain:transitions'] = trans

    var events = patient.status['pain:transitions'] || []
    add = 5 - events.length
    for (var j = 0; j < add; ++j) {
        events.push({})
    }
    patient.status['pain:transitions'] = events
    return patient
}

