import React from "react";

import {AlphaColorTable, PartsFileList, PartsList} from "./ColorTable";
import {Box, Grid, Text} from "grommet";
import {LinkDown, LinkNext, LinkUp} from "grommet-icons";

export function ShowLegend() {
    const plist = PartsList()
    const sev = ['mild', 'moderate', 'severe']

    return (
        <Box border='horizontal' flex={false} pad='small' margin={{ top: '2em' }}>
            <b>Legend:</b>
            <Box margin={{ top: '1em', bottom: '1em' }} height='3em'>
                <Box direction='row' flex={false}>
                    <Box width='4em' height='1em' align='center'>
                        <Text size='10px'>
                            Better
                        </Text>
                    </Box>
                    <Box width='4em' height='1em'></Box>
                    <Box width='4em' height='1em' align='center'>
                        <Text size='10px'>
                            Worse
                        </Text>
                    </Box>
                </Box>
                <Box direction='row' flex={false}>
                    <Box width='4em' height='1em' align='center'>
                        <LinkUp size="12px"/>
                    </Box>
                    <Box width='4em' height='1em' align='center'>
                        <LinkNext size="12px"/>
                    </Box>
                    <Box width='4em' height='1em' align='center'>
                        <LinkDown size="12px"/>
                    </Box>
                </Box>
                <Box direction='row' flex={false}>
                    {AlphaColorTable[3].map((color, index) => (
                        <Box key={color} background={color} width='4em' height='1em'></Box>
                    ))}
                </Box>
            </Box>
            <Box style={{ overflow: 'auto' }}>
                <Grid
                    margin={{ horizontal: "xxsmall", vertical: "xxsmall" }}
                    gap="small"
                    columns="xsmall"
                    flex={false}
                >
                    {Object.keys(plist).map((key, index) => (
                        <Box key={'legen-' + key} direction='row'>
                            <img alt={key} src={"./" + sev[parseInt(index % 3)] + "/" + PartsFileList(key)} width='30px'/>
                            <Text size='14px'
                                  style={{
                                      textTransform: 'capitalize',
                                      marginTop: 'auto',
                                      marginBottom: 'auto',
                      marginLeft: '0.4em'
                    }}>{key}</Text>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
