import {MatchEmail} from "../FormHelpers";
import {Form, FormField} from "grommet";
import {Refresh as ResendIcon} from "grommet-icons/es6/icons";
import React from "react";

import {AuthenticationPage, FormLabel, ErrorTrap} from ".";
import {AuthLink, AuthLinkBox, MessageBanner, FormTitle} from ".";
import {ApiCallNoAuth} from "../ApiHelpers";
import {LoadingButton} from "../Loading";

export class SignupResend extends AuthenticationPage {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email,
            valid_email: MatchEmail(this.props.email),
            error: null
        };
    }

    validateEmailForm() {
        return MatchEmail(this.state.email);
    }

    handleEmail = event => {
        var email = event.target.value;
        this.props.onEmailChange(email);

        this.setState({
            [event.target.name]: email,
            valid_email: MatchEmail(email)
        });
    }

    handleResendCode = async (done) => {
        const {email} = this.state;

        if ( this.state.error )
            this.setState({ error: false });

        ApiCallNoAuth("post", "/auth/request", {email})
            .then((res) => {
                done();
                this.props.onConfirm();
            }).catch((e) => {
                done();
                ErrorTrap(e, (msg) => {
                    this.setState({error: msg});
                });
            });
    }

    render() {
        const {error, email} = this.state;
        const {message} = this.props;
        return (
            <>
                <FormTitle title="Resend Code" icon={ResendIcon}/>
                <MessageBanner error={error} message={message}/>
                <Form>
                    <FormField
                        id="email"
                        name="email"
                        label={<FormLabel label="E-mail"/>}
                        value={email}
                        onChange={this.handleEmail}
                    />
                    <LoadingButton
                        primary
                        type="submit"
                        label="Resend Code"
                        disabled={!this.validateEmailForm()}
                        onClick={this.handleResendCode}
                    />
                </Form>
                <AuthLinkBox>
                   <AuthLink label="Sign Up" onClick={this.props.onSignUp}/>
                   <AuthLink label="Confirm Sign Up" onClick={this.props.onConfirm} side='right'/>
                </AuthLinkBox>
            </>
        );
    }
}