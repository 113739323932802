import React from "react";

import {Box, Heading} from "grommet";
const headerMargin = {top: 'auto', bottom: '1em'};

export const PageHeader = ({label, width, ...rest}) => (
    <Box direction="row-responsive"
         flex={false}
         width={width || '60em'}
         height="4em"
         pad={{left: '0.5em'}}
    >
        <Heading level="4" margin={headerMargin}>
            {label}
        </Heading>
        {rest.children}
    </Box>
);
