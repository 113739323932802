

export const JournalLink = (patient, host) => {
  if (host === undefined || host)
    return  window.location.protocol + '//' + window.location.host + '/journal?uuid=' + patient.uuid
  return  '/journal?uuid=' + patient.uuid
}

export const HistoryLink = (patient, host) => {
  if (host === undefined || host)
    return  window.location.protocol + '//' + window.location.host + '/history/' + patient.id
  return  '/history/' + patient.id
}

export const signUpLink = (code) => {
  return window.location.protocol + '//' + window.location.host + '/signup?code=' + code;
}
