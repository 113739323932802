import React from "react";
import {Route} from "react-router-dom"

import { shallowEqualObjects } from "shallow-equal";
import {isValidPhoneNumber} from "react-phone-number-input";
import {theme} from "../theme";

import {
    Box,
    Heading,
    Text,
    Form,
    FormField,
    TextInput,
    Grid,
    Button,
    CheckBox,
    Image
} from "grommet";

import {Loading, MatchEmail, CleanPhoneInput, PrettyPhone,
        roleId, ApiCall, UserContext, /*VerifyBox,*/ LinkBox, SettingsBox} from "../components";
import {UserNew, Mail, Phone, Alert} from 'grommet-icons';

import {FormLabel} from "../components/auth";

// function sex(input) {
//     if (input === 1)
//         return 'Male';
//     if (input === 2)
//         return 'Female';
//     return ''
// }

function fromSex(input) {
    if (input === 'Male')
        return 1;
    if (input === 'Female')
        return 2;
    return 0
}

const PatientForm = ({patient, admin, create, submitting, validated, canReset, valid_phone, valid_email, onChange, onReset, onSubmit, ...rest}) => {
    const limit_fields = !admin;
    const no_access = !admin && !patient.allow_admin;
    return (
        <SettingsBox width="30em" title={"User Settings"}
                     color={theme.global.colors.titleBackground}
                     text={theme.global.colors.titleText}>
            <Form border='1px'>
                <Grid margin={{horizontal: "small"}} gap="xsmall" columns="15em">
                    <FormField label={<Text size="18px">First Name*</Text>}>
                        <TextInput
                            name="first_name"
                            value={patient.first_name}
                            onChange={onChange}
                            disabled={no_access}
                        />
                    </FormField>
                    <FormField label={<Text size="18px">Last Name*</Text>}>
                        <TextInput
                            name="last_name"
                            value={patient.last_name}
                            onChange={onChange}
                            disabled={no_access}
                        />
                    </FormField>
                </Grid>
                {/*<Grid margin={{horizontal: "small"}} gap="xsmall" columns="15em">
                <FormField label={<Text size="18px">Communication Name</Text>}>
                    <TextInput
                        name="alias"
                        value={patient.alias}
                        onChange={onChange}
                    />
                </FormField>
            </Grid>*/}

                <Grid margin={{horizontal: "small"}} gap="xsmall" columns="15em">
                    <FormField marigin={{right: '1em'}}
                               label={<FormLabel label={<><Mail size='18px'/>Email*</>}
                                                 icon={Alert} alert={!valid_email}/>}>
                        <TextInput
                            name="email"
                            value={patient.email}
                            onChange={onChange}
                            disabled={no_access}
                        />
                    </FormField>
                    <FormField label={<FormLabel label={<><Phone size='18px'/>Phone*</>}
                                                 icon={Alert} alert={!valid_phone}/>}>
                        <TextInput
                            name="phone"
                            value={PrettyPhone(patient.phone)}
                            onChange={onChange}
                            placeholder="+1#######"
                            disabled={no_access}
                        />
                    </FormField>
                </Grid>
                {
                    // <Grid margin={{horizontal: "small"}} gap="xsmall" columns="small">
                    //     <FormField marigin={{right: '1em'}}
                    //                label='Primary Code'>
                    //         <TextInput
                    //             name="primary_code"
                    //             value={patient.primary_code}
                    //             onChange={onChange}
                    //         />
                    //     </FormField>
                    //     <FormField label='Secondary Code'>
                    //         <TextInput
                    //             name="secondary_code"
                    //             value={patient.secondary_code}
                    //             onChange={onChange}
                    //         />
                    //     </FormField>
                    // </Grid>
                    //
                    // <Box width="medium"
                    //      pad='small'
                    //      margin={{left: 'small', top: 'medium'}}
                    //      border={'bottom'}
                    // >
                    //     <RadioButtonGroup
                    //         gap='medium'
                    //         direction="row"
                    //         name="sex"
                    //         options={['Male', 'Female']}
                    //         value={sex(patient.sex)}
                    //         onChange={onChange}
                    //         disabled={no_access}
                    //     />
                    // </Box>
                }

                <Box width="medium"
                     pad='small'
                     margin={{left: 'small', top: 'medium'}}
                     border={'bottom'}
                >
                    {
                        // <Box margin={{bottom: 'small'}}>
                        //     <CheckBox
                        //         name='insured'
                        //         checked={!!patient.insured}
                        //         label='Insured'
                        //         onChange={onChange}
                        //         margin={{
                        //             top: 'large',
                        //             bottom: 'small',
                        //             left: 'small',
                        //             right: 'small'
                        //         }}
                        //     />
                        // </Box>
                    }
                    <Box margin={{bottom: 'small'}}>
                        <CheckBox
                            name='reminder'
                            checked={!!patient.reminder}
                            label='Opt-in for Reminders'
                            onChange={onChange}
                            margin={{
                                top: 'large',
                                bottom: 'small',
                                left: 'small',
                                right: 'small'
                            }}
                            disabled={limit_fields || no_access}
                        />
                    </Box>
                    <Box margin={{bottom: 'small'}}>
                        <CheckBox
                            name='com_notifications'
                            checked={!!patient.com_notifications}
                            label='Opt-in for Communications'
                            onChange={onChange}
                            margin={{
                                top: 'large',
                                bottom: 'small',
                                left: 'small',
                                right: 'small'
                            }}
                            disabled={limit_fields || no_access}
                        />
                    </Box>
                </Box>

                <Box pad={{top: 'xxsmall', bottom: '1em'}}>
                    <Box pad='medium'>
                    {canReset() &&
                        <Text color={'brandAlt'}>Changes have not been saved.</Text>
                    }
                    {!canReset() &&
                        <Text color={'brandAlt'}>No changes to save.</Text>
                    }
                    </Box>
                    <Grid
                        margin={{top: 'medium'}}
                        rows={['1em']}
                        columns={['small', 'small', 'small']}
                        gap='small'
                        areas={[
                            {name: 'submit', start: [0, 0], end: [0, 0]},
                            {name: 'cancel', start: [1, 0], end: [1, 0]},
                            {name: 'reset', start: [2, 0], end: [2, 0]}
                        ]}
                    >
                        <Box>
                            <Button
                                primary
                                disabled={!validated() || submitting }
                                label={submitting ? (
                                    <Box direction='row' fill flex>
                                        <Box margin={{left: 'auto'}}/>
                                        <Box width='1em' height='1em' margin={{right: '0.5em', top: '2px'}}>
                                            <Image src="/loading.gif" fit="contain"/>
                                        </Box>
                                        Saving...
                                        <Box margin={{right: 'auto'}}/>
                                    </Box>
                                ) : 'Save'}
                                onClick={onSubmit}/>
                        </Box>
                        {!create &&
                        <Box>
                            <Button gridArea='reset' label='Reset' onClick={onReset} disabled={!canReset()}/>
                        </Box>
                        }
                    </Grid>
                </Box>
            </Form>
        </SettingsBox>
    );
}


export class Patient extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        var create = false

        try {
            create = (props.computedMatch.params.pid === 'create')
        } catch (ex) {
        }

        var create_patient = {
            first_name: '',
            last_name: '',
            alias: '',
            phone: '',
            email: '',
            insured: false,
            reminder: true,
            com_notifications: true,
            sex: 0,
            role: 1
        }

        this.state = {
            valid_phone: false,
            valid_email: false,
            failed: false,
            create: create,
            submitting: false,
            patient: create_patient,
            orig: Object.assign({}, create_patient)
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.computedMatch.params.pid === 'create' &&
            this.props.computedMatch.params.pid !== 'create') {

            this.setState({ create: false }, () => this.loadData() );
        }
    }

    async componentDidMount() {
        if (this.state.create)
            return

        this.loadData()
    }

    handleEvent = (event) => {
        const patient = this.state.patient;

        var value;

        if (event.target.name === "phone") {
            value = CleanPhoneInput(event.target.value)
        } else if (event.target.name === "sex") {
            value = fromSex(event.target.value)
        } else if (event.target.type === "checkbox") {
            value = event.target.checked
        } else {
            value = event.target.value
        }

        patient[event.target.name] = value;
        this.setState({
            patient: patient,
            valid_phone: isValidPhoneNumber(patient.phone),
            valid_email: MatchEmail(patient.email)
        })
    }

    validated = () => {
        if (shallowEqualObjects(this.state.patient, this.state.orig))
            return false;
        return this.state.patient.first_name.length > 0 &&
            this.state.patient.last_name.length > 0 &&
            this.state.valid_phone && this.state.valid_email
    }

    canReset = () => {
        return !shallowEqualObjects(this.state.patient, this.state.orig);
    }

    resetPatient = () => {
        this.setState({patient: Object.assign({}, this.state.orig)})
    }

    submit = async (event) => {
        this.submitPatient(this.state.patient)
    }

    async submitPatient(patient) {

        if (this.props.user.role !== roleId('admin'))
            patient = {
              id: patient.id,
              primary_code: patient.primary_code,
              secondary_code: patient.secondary_code,
              insured: patient.insured
            };

        delete patient.verified_phone;

        const url = (patient.id === undefined) ? '/users' : `/users/${patient.id}`;

        ApiCall(this.context, 'post', url, patient)
            .then((result) => {
                if (result.try_again) {
                    setTimeout(() => this.submitPatient(patient), 30000)
                } else {
                    if (!this.state.create)
                        this.props.history.goBack()
                    else
                        this.props.history.push('/patients/' + result.user_id)
                    this.setState({ submitting: false, failed: false });
                }
            })
            .catch(result => {
                // A 502 indicates a lambda timeout. Keep trying.
                if (result.response && result.response.status === 502) {
                    this.setState({submitting: true, failed: false});
                    setTimeout(() => this.submitPatient(patient), 20000)
                } else if (result.response && result.response.status === 400) {
                    var msg = (<Text color='error'>{result.response.data.errorMessage}</Text>);
                    this.setState({submitting: false, failed: msg});
                } else {
                    console.log(result.response);
                    this.setState({submitting: false, failed: true});
                }
            });

        this.setState({submitting: true})
    }

    async loadData() {
        ApiCall(this.context,'get', `/users/${this.props.computedMatch.params.pid}`)
            .then((result) => {
                this.setState({
                    patient: result,
                    valid_email: !!MatchEmail(result.email),
                    valid_phone: isValidPhoneNumber(result.phone),
                    orig: Object.assign({}, result)})
            })
            .catch(result => {
                // A 502 indicates a lambda timeout. Keep trying.
                if (result.response && result.response.code === 502) {
                    setTimeout(() => this.loadData(), 20000)
                } else
                    this.setState({failed: true})
            })
    }
    render() {
        const {patient, create, submitting, failed, valid_phone, valid_email} = this.state;
        const is_admin = this.props.user.role === roleId('admin');
        const is_staff = this.props.user.role === roleId('staff');
        return (
            <Box fill background="backgroundBody" style={{ overflow: 'scroll' }}>
                <Box flex={false} background="backgroundBody">
                    <Box direction="row-responsive" flex={false} width='62em' height="7em">
                        <Heading level="2" margin={{left: "small", top: 'auto', bottom: 'auto'}}>
                            <UserNew size="0.9em" color="brand"
                                     style={{paddingTop: "0.25em", paddingRight: "0.25em"}}/> Active Patients
                            <Box margin={{left: 'medium', bottom: 'small'}}>
                                <Text color='DarkRed'>Fields marked with an asterisk (*) are required.</Text>
                            </Box>
                        </Heading>
                    </Box>
                    {failed &&
                    <Box margin={{left: 'medium', bottom: 'large'}}>
                        <Loading failed={failed}/>
                    </Box>
                    }
                    <Box direction={'row-responsive'}>
                        <PatientForm
                            admin={is_admin}
                            patient={patient}
                            create={create}
                            submitting={submitting}
                            onSubmit={this.submit}
                            onReset={this.resetPatient}
                            onChange={this.handleEvent}
                            validated={this.validated}
                            canReset={this.canReset}
                            valid_phone={valid_phone}
                            valid_email={valid_email}/>
                        <Box direction={'column'} flex={false}>
                            {/* (is_staff || is_admin) && !create &&
                                <VerifyBox patient_id={patient.id}
                                           email={patient.email}
                                           context={this.context}
                                />
                            */}
                            { (is_staff || is_admin) && !create &&
                                <Route render={({history}) => (
                                    <LinkBox patient_id={patient.id}
                                             context={this.context}
                                             detach_only={!patient.allow_admin}
                                             current_code={this.props.user.provider.code}
                                             onUpdate={() => {
                                               setTimeout(() => history.push('/'), 1000);
                                            }}/>
                                )}/>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}
