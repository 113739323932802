import React from "react";

import { Box, Text } from "grommet";

export const Avatar = ({ name, url: Url, ...rest }) => {
    if (typeof (url) === 'string') {
        return (<Box direction='row'>{name}
                    <Box
                        a11yTitle={`${name} avatar`}
                        height="avatar"
                        width="avatar"
                        round="full"
                        background={`url(${Url})`}
                        margin={{left: '0.5em'}}
                        {...rest}
                    />
                </Box>);
    } else {
        return (
            <Box direction='row'>
                <Text size='13px' color='#5a5a5a'>{name}</Text>
                <Url color='#5a5a5a' style={{marginTop: '0.3em', marginLeft: '0.5em'}} size="10px"/>
            </Box>);
    }
}
