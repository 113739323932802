import React from "react";
import { Box, Heading, Text } from "grommet";
import { JournalLinkFromUuid } from "../pages"

function InlineImage({source, ...rest}) {
  return (<img src={source} width='20px' height='20px' alt={rest.alt} {...rest}/>)
}

export const AppleInstructions = ({ patient, ...rest }) => (
  <Box {...rest}>
    <Box pad='0.5em'>
      <Heading
        level="4"
        style={{
          marginBlockStart: '0.25em',
          marginBlockEnd: '0.25em'
        }}
      >
        iPhone
      </Heading>
    </Box>
    <Box
      fill border='top' pad='1em'
      overflow={{ horizontal: 'hidden' }}
    >
      <Text size="12px">
        <b style={{ marginTop: '1em', marginBottom: 0 }}>By QR Code:</b>
        <ol style={{ marginTop: 0, marginBottom: '1em' }}>
          <li>Open your phone’s camera and point it at the <b>QR Code</b> <InlineImage source='/qr.png' alt={'QR code'}/> above.</li>
          <li>A notification will appear at the bottom or top displaying the web address.</li>
          <li>Tap on the notification.</li>
          <li>Safari will open and automatically navigate the appropriate page.</li>
        </ol>

        <b style={{ marginTop: 0, marginBottom: 0 }}>By Web Address:</b>
        <ol style={{ marginTop: 0, marginBottom: '1em' }}>
          <li>Open <b>Safari</b>.</li>
          <li>Navigate to <Text size='12px' color='black'><b>{JournalLinkFromUuid(patient.uuid)}</b></Text></li>
        </ol>
        <b style={{ marginTop: 0, marginBottom: 0 }}>Add Journal to Home Screen:</b>
        <ol style={{ marginTop: 0, marginBottom: '1em' }}>
          <li>Press the <InlineImage alt={'Add to Home screen'} source='/add_to_home_safari.png' style={{ border: '1px solid darkgrey' }}/> icon at the bottom of the screen.</li>
          <li>Press the <Text size='12px' color='black'><b>Add to Home Screen</b></Text> from the list of actions on the bottom row.  If the does not immediately show, scroll to the right.</li>
          <li>Click <Text size='12px' color='blue'><b>Add</b></Text>.</li>
          <li>Navigate to your 'Home Screen' and locate the 'Pain Point' icon <InlineImage alt='PainPoint Icon' source='/painpoint-icon.png'/>.</li>
          <li>Activate the 'Pain Point' app.</li>
        </ol>

      </Text>
    </Box>
  </Box>
);
