import React from "react";
import {Box, Text} from "grommet";

import { Close, Clock, Chat } from 'grommet-icons';

import {
  Loading, PainPeak, PainTrendBlock, BodyPainBlock, PrettyFullDate,
  UserContext, FullName, ApiCall
} from "../components";
import { AlertIcon } from "../icons";
import { theme } from "../theme";

const rightMargin = {right: '0.75em'};
const padLeft = {left: '1em'};
const verticalLeftAuto = {vertical: 'auto', left: 'auto'};
const peakMargin = {};
const trendMargin = rightMargin;
const partMargin = rightMargin;
const dateTimeMargin = {};
const verticalAuto = {vertical: 'auto'};
const borderTop = {size: '1px', color: '#E0E0E0', side: 'top'};

export const DateTime = ({date, ...props}) => (
  <Text size='13px' margin={dateTimeMargin} color={'#777'} {...props}>
    {PrettyFullDate(date)}
  </Text>
);

const to_bool = (s) => {
    if (s === 'yes' || s === '1')
        return true;
    if (s === 'no' || s === '0')
        return false;
    return null;
};

export const EntryContainer = ({children}) => (
    <Box margin={'1em'}>
      {children}
    </Box>
);

export const MessageContainer = ({children}) => (
    <Box margin={'1em'}>
      {children}
    </Box>
);

const checkBtnStyle = {
  height: '1.8em',
  padding: '0.3em',
  marginLeft: '0.64em',
  backgroundColor: '#f8f8f8',
  border: '1px #f2f2f2 solid',
  borderRadius: '0.75em'
};

export const CheckContainer = ({children, style}) => {
    const bstyle = {...checkBtnStyle, ...(style || {}) };
    return (
        <Box style={bstyle}
             flex={false}
             direction="row"
        >
            {children}
        </Box>
    );
}

export const InfoContainer = ({children, has_content}) => {
  if (!has_content)
    return null;

  return (<Box flex={false}
               width='62em'
               pad='2em'
               border={borderTop}>
    {children}
  </Box>);
}

const badgeBorder = {size: '1px', color: '#F0F0F3'};

export const InfoBadge = ({icon, content}) => {
    if (!content)
        return null;

    return (
        <Box border={badgeBorder} round='0.75em'
             height='2.2em' flex={false} margin={{right: '1em'}}
             pad={{horizontal: '1em'}} direction='row'
             background='white'
        >
            <Box margin={verticalAuto}>
                {icon}
            </Box>
            <Text size='12px' color='#939393'
                  margin={{...verticalAuto, left: '0.5em'}}>
                {content}
            </Text>
        </Box>
    );
}

export const CommentBlock = ({content}) => {
  if (!content)
    return null;

  return (
      <Box direction="row">
        <Text size='13px' color='#848484'>{content}</Text>
      </Box>
  );
}

export const PainInfo = ({location, level, trend}) => (
  <Box fill={false} flex={false}
       margin={verticalAuto}
       direction='row'
       pad={padLeft}
  >
    <Box margin={trendMargin}>
      <PainTrendBlock index={0} pain_trend={trend}/>
    </Box>
    <Box margin={partMargin}>
      <BodyPainBlock pain_location={location} pain_level={level} index={0}/>
    </Box>
    <Box margin={peakMargin}>
      <PainPeak peak={level}/>
    </Box>
  </Box>);

const textOffset = { vertical: '0.1em', horizontal: '0.4em' };

export const ActionInfo = ({ alert, recent, exercise, comment, ...rest }) => {
    const textSize = '12px';
    return (<Box flex={false}
                 direction={'row'}
                 margin={{...verticalLeftAuto, ...rightMargin}}
                 style={rest.boxStyle}>
        {alert &&
            <CheckContainer {...rest}>
                <Box style={{ marginTop: '0.1em' }}>
                    <AlertIcon size="16px"
                               color={theme.dash.bad}
                               tooltip="alert"
                    />
                </Box>
                <Text size={textSize} margin={textOffset}>
                    Alert
                </Text>
            </CheckContainer>
        }
        {recent &&
            <CheckContainer {...rest}>
                <Box style={{ marginTop: '0.1em' }}>
                    <Clock size="16px" color={recent ? 'black' : '#BCBCBC'}/>
                </Box>
                <Text size={textSize} margin={textOffset}>
                    Recent
                </Text>
            </CheckContainer>
        }
        {exercise === false &&
            <CheckContainer {...rest}>
                <Box style={{ marginTop: '0.1em' }}>
                    <Close size="16px" color={"Black"}/>
                </Box>
                <Text size={textSize} margin={textOffset}>
                    Didn't Exercise
                </Text>
            </CheckContainer>
        }
        {comment === false &&
            <CheckContainer {...rest}>
                <Box style={{ marginTop: '0.1em' }}>
                    <Chat size="16px" color={"Black"}/>
                </Box>
                <Text size={textSize} margin={textOffset}>
                    Comment
                </Text>
            </CheckContainer>
        }

    </Box>);
}

export const PainPanel = ({action, ...rest}) => {
  if (action)
    return (
      <Box direction='column' flex={false}>
        <PainInfo {...rest}/>
        <ActionInfo {...rest} boxStyle={{ marginTop: '0.5em' }}/>
      </Box>
    );
  return <PainInfo {...rest}/>;
}

export const ActionPanel = ({hide, ...rest}) => {
  if (hide)
    return null;
  return <ActionInfo {...rest}/>;
}

const HeaderEntryText = ({event}) => {
    const poses = ListPositions(event);
    const descs = ListDescriptions(event);
    const hasPos = poses.length !== 0;
    const hasDesc = descs.length !== 0;
    const none = !hasPos && !hasDesc;

    if (none)
        return (<Text size={'14px'}><b>No description provided</b></Text>);

    return (
        <>
            { hasPos &&
            <Box direction={'row-responsive'} margin={{bottom: '0.25em'}}>
                <Box direction={'row'} width={'6em'}>
                    <Text size={'12px'} margin={{right: '1em'}} color='#777'>Body Position:</Text>
                </Box>
                <Text size={'14px'}><b>{poses.join(', ')}</b></Text>
            </Box>
            }
            { hasDesc &&
            <Box direction={'row-responsive'}>
                <Box direction={'row'} width={'6em'}>
                    <Text size={'12px'} margin={{right: '1em'}} color='#777'>Description:</Text>
                </Box>
                <Text size={'14px'}><b>{descs.join(', ')}</b></Text>
            </Box>
            }
        </>
    );
}

export const EntryDetails = ({event, date}) => {
    return (
        <Box margin={{left: '2em', top: '0.25em', bottom: '0.5em'}} direction='column' width='40em'>
            <Box style={{'textTransform': 'capitalize'}}
                 margin={{top: '0.5em', bottom: '0.5em'}}>
                <HeaderEntryText event={event}/>
                <DateTime date={date} margin={{top: '0.75em'}}/>
            </Box>
        </Box>
    );
}


function ListPositions(event) {
    var list = [];

    for (var x in event) {
        if (x.search(/^pain:position:/) === 0 && event[x])
            list.push(x.substr(14, x.length));
    }

    return list;
}


function ListDescriptions(event) {
    var list = [];

    for (var x in event) {
        if (x.search(/^pain:description:/) === 0 && event[x])
            list.push(x.substr(17, x.length));
    }

    return list;
}


export const EventEntry = ({entry, index, event_id, patient, comments}) => {

  const level = entry.event['pain:level'];
  const trend = entry.event['pain:trend'];
  const location = entry.event['pain:location'];
  const exercise = to_bool(entry.event['pain:exercise']);
  const recent = to_bool(entry.event['pain:recent']);
    const comment = entry.event['pain:comments'];
    const activity = entry.event['pain:activity'];

  return (
    <Box key={"event-" + entry.id}
         flex={false}
         height='auto'
         border={{color: '#EDEDED'}}
         round='1em'
         pad='1em'
         margin={{top: '1.1em'}}
    >
        <Box direction='row'>
            <Box margin={{left: '10px', bottom: '0.25em'}} direction='column'>
                <Box direction='row'>
                    <Text weight='bold' margin={{vertical: 'auto'}}>
                        {FullName(patient)}
                    </Text>
                    <Box margin={{left: '0.5em'}}>
                        <ActionInfo alert={entry.alert} exercise={exercise}
                                    action={true} recent={recent}
                                    comment={!!comment}
                                    style={{ marginBottom: '0.25em' }}/>
                    </Box>
                </Box>
                <Box>
                    <Text color='#848484' size='14px'>{PrettyFullDate(entry.created_at)}</Text>
                </Box>
            </Box>
            <Box margin={{left: 'auto', right: '0.5em'}}>
                <PainInfo location={location} level={level} trend={trend}/>
            </Box>
        </Box>
        <Box direction='row'>
            <Box margin={{left: '0.5em', bottom: '0.25em'}} direction='column'>
                <Box style={{'textTransform': 'capitalize', overflow: 'wrap'}}
                     margin={{top: '0.5em'}}>
                    <Text color='#656565' size='14px'>
                        {ListPositions(entry.event).join(', ')}
                    </Text>
                </Box>
            </Box>
        </Box>
        {comments && !!comment &&
            <Box margin='0.5em'>
                <CommentBlock content={comment}/>
            </Box>
        }
    </Box>);
}



export class EventInfo extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            loading: true,
            video: false,
            image: false,
            temp_try_count: 0
        };

        this.unmounted = false;
    }

    async componentDidMount() {
        var query = ['type=journal'];

        if (this.props.limit) query.push('limit=' + this.props.limit)
        if (this.props.alerts) query.push('alerts=' + this.props.alerts)
        if (this.props.location) query.push('location=' + this.props.location)

        this.current_call = ApiCall(this.context, 'get', `/users/${this.props.patient.id}/events?` + query.join('&'))
            .then(result => {
               if (!this.unmounted)
                  this.setState({events: result, loading: false});
            }).catch(result => {
            if (!result.response && result.message === "Network Error") {
               if (!this.unmounted)
                 this.setState({failed: true});
            }
        });
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        const {events, loading} = this.state;
        const {event_id, patient, comments} = this.props;
        return (
            <Box fill='horizontal'
                 flex={false}
                 style={{overflow: 'auto', minWidth: 'large'}}
                 pad={{ horizontal: '2em', top: '0.25em', bottom: '2em'}}
            >
                {loading &&
                <Loading loading={false} failed={false} margin={{left: 'auto', right: 'auto'}}/>
                }
                {events.map((entry, index) =>
                    <EventEntry key={"entry-" + index}
                                entry={entry} index={index}
                                event_id={event_id} patient={patient}
                                comments={comments}/>
                )}
            </Box>
        );
    }
}

/*
{patient.uuid === "82d6cce7-1098-4713-8f97-2f30c8f2d466" &&
<Box align="end" direction="row" margin={{left: 'auto', right: '0.5em'}}>
    {index === 2 && multimedia &&
    <Box
        flex={false}
        pad='0.1em'
    >
        <DocumentImage
            size="20px"
            color={"Black"}
            style={{cursor: 'pointer'}}
            onClick={() => {
                if (image && image.index === index)
                    this.setState({image: false, video: false});
                else
                    this.setState({
                        video: false,
                        image: {
                            index: index,
                            url: (index === 2 ? "https://painpoint-test-bucket-2783gsdhf.s3.amazonaws.com/LoadingDishwasher.png" : "")
                        }
                    });
            }}/>

    </Box>
    }
    {(index === 3 || index === 4) && multimedia &&
    <Box
        flex={false}
        pad='0.1em'
        margin={{left: '0.5em'}}
    >
        <DocumentVideo
            size="20px"
            color={"Black"}
            style={{cursor: 'pointer'}}
            onClick={() => {
                if (video && video.index === index)
                    this.setState({image: false, video: false});
                else
                    this.setState({
                        image: false,
                        video: {
                            index: index,
                            url: (index === 4 ? 'https://painpoint-test-bucket-2783gsdhf.s3.amazonaws.com/PresentWrap.mp4'
                                : 'https://painpoint-test-bucket-2783gsdhf.s3.amazonaws.com/Vegetables.mp4')
                        }
                    });
            }}/>
    </Box>
    }
</Box>
}

{((video && video.index === index) || (image && image.index === index)) &&
                        <Box
                            align="start"
                            direction="row"
                            flex='shrink'
                            fill={false}
                            border={true}
                            width="400px"
                            round='0.25em'
                            style={{overflow: 'hidden'}}
                            background="lightgrey"
                            margin={{left: 'auto', right: 'auto', top: '1em', bottom: '1em'}}>
                            {video && video.index === index &&
                            <video controls style={{
                                width: "102%",
                                position: 'relative',
                                top: '-1px',
                                left: '-1px',
                                marginBottom: '-1px',
                                padding: 0
                            }}>
                                <source src={video.url} type="video/mp4"/>
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            }
                            {image && image.index === index &&
                            <img alt='Patient' src={image.url} style={{width: "100%"}}/>
                            }
                        </Box>
                        }
*/
