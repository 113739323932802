import React, { Component } from "react";

import {Box, Text} from "grommet";
import RoutedButton from "./RoutedButton";

const containerPad = {left: "1em", top: "0.5em"};
const iconMargin = {top: "0.3em"};
const labelMargin = {left: '0.15em', top: "0.25em"};
const overflowHidden = {overflow: 'hidden'};

const ButtonContents = ({ icon: Icon, iconOnly, label, active=false }) => {
    const color = active ? "white" : "#5a5a5a"
    return (
        <Box
            pad={containerPad}
            gap="small"
            direction="row"
            height="2.55em"
            style={overflowHidden}
        >
            <Box direction='row'
                 margin={iconMargin}>
                <Icon color={color}/>
            </Box>
            {!iconOnly &&
                <Box direction='row'
                     margin={labelMargin}>
                <Text size="0.84em" color={color}>{label}</Text>
                </Box>
            }
        </Box>
    );
}

const btnStyle = {
                  borderRadius: '0.6em',
                  marginLeft: '2.25em',
                  marginRight: '3em'
                };

export class MenuButton extends Component {
  render() {
    const { label, icon, iconOnly, ...rest } = this.props;
    let style = {...btnStyle};
    if (iconOnly)
        style.marginRight = '1.2em';

    return (
      <RoutedButton {...rest} style={style}>
          <ButtonContents icon={icon} iconOnly={iconOnly} label={label}/>
      </RoutedButton>
    );
  }
}
