import {Component} from "react";
import {MatchEmail, CleanPhoneInput, CleanCodeInput, CleanEmailInput} from "../FormHelpers";
import {isValidPhoneNumber} from "react-phone-number-input";

export class AuthenticationPage extends Component {
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    genCodeHandler = len => {
        return (event) => {
            this.setState({
                [event.target.id]: CleanCodeInput(event.target.value, len)
            });
        }
    }

    handleCheck = event => {
        this.setState({
            [event.target.id]: event.target.checked
        });
    };

    handlePhone = event => {
        var phone = CleanPhoneInput(event.target.value);

        this.setState({
            [event.target.name]: phone,
            valid_phone: isValidPhoneNumber(phone)
        });
    };

    handleEmail = event => {
        var email = event.target.value;
        this.props.onEmailChange(CleanEmailInput(email));

        this.setState({
            [event.target.name]: email,
            valid_email: MatchEmail(email)
        });
    }

    handleConfirmEmail = event => {
        var email = event.target.value;
        this.setState({
            [event.target.name]: email,
            valid_email: MatchEmail(email) && email === this.state.email
        });
    }
}
