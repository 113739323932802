import {Auth} from "aws-amplify";
import {Form, FormField} from "grommet";
import {Lock as PasswordIcon} from "grommet-icons/es6/icons";
import React from "react";

import {AuthenticationPage, FormLabel} from ".";
import {AuthLink, AuthLinkBox, MessageBanner, FormTitle} from ".";
import {LoadingButton} from "../Loading";

export class ResetPassword extends AuthenticationPage {
    constructor(props) {
        super(props);

        this.state = {
            code: "",
            email: "",
            new_password: "",
        };
    }

    handleSendCode = async (done) => {
        if ( this.state.error )
            this.setState({ error: false });

        try {
            await Auth.forgotPassword(this.state.email);
        } catch (e) {
            // if ( e.code === 'UserNotFoundException' )
            //     e.message = 'This email is not registered. Verify the email and try again, or register for a PainPoint account.';
            // this.setState({error: e.message});
        }
        done();
        this.props.onConfirmReset({
            message: 'A 6-digit password reset code has been sent to the mobile phone registered with this email if one exists.'
        });
    };

    render() {
        const {error, email, valid_email} = this.state;
        const {message} = this.props;
        return (
            <>
                <FormTitle title="Reset Password" icon={PasswordIcon}/>
                <MessageBanner error={error} message={message}/>
                <Form>
                    <FormField
                        id="email"
                        name="email"
                        label={<FormLabel label="E-mail"/>}
                        value={email}
                        onChange={this.handleEmail}
                    />

                    <LoadingButton
                        primary
                        type="submit"
                        label="Send Confirmation Code"
                        disabled={!valid_email}
                        onClick={this.handleSendCode}
                    />
                </Form>
                <AuthLinkBox>
                    <AuthLink label="Login" onClick={this.props.onLogin}/>
                    <AuthLink label="Create Account" onClick={this.props.onSignUp} side="right"/>
                    <AuthLink label="Enter Code" onClick={this.props.onConfirmReset} side="right"/>
                </AuthLinkBox>
            </>
        );
    }
}
