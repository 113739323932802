import {Box, Grid, Image, Text} from "grommet";
import {Home, Mail, MapLocation, Phone} from "grommet-icons";
import React from "react";

/**
 * @return {string}
 */
export function CleanPhoneInput(phone) {
    const match = phone.replace(/\D+/g, '');

    if (match.length === 0) {
        return '';
    }

    if ('+' + match === phone)
        return phone;

    if (match.length === 11 && match[0] !== '+')
        return '+' + match;

    if (match.length === 10 && match[0] !== '1')
        return '+1' + match;

    return match
}

export function CleanEmailInput(email) {
    return email.trim();
}

export function CleanCodeInput(code, len) {
    if (!len) len = 6;
    return code.replace(/\D+/g, '').slice(0, len);
}

function toHexString(byteArray) {
  return Array.from(byteArray, function(byte) {
    return ('0' + byte.toString(16)).slice(-2);
  }).join('')
}

export function ConvertToUuid(uuid) {
    uuid = (uuid || '')
    const b64 = /^[a-zA-Z0-9-_/]{22}$/;
    if (uuid.search(b64) !== 0)
        return uuid;

    uuid = uuid.replace(/_/g, '+')
               .replace(/-/g,'/');
    // Re-append the ==. 32 bytes will always have a == at the end.
    const buff = new Buffer(uuid + '==', 'base64');
    let hex = toHexString(buff);
    return hex.slice(0,8) + '-' + hex.slice(8,12) + '-' +
           hex.slice(12,16) + '-' + hex.slice(16,20) + '-' + hex.slice(20,32);
}

export function PrettyPhone(phone) {
    if (!phone) return '';

    if (phone[0] === '+')
        phone = phone.substr(1, phone.length);
    if (phone[0] === '1') {
        phone = phone.substr(1, phone.length);
        return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`
    }
    return phone;
}

/**
 * @return {boolean}
 */
export function MatchEmail(email) {
    if (!email) return false;
    const re = /^\S+@\S+[.]\S\S+$/;
    return email.search(re) === 0;
}

/**
 * @return {boolean}
 */
export function MatchPhone(phone) {
    if (!phone) return false;
    const re = /^\+1[0-9]{10}$/;
    return phone.search(re) === 0;
}

/**
 * @return {boolean}
 */
export function MatchUuid(uuid) {
    const re = /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/;
    return (uuid || '').search(re) === 0;
}


export function Clinic({provider, hideInvalid, prefix="", ...props}) {
    const clinic_style = {left: "1em", top: '0.35em'};
    const left_pad = {left: "1em"};
    return (<Box
        flex={false}
        fill={false}
        width='100%'
        {...props}
    >
        <Grid
        >
            {provider.logo_url &&
                <Box basis="xxsmall" height='100px' fill={false} flex={false}>
                    <Image src={provider[`${prefix}logo_url`]} fit="contain" alt="Provider Logo"/>
                </Box>
            }
            <Box direction='row'>
                <Home size="28px" color="brand"/>
                <Text
                    margin={clinic_style}>{provider && provider[`${prefix}name`]}</Text>
            </Box>

            {(!hideInvalid || provider[`${prefix}email`]) &&
            <Box direction='row' pad={left_pad}>
                <Mail style={{marginTop: '0.5em'}} size="18px" color="brand"/>
                <Text
                    margin={clinic_style}>{provider && provider[`${prefix}email`]}</Text>
            </Box>
            }

            {(!hideInvalid || provider[`${prefix}phone`]) &&
            <Box direction='row' pad={left_pad}>
                <Phone style={{marginTop: '0.5em'}} size="18px" color="brand"/>
                <Text
                    margin={clinic_style}>{provider && provider[`${prefix}phone`]}</Text>
            </Box>
            }

            {(!hideInvalid || provider[`${prefix}address`]) &&
            <Box direction='row' pad={left_pad}>
                <MapLocation style={{marginTop: '0.5em'}} size="18px" color="brand"/>
                <Text
                    margin={clinic_style}>{provider && provider[`${prefix}address`]}</Text>
            </Box>
            }
        </Grid>
    </Box>);
}