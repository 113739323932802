import React, {} from 'react';
import {
    CheckBox,
    Select,
    Box,
    Button,
    Form,
    FormField,
    TextInput,
    TextArea,
    RangeInput,
    Grid,
    RadioButtonGroup,
    Image,
    Text
} from 'grommet';


import { goToAnchor } from 'react-scrollable-anchor';

import {Alert} from 'grommet-icons';

import queryString from 'query-string'
import {FaRegSmileBeam, FaRegGrimace} from 'react-icons/fa';
import {theme} from "../theme";

import {Dialog, Title, DialogButtons, Clinic} from '../components';

import {
    ApiCall,
    ApiCallNoAuth,
    roleId,
    NetPromoterDialog,
    ConvertToUuid,
    MatchUuid,
    LoadingItem
} from "../components";
import {Download} from "../pages";



const btnPrimary = {borderRadius: '0.75em', color: 'white'};
const btnGeneric = {borderRadius: '0.75em', color: '#5a5a5a'};

/**
 * Generates a GUID string.
 * @returns {String} The generated GUID.
 * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
 * @author Slavik Meltser (slavik@meltser.info).
 * @link https://slavik.meltser.info/?p=142
 */
function guid() {
    function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }

    return _p8() + _p8(true) + _p8(true) + _p8();
}

export function ToggleYN(props) {
    const [value, setValue] = React.useState('');

    const updateValue = event => {
        setValue(event.target.value);
        props.onChange(props.name, event.target.value === 'yes');
    }

    return (
        <Box>
            <Grid
                rows={['xxsmall']}
                columns={['flex', 'flex']}
                gap='small'
                areas={[
                    {name: 'left', start: [0, 0], end: [0, 0]},
                    {name: 'right', start: [1, 0], end: [1, 0]}
                ]}
            >
                <Box gridArea='left'>
                    <Button style={btnGeneric} active={value === 'yes'} label='Yes' value='yes' onClick={updateValue}/>
                </Box>
                <Box gridArea='right'>
                    <Button style={btnGeneric} active={value === 'no'} label='No' value='no' onClick={updateValue}/>
                </Box>
            </Grid>
        </Box>
    );
}

export function ToggleYNU(props) {
    const [value, setValue] = React.useState('unset');

    const updateValue = event => {
        const val = event.target.value;
        const store = val === 'yes' ? true :
            (val === 'no' ? false : val);
        setValue(event.target.value);
        props.onChange(props.name, store);
    }

    return (
        <Box>
            <Grid
                rows={['xxsmall']}
                columns={['flex', 'flex', 'flex']}
                gap='small'
                areas={[
                    {name: 'left', start: [0, 0], end: [0, 0]},
                    {name: 'center', start: [1, 0], end: [1, 0]},
                    {name: 'right', start: [2, 0], end: [2, 0]}
                ]}
            >
                <Box gridArea='left'>
                    <Button style={btnGeneric} active={value === 'yes'} label='Yes' value='yes' onClick={updateValue}/>
                </Box>
                <Box gridArea='center'>
                    <Button style={btnGeneric} active={value === 'no'} label='No' value='no' onClick={updateValue}/>
                </Box>
                <Box gridArea='right'>
                    <Button style={btnGeneric} active={value === 'unassigned'} label='N/A' value='unassigned' onClick={updateValue}/>
                </Box>
            </Grid>
        </Box>
    );
}

export function PainLocation(props) {
    const [value, setValue] = React.useState('Head');

    const updateOption = event => {
        setValue(event.option);
        props.onChange(props.name, event.option);
    }

    props.onChange(props.name, value);

    return (
        <Select
            options={['Head', 'Neck', 'Mid-Back', 'Low-Back', 'Arm', 'Hand', 'Leg', 'Foot', 'Chest']}
            value={value}
            onChange={updateOption}
        />
    );
}

export function MakeCheckbox({label, name, onChange, ...props}) {
    const [checked, setChecked] = React.useState(false);

    const updateChecked = event => {
        setChecked(event.target.checked);
        onChange(name, event.target.checked);
    }

    if (typeof (label) === "string")
        label = label.split('##').map((v, i) => {
            return (<span key={"sp" + i}>{v}
                <wbr/></span>)
        });

    return (
        <Box pad={{vertical: 'small'}}>
            <CheckBox
                checked={checked}
                label={(<span style={{'textTransform': 'capitalize'}}>{label}</span>)}
                onChange={updateChecked}
            />
        </Box>
    );
}


export function MakeSlider(props) {
    const [value, setValue] = React.useState(-1);

    const updateValue = event => {
        setValue(event.target.value);
        props.onChange(props.name, event.target.value);
    }

    const painFree = event => {
        setValue(0);
        props.onChange(props.name, 0);
    }

    return (
        <Grid
            rows={['xxsmall', 'xxsmall']}
            columns={['xxsmall', 'flex', 'xxsmall']}
            gap='small'
            areas={[
                {name: 'left', start: [0, 0], end: [0, 0]},
                {name: 'center', start: [1, 0], end: [1, 0]},
                {name: 'right', start: [2, 0], end: [2, 0]},
                {name: 'free', start: [0, 1], end: [2, 1]}
            ]}
        >
            <Box gridArea='left' pad={{left: 'medium'}}>
                <FaRegSmileBeam size='small'/>
            </Box>
            <Box
                gridArea='center'
                flex='grow'
                margin={{top: '0.6em'}}
            >
                <RangeInput
                    min={0}
                    max={100}
                    step={1}
                    value={value}
                    onChange={updateValue}
                />
            </Box>
            <Box gridArea='right' pad={{right: 'medium'}}>
                <FaRegGrimace size='small'/>
            </Box>
            <Box gridArea='free'>
                <Button
                    active={value === 0}
                    label='Symptom Free'
                    onClick={painFree}
                    style={btnGeneric}
                />
            </Box>
            <Box align="center">

            </Box>
        </Grid>
    );

}

export function JournalTextarea({question, onChange}) {
    const nativeHandler = (event) => {
        onChange(event.target.name, event.target.value);
    };

    return (
        <TextArea placeholder={question.placeholder} name={question.id} onChange={nativeHandler}/>
    );
}

export function JournalSelectOneSub3({question, onChange, ...props}) {
    const [value, setValue] = React.useState('');

    const updateValue = event => {
        setValue(event.target.value);
        onChange(question.id, event.target.value);
    }

    var areas = [
        {name: 'idx0', start: [0, 0], end: [0, 0]},
        {name: 'idx1', start: [1, 0], end: [1, 0]}
    ];
    var columns = ['flex', 'flex'];

    if (question.answers.length === 3) {
        areas.push({name: 'idx2', start: [2, 0], end: [2, 0]});
        columns.push('flex');
    }

    return (
        <Box pad={{bottom: 'small'}}>
            <Grid
                rows={['xxsmall']}
                columns={columns}
                gap='xxsmall'
                areas={areas}
            >
                {
                    question.answers.map((v, i) => {
                        return (
                            <Box key={"answer-" + i} gridArea={'idx' + i}>
                                <Button style={{'textTransform': 'capitalize', ...btnGeneric}}
                                        active={value === (v.value ? v.value : v)}
                                        label={v.label ? v.label : v}
                                        value={v.value ? v.value : v}
                                        onClick={updateValue}/>
                            </Box>
                        );
                    })
                }
            </Grid>
        </Box>
    );
}

export function JournalSelectOne({question, onChange, ...props}) {
    const [value, setValue] = React.useState('');
    const lref = React.useRef();
    const rref = React.useRef();

    const updateOption = event => {
        setValue(event.target.value);
        onChange(question.id, event.target.value);

    }

    const plist = question.answers;
    const len = plist.length;
    if (len <= 3)
        return <JournalSelectOneSub3 question={question} onChange={onChange}/>;

    var row_size;
    if (len <= 6)
        row_size = 'xsmall';
    else if (len <= 14)
        row_size = 'small';
    else
        row_size = 'medium';

    const left = plist.slice(0, len / 2)
    const right = plist.slice(len / 2)

    return (
        <Grid
            margin={{top: 'small', left: 'small'}}
            rows={[row_size]}
            columns={['flex', 'flex']}
            gap='small'
            areas={[
                {name: 'left', start: [0, 0], end: [0, 0]},
                {name: 'right', start: [1, 0], end: [1, 0]}
            ]}
            onMouseOut={() => {
            }}
        >
            <Box gridArea='left'>
                <RadioButtonGroup ref={lref}
                                  style={{'textTransform': 'capitalize'}}
                                  name={"radio-left" + question.id}
                                  options={left}
                                  value={value}
                                  onChange={updateOption}/>
            </Box>
            <Box gridArea='right'>
                <RadioButtonGroup ref={rref}
                                  style={{'textTransform': 'capitalize'}}
                                  name={"radio-right" + question.id}
                                  options={right}
                                  value={value}
                                  onChange={updateOption}/>
            </Box>
        </Grid>
    );
}

export function JournalSelectMulti({question, onChange}) {
    const len = question.answers.length;
    const ans = len / 2;
    const left_answers = question.answers.slice(0, ans);
    const right_answers = question.answers.slice(ans, question.answers.length);

    var row_size;
    if (len <= 6)
        row_size = 'xsmall';
    else if (len <= 12)
        row_size = 'small';
    else
        row_size = 'medium';

    return (

        <Grid
            rows={[row_size]}
            columns={['flex', 'flex']}
            gap='small'
            areas={[
                {name: 'left', start: [0, 0], end: [0, 0]},
                {name: 'right', start: [1, 0], end: [1, 0]},
            ]}
        >
            <Box gridArea='left'>
                {
                    left_answers.map((v, i) => {
                        return <MakeCheckbox key={"left-key-" + question.id + i}
                                             label={v.label ? v.label : v}
                                             name={question.id + ":" + (v.value ? v.value : v)}
                                             onChange={onChange}/>
                    })
                }
            </Box>
            <Box gridArea='right'>
                {
                    right_answers.map((v, i) => {
                        return <MakeCheckbox key={"right-key-" + question.id + i}
                                             label={v.label ? v.label : v}
                                             name={question.id + ":" + (v.value ? v.value : v)}
                                             onChange={onChange}/>
                    })
                }
            </Box>
        </Grid>);
}

export function JournalPainSlider({question, onChange}) {
    return (<MakeSlider onChange={onChange} name={question.id}/>);
}

export function JournalTextInput({question, onChange}) {
    const nativeHandler = (event) => {
        onChange(event.target.name, event.target.value);
    }

    return (
        <>
            <TextInput placeholder={question.placeholder} name={question.id} onChange={nativeHandler}/>
            <Box margin={{top: 'large'}}/>
        </>
    )
}

const fontWeight = {fontWeight: 'bold'};
const rightMargin = {right: '0.5em'};

export function BuildQuestion({question, onChange, isValid, attempt, ...props}) {
    if (!isValid)
        isValid = () => {
            return true;
        };

    var TypeFunction = {
        'select:one': JournalSelectOne,
        'select:multi': JournalSelectMulti,
        'text:area': JournalTextarea,
        'text:input': JournalTextInput,
        'pain:slider': JournalPainSlider
    }[question.type];

    if (TypeFunction === undefined)
        TypeFunction = JournalTextarea;

    return (
        <div id={question.id}>
            <FormField
                contentProps={{ border: false }}
                label={(
                    <Box direction='row' style={fontWeight}>
                        {question.required && !isValid(question.id) && attempt &&
                            <Box margin={rightMargin}>
                                <Alert color='red'/>
                            </Box>
                        }
                        {
                            question.question
                        }
                        {question.required &&
                            <Text
                                margin={{left: '0.5em'}}
                                size='10px'
                                color={isValid(question.id) ? 'white' : 'red'}
                            >
                                (required)
                            </Text>
                        }
                    </Box>
                )}>
                <Box pad={{bottom: 'large', top: 'small'}} {...props}>
                    <TypeFunction question={question} onChange={onChange}/>
                </Box>
            </FormField>
        </div>
    );
}

const TackerNotification = ({tracker, onReject, onAccept, onClose, loading, error, ...props}) => {
    if (!tracker)
        return null;

    return (
    <Dialog mobile={props.mobile}>
        <Title onClose={onClose}>
            Provider link request
        </Title>
        <Box margin={{top: '1em', bottom: '2em', horizontal: '2em'}} direction='column'>
            <Box margin={{horizontal: '1em', top: '0.5em', bottom: '2em'}}>
                <Clinic hideInvalid={true} provider={tracker} prefix={"provider_"}/>
            </Box>
            Do you want this provider to have access to your PainPoint journal entries?<br/><br/>
            If you accept the provider will:
            <ol>
                <li>
                    Have access to your full journal history.
                </li>
                <li>
                    Receive notifications when you submit a journal entry.
                </li>
                <li>
                    Be able to communicate with you directlty using PainPoint.
                </li>
            </ol>
        </Box>
        { error &&
            <Text color={theme.dash.bad} margin={{ horizontal: '2em', bottom: '2em'}}>
                {error} Please contact <a href='mailto:info@painpointanalytics.com'>info@painpointanalytics.com</a>.
            </Text>
        }
        <DialogButtons>
            <Button
                color={theme.dash.bad}
                primary
                disabled={loading}
                label={<Text size='14px' color='white'>Reject Request</Text>}
                style={{ padding: '1em', borderRadius: '0.75em' }}
                margin={{left: '1em'}}
                onClick={onReject}
            />
            <Button
                label={<Text size='14px' color='white'>Accept Request</Text>}
                style={{ padding: '1em', borderRadius: '0.75em' }}
                primary
                disabled={loading}
                onClick={onAccept}
            />
        </DialogButtons>
    </Dialog>)
}


const logoMargin = {bottom: '1em'};
const reviewMargin = {horizontal: 'auto', vertical: '1em'};
const reviewStyle = {width: '6em', height: '6em'};
const bottomMargin = {bottom: '0em'};
const horizontalPad = {horizontal: '0'};
const titleMargin = {top: '1em', bottom: '3em'};

export class MainForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { questions: {}, questions_loaded: false, ...this.getDefaultState() };

        props.setAppDashboard(props.user.role > roleId("patient"));

        fetch('/questions.json').then((q) => {
            return q.json();
        }).then((data) => {
            this.setState({questions: data, questions_loaded: true});
        });
    }

    getDefaultState() {
        const values = queryString.parse(this.props.location.search || '')
        const uuid = ConvertToUuid(values.uuid || values.u);
        const bad_uuid = !MatchUuid(uuid);

        return {
            submitted: false,
            submitting: false,
            bad_uuid,
            failed_to_submit: false,
            patient: false,
            trackers: false,
            tracker: false,
            ltrackers: true,
            tracker_error: false,
            patient_provider: false,
            event: {},
            validated: {},
            uuid,
            euid: guid(),
            show_netpromoter: true,
            show_google_review: false
        };
    }

    componentDidMount() {
        this.loadUser();
        this.loadProvider();
        this.checkInvites();

        const values = queryString.parse(this.props.location.search)
        if (values.uuid !== this.state.uuid)
            this.setState({ uuid: values.uuid });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( prevState.submitted === true )
            this.setState({ submitted: false });
    }

    key = 'painpoint.journal.uuid';

    loadUser = async () => {
        if (this.state.patient) return;

        const {user} = this.props;
        if (user.uuid === this.state.uuid) {
            if (user.role === roleId("patient"))
                localStorage.setItem(this.key, user.uuid);
            this.setState({patient: Object.assign({}, user)});
            return;
        }

        if (user.role > roleId("patient")) {
            localStorage.removeItem(this.key);
        } else {
            this.setState({patient: user});
            return;
        }

        const no_user = Object.keys(user).length === 0;

        if (!no_user) {
            ApiCall(user, 'get', `/users/${this.state.uuid}`).then((result) => {
                this.setState({patient: result});
            }).catch((ex) => {
                console.log(ex.rep);
            });
        }
    }

    loadProvider = async () => {
        let {bad_uuid, uuid} = this.state;

        if (uuid === undefined || uuid === '') {
            uuid = localStorage.getItem(this.key);
            bad_uuid = !MatchUuid(uuid);
            this.setState({ bad_uuid, uuid });
            if (bad_uuid)
                return;
        }

        ApiCallNoAuth('get', `/journal?uuid=${uuid}&euid=${this.state.euid}`).then((result)=> {
            this.setState({ patient_provider: result });
            localStorage.setItem(this.key, uuid);
            this.props.setReviewLink(result.google_review_url);
        }).catch((ex) => {
            if (ex.response && ex.response.status === 400 && ex.response.data) {
                const {code} = ex.response.data;
                if ( code === 'no-provider' || code === 'invalid-uuid' )
                    this.setState({bad_uuid: true});
            }
        });
    }

    submitEvent = (type) => {
        const close = this.closeDialog(type);
        return async (obj) => {
            const journal = {
                uuid: this.state.uuid,
                euid: guid(),
                event_type: 'meta',
                event: obj
            };

            ApiCallNoAuth('post', '/journal', journal);
            close();
        };
    }

    closeDialog = (type) => {
        return () => {
            let nstate = {};
            nstate["show_" + type] = false;
            this.setState(nstate);
        };
    }

    handleEvent = (name, value) => {
        var newState = {
            event: this.state.event,
            validated: this.state.validated
        }

        newState.event[name] = value
        newState.validated[name] = true
        this.setState(newState);
    }

    nativeHandler = (event) => {
        this.handleEvent(event.target.name, event.target.value);
    }

    submitJournal = async () => {
        const journal = {
            uuid: this.state.uuid,
            euid: this.state.euid,
            event: this.state.event,
            event_type: 'journal'
        };

        try {
            if (this.props.user && this.props.user.role >= roleId('staff')) {
                let result = await ApiCall(this.props.user, 'post', '/journal', journal);
                this.setState({ euid: guid(), submitting: false })
                if (result.success)
                    this.setState({submitted: true, failed_to_submit: false, euid: guid()})
            } else {
                let result = await ApiCallNoAuth('post', '/journal', journal);
                if (result.success) {
                    this.setState({
                        submitted: true,
                        failed_to_submit: false,
                        euid: guid(),
                        show_netpromoter: result.netpromoter,
                        show_google_review: result.google_review
                    });
                }
            }
        } catch (result) {
            const data = (result && result.response && result.response.data) ? result.response.data : {}
            const msg = data.detail ? data.detail : (data.errorMessage ? data.errorMessage : "Unknown");
            this.setState({failed_to_submit: msg, submitted: false, submitting: false})
        }
    };

    submitHandler = () => {
        if (!this.validateForm())
            return;

        this.setState({
            submitting: true
        });
        this.submitJournal();
    }

    resetHandler = () => {
        this.setState(this.getDefaultState(), ()=> {
            this.loadProvider();
            this.checkInvites();
        });
    }

    validateForm() {
        let update = {};
        let valid = true;
        let firstId;

        const questions = this.state.questions.questions;
        for (var idx in questions) {
            const que = questions[idx];
            if (!que.required)
                continue;

            if (this.state.event[que.id] === undefined) {
                if ( firstId === undefined )
                    firstId = que.id;
                valid = false;
                update[que.id] = false;
            } else
                update[que.id] = true
        }

        if (valid) return true;

        this.setState({validated: update, attempted: true});
        goToAnchor(firstId, false);
    }

    isValid(name) {
        return this.state.validated[name] === true;
    }

    isFormValid() {
        if (this.state.questions.questions) {
            return this.state.questions.questions.reduce((accum, cv) => {
                if (!cv.required)
                    return accum;
                return accum && this.isValid(cv.id);
            }, true);
        }
        return false;
    }

    checkInvites = async () => {
        const {user} = this.props;
        if (user.role !== roleId("patient"))
          return false;

        this.setState({ ltrackers: true });
        ApiCall(user, 'get', `/users/current/trackers`).then((trackers) => {
            const tracker = this.firstApplicable(trackers);
            this.setState({trackers, tracker, ltrackers: false});
        }).catch((ex) => {
            console.log(ex.rep);
            this.setState({trackers: [], tracker: undefined, ltrackers: false});
        });
        return true;
    }

    link_with(code, done) {
        const {user} = this.props;
        ApiCall(user, 'post', `/users/current/relink`, { code })
            .then((result) => {
                this.props.loadUser(undefined, () => done());
                if (result.success) {
                    // setMessage('Your account has now been linked to the new provider.');
                    // setError('');
                } else {
                    // setMessage("");
                    // setError("Invalid provider code!");
                }
            })
            .catch(result => {
                this.setState({ tracker_error:"An error occurred while attempting to link with the provider." })
            });
    }

    acceptTracker(trkr) {
        this.link_with(trkr.provider_code, () => {
            this.checkInvites();
            this.loadProvider();
        });
    }

    rejectTracker(trkr) {
        const {user} = this.props;
        this.setState({ ltrackers: true });

        console.log("Reject: ", trkr)
        ApiCall(user, 'post', `/users/current/trackers/${trkr.id}`, {reject: true})
        .then((success) => {
            console.log(success);
            this.setState({ ltrackers: this.checkInvites() });
        })
        .catch((ex) => {
            this.setState({
                ltrackers: this.checkInvites(),
                tracker_error: "An error occurred while rejecting the link request."
            });
            console.log(ex.rep);
        });
    }

    firstApplicable(trks) {
        if (!trks)
            return undefined

        for ( let x = 0; x < trks.length; ++x ) {
            if (!trks[x].rejected &&
                !trks[x].unlinked &&
                 trks[x].provider_id !== this.props.user.provider_id)
                return trks[x];
        }

        return undefined;
    }

    render() {
        const {submitted, submitting, patient, patient_provider,
               questions, attempted, bad_uuid, questions_loaded,
               tracker, ltrackers, tracker_error} = this.state
        if (!questions_loaded)
            return <LoadingItem loading={true}
                                pad='0.5em'
                                label="Loading your journal..."
                                width='100%'/>;

        return (<>
            <TackerNotification
                    mobile={this.props.mobile}
                    tracker={tracker}
                    error={tracker_error}
                    loading={ltrackers}
                    onClose={()=> this.setState({tracker:undefined})}
                    onReject={() => this.rejectTracker(tracker)}
                    onAccept={() => this.acceptTracker(tracker)}
            />
            {!submitted ? (
            <Box>
                {patient && patient.id !== this.props.user.id &&
                <Box
                    flex={false}
                    background='backgroundBody'
                    pad='small'
                >
                    <b><Text size='16px' color='black'>{patient.first_name} {patient.last_name}'s Journal</Text></b>
                </Box>
                }
                <Box
                    direction='row-responsive'
                    breakpoint='medium'
                    flex={true}
                    fill={true}
                    id='journal-div'
                    overflow={{horizontal: 'hidden', vertical: 'scroll'}}
                    margin={{top: '1em', right: 'auto'}}
                    style={{maxWidth: '800px'}}
                    justify='center'
                    gap='small'
                    background='white'
                    pad={this.props.mobile ? '1em' : '0em'}
                >
                    <Box
                        flex={false}
                        basis='90%'
                        pad={horizontalPad}
                    >
                        {patient_provider &&
                        <Box flex={false} fill={false} pad='1em' align='center' margin={titleMargin}>
                            {patient_provider.logo_url ?
                                (
                                    <Box width={'20em'}>
                                        <Image src={patient_provider.logo_url} fit="contain" alt="Provider Logo"/>
                                    </Box>
                                ) :
                                <Text weight='bold' size='2em' align='center'
                                      margin={bottomMargin}>{patient_provider.name}</Text>
                            }
                        </Box>
                        }
                        {bad_uuid &&
                        <Box flex={false} margin={{bottom: '2em'}}>
                            <Text weight='bold' color='error' size='2em' margin={{bottom: '1em'}}>
                                Oops! There seems to be a problem with your journal.
                            </Text>
                            <Text weight='bold' color='error' size='1em'>
                                This journal URL is invalid. Please verify you are using the URL
                                given to you by your care provider.<br/><br/>
                                If you have any questions, call your provider or email us at <a
                                href="mailto:info@painpointanalytics.com">info@painpointanalytics.com</a>
                                with your provider's clinic name and we'll be happy to help correct the issue.<br/><br/>
                                Sorry for the inconvenience!<br/>
                                The PainPoint Team
                            </Text>
                        </Box>
                        }
                        <Form>
                            {questions.questions &&
                            questions.questions.map((v, i) => {
                                return (<Box key={v.id} id={v.id}>
                                    <BuildQuestion question={v}
                                                   onChange={this.handleEvent} isValid={(n) => this.isValid(n)}
                                                   attempt={attempted}
                                                   flex={false} fill={false}
                                    />
                                </Box>);
                            })
                            }

                            {
                                this.state.failed_to_submit &&
                                <Box pad='small'>
                                    <Text color='DarkRed'>
                                        There was an error submitting your journal
                                        ("{this.state.failed_to_submit.toString()}").
                                        Feel free to try again.
                                        If you continue having issues submitting, please contact your provider or
                                        email <a
                                        href="mailto:info@painpointanalytics.com">info@painpointanalytics.com</a> with
                                        the above
                                        error message code.
                                    </Text>
                                </Box>
                            }
                            {
                                !this.isFormValid() &&
                                <Box pad='small'>
                                    <Text color='DarkRed'>
                                        Please complete all fields marked <Text color='red'>(required)</Text>:
                                        <ul>
                                            {questions.questions &&
                                            questions.questions.map((v, i) => {
                                                if (this.isValid(v.id) || !v.required)
                                                    return '';
                                                return (
                                                    <li key={"error-list-" + v.id}>
                                                        <a href={'#' + v.id}>{v.question}</a>
                                                    </li>
                                                );
                                            })
                                            }
                                        </ul>
                                    </Text>
                                </Box>
                            }

                            <Box pad={{top: 'xxsmall', bottom: '2em'}}>
                                <Button
                                    primary color={this.isFormValid() ? 'brand' : 'status-error'}
                                    style={btnPrimary}
                                    disabled={this.state.submitting || this.state.bad_uuid}
                                    label={submitting ? (
                                        <Box direction='row' fill flex>
                                            <Box margin={{left: 'auto'}}/>
                                            <Box width='1em' height='1em' margin={{right: '0.5em', top: '2px'}}>
                                                <Image src="/loading.gif" fit="contain"/>
                                            </Box>
                                            Submitting...
                                            <Box margin={{right: 'auto'}}/>
                                        </Box>
                                    ) : this.isFormValid() ? 'Submit' : 'Scroll to Required Fields'}
                                    onClick={this.submitHandler}/>
                            </Box>

                            <Box margin={{top: 'large'}}/>
                            <Box margin={{top: 'large'}}/>
                            <Box margin={{top: 'large'}}/>
                        </Form>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box
                direction='row-responsive'
                breakpoint='medium'
                flex={false}
                fill={false}
                overflow={{horizontal: 'hidden'}}
                margin={{right: 'auto'}}
                justify='center'
                gap='small'
                width={'40em'}
                pad={this.props.mobile ? '2em' : '0em'}
            >
                <Box flex={false} basis='large' background='white' align='center' pad='1em'>
                    {patient_provider &&
                    <Box basis="xxsmall" fill={false} flex={false} margin={logoMargin}>
                        {patient_provider.logo_url ?
                            <Image src={patient_provider.logo_url} fit="contain" alt="Provider Logo"/> :
                            <Text weight='bold' size='2em' align='center'
                                  margin={bottomMargin}>{patient_provider.name}</Text>
                        }
                    </Box>
                    }

                    {false && patient_provider && patient_provider.google_review_url &&
                    <Box fill={false} flex={false} margin={reviewMargin}>
                        <a class='basic' href={patient_provider.google_review_url} target="blank_">
                            <Image src='/google-review-icon.png' flex={false} fill={false} fit="contain"
                                   alt="Review us on google." style={reviewStyle}/>
                        </a>
                    </Box>
                    }

                    Thank you for submitting your pain journal. Your pain journal will be reviewed shortly!
                    <Button margin={{top: 'large'}} style={btnPrimary}
                            label="Make Another Journal Entry" primary
                            onClick={this.resetHandler}/>
                    <NetPromoterDialog show={false}
                                       clinicName={this.state.patient_provider.name}
                                       onConfirm={this.submitEvent("netpromoter")}/>
                    {false && <Download embedded={true}/>}
                </Box>
            </Box>
        )}
        </>);
    }
}
