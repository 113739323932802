import React from "react";

import {
    Box, Button, Text, TextInput,
    Menu, FormField, CheckBox, Anchor
} from "grommet";

import {
    ApiCall,
    FinalizeDialog,
    DaysSinceToday,
    List,
    ListItem,
    ListContainer,
    ListHeader,
    LoadingItem,
    UserContext,
    roleId, ApiCallNoAuth, LoadingButton,
    MatchPhone, MatchEmail, CleanPhoneInput, CleanEmailInput, OnboardingGuidePrompts
} from "../components";

import {
    More,
    Trash,
    Chat,
    Magic,
    Checkmark
} from 'grommet-icons';

import FuzzySearch from "fuzzy-search";

import { PrettyPhone, SearchBar, SearchBarButton, PageHeader, ToDate } from '../components';
import {AddPatientIcon, RefreshIcon} from '../icons';
import { theme } from "../theme";
import {Route} from "react-router-dom";
import queryString from "query-string";

function DownloadPage({last, time, created, past_due}) {
    if (!time)
        return (<li className={(created || !last) ? 'inactive first' : (past_due ? 'due first' : 'active first')}>
            <Text size='12px'>Download
                <wbr/>
                Link Sent</Text>
        </li>);

    return (<li className={'complete first'}>
        <Text size='12px'>Download<wbr/>Clicked</Text>
    </li>);
}

function AppStore({last, time, created, past_due}) {
    if (!time)
        return (<li className={created ? 'inactive' : (past_due ? 'due' : 'active')}>
            <Text size='12px'>
                Visit
                <wbr/>
                Store</Text>
        </li>);

    return (<li className={'complete'}>
        <Text size='12px'>Visited<wbr/>Store</Text>
    </li>);
}

function SignedUp({last, time, past_due}) {
    if (!time)
        return (<li className={past_due ? 'due' : (!last ? 'inactive' : 'active')}>
            <Text size='12px'>Creating
                <wbr/>
                Account</Text>
        </li>);

    return (<li className={'complete'}>
        <Text size='12px'>Account<wbr/>Created</Text>
    </li>);
}


function LoggedIn({last, time, past_due}) {
    if (!time) {
        const incStyle = past_due ? 'due' : (!last ? 'inactive' : 'active');
        return (<li className={incStyle}>
            <Text size='12px'>First
                <wbr/>
                Login</Text>
        </li>);
    }
    return (<li className={'complete'}>
        <Text size='12px'>First<wbr/>Login</Text>
    </li>);
}

function Linked({time, unlinked, rejected, last, past_due}) {
    if (time)
        return (<li className={'complete'}>
            <Text size='12px'>Account<wbr/>Linked</Text>
        </li>);

    if (unlinked)
        return (<li className={'due'}>
            <Text size='12px'>Account<wbr/>Unlinked</Text>
        </li>);

    if (rejected)
        return (<li className={'due'}>
            <Text size='12px'>Invite<wbr/>Rejected</Text>
        </li>);

    const incStyle = past_due ? 'due' : (!last ? 'inactive' : 'active');
    return (<li className={incStyle}>
        <Text size='12px'>Invite<wbr/>Sent</Text>
    </li>);
}

function Journaled({time, last, past_due}) {
    if (!time) {
        const incStyle = past_due ? 'due' : (!last ? 'inactive' : 'active');
        return (<li className={`${incStyle} last`}>
            <Text size='12px'>Journal
                <wbr/>
                Entry</Text>
        </li>);
    }

    return (<li className={'complete last'}>
        <Text size='12px'>Journal<wbr/>Entry</Text>
    </li>);
}

const check_due = (date) => {
    if (!date)
        return false;

    if (date instanceof String)
        date = ToDate(date)

    const hours = Math.abs(Date.now() - date) / 36e5;
    return hours > 24;
};

function Status({created_at, signed, login, linked, unlinked, rejected, journal, download_page, appstore, link_count}) {
    return (<ul className={"progress"}>
        <DownloadPage time={download_page} last={!!link_count} created={!!signed} past_due={check_due(created_at)}/>
        {/*<AppStore time={appstore} created={!!signed} past_due={check_due(created_at)}/>*/}
        <SignedUp time={signed} last={!!download_page} past_due={check_due(created_at)}/>
        <LoggedIn time={login} last={!!signed} past_due={check_due(signed)}/>
        <Linked time={linked} unlinked={unlinked} rejected={rejected} last={!!login} past_due={check_due(login)}/>
        <Journaled time={journal} last={!!linked} past_due={check_due(linked)}/>
    </ul>);
}

function ActionMenu({ patient, onFinalize, onLink, props }) {
    const [ requested, setRequested ] = React.useState(false);
    return (
        <Menu
          items={[
            {
              label: <Box alignSelf="center">Send app store link.</Box>,
              icon: (
                  <Box pad="xsmall">
                      <Chat size="small" color="darkgreen"/>
                  </Box>
              ),
              onClick:
                () => {
                    ApiCallNoAuth("post", "/auth/app_link", { phone: patient.phone, tracker_id: patient.id })
                        .then(()=>{
                            onLink(patient);
                        }).catch(()=>{});
                }
            },
            {
              label: <Box alignSelf="center">Remove from Onboarding.</Box>,
              icon: (
                  <Box pad="xsmall">
                      <Trash size="small" color="green"/>
                  </Box>
              ),
              onClick: () => { onFinalize(patient); }
            },
          ]}
        >
            <Box pad={{horizontal: "small"}}>
                <More size={"24px"}/>
            </Box>
        </Menu>);
}

const percentComplete = (patient) => {
    if (!!patient.journal) {
        if (!patient.linked)
            return "75";
        return "100";
    }

    if (!!patient.linked)
        return "75";

    if (!!patient.login)
        return "50";

    if (!!patient.signup)
        return "25";

    return "0";
};

const rowMargin = { top: 'auto', bottom: 'auto' };
const rowMarginFinal = { top: 'auto', bottom: 'auto', left: 'auto' };
const rowPad = { right: '0.5em' };

const container_style = { width: '44px', height: '44px', marginTop: '20px' };
const icon_size = "18px";

const OnboardingRow = ({patient, index, onFinalize, onLink, ...rest}) => {
    const complete = !!patient.journal && !!patient.linked;
    return (
        <ListItem justify="between"
                  align="start"
                  width='100%'
                  className='patient-data-row'
                  round='0.75em'
                  {...rest}
        >
            <Box direction='column' width='100%'>
                <Box direction='row' width='100%' height='4em'>
                    <Box direction="row"
                         align="start"
                         basis="2em"
                         margin={rowMargin}
                         pad={rowPad}
                         flex={false}
                         className="priority-1"
                    >
                        <Text size="14px"
                              style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          { complete &&
                            <Box style={container_style}>
                              <Checkmark size={icon_size} color="Green"/>
                            </Box>
                          }
                        </Text>
                    </Box>
                    <Box direction="column"
                         align="start"
                         basis="14em"
                         margin={rowMargin}
                         pad={rowPad}
                         flex={false}
                         className="priority-1"
                    >
                        <Text size="16px"
                              style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {!!patient.linked &&

                            <Route {...rest} render={props => {
                                return (<Anchor label={patient.name} onClick={() => {
                                    props.history.push("/history/" + patient.user_id);
                                }}/>);
                            }}/>
                            }
                          {!patient.linked && patient.name}
                        </Text>
                        <Text size="13px">
                            {PrettyPhone(patient.phone)}
                        </Text>
                        <Text size="13px"
                              style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {patient.email}
                        </Text>
                    </Box>

                    <Box direction="row"
                        align="start"
                        basis="5em"
                        flex={false}
                        margin={rowMargin}
                        pad={rowPad}
                        className="priority-5"
                        >
                          <Text size="14px">
                            {DaysSinceToday(patient.created_at)}
                          </Text>
                    </Box>

                    <Box direction="row"
                        align="start"
                        basis="5em"
                        flex={false}
                        margin={rowMargin}
                        pad={rowPad}
                        className="priority-5"
                        >
                          <Text size="18px">
                              {percentComplete(patient)}%
                          </Text>
                    </Box>

                    <Box direction="row"
                         align="start"
                         flex={false}
                         width={"25em"}
                         margin={rowMargin}
                         pad={rowPad}
                         className="priority-1"
                    >
                        <Status created_at={ToDate(patient.created_at)}
                                signed={ToDate(patient.signup)}
                                login={ToDate(patient.login)}
                                linked={ToDate(patient.linked)}
                                unlinked={ToDate(patient.unlinked)}
                                rejected={ToDate(patient.rejected)}
                                journal={ToDate(patient.journal)}
                                download_page={ToDate(patient.download_page)}
                                appstore={ToDate(patient.appstore)}
                                link_count={patient.download_links}
                        />
                    </Box>

                    <Box direction="row"
                         align="start"
                         basis="4em"
                         margin={rowMarginFinal}
                         flex={false}
                         className="priority-1"
                    >
                        <ActionMenu patient={patient}
                                    onFinalize={onFinalize}
                                    onLink={onLink}
                        />
                    </Box>
                </Box>
            </Box>
        </ListItem>
    );
}

const NewUserPane = ({onCancel, onCreate, show, training, error}) => {
    const [ name, setName ] = React.useState('');
    const [ phone, setPhone ] = React.useState('');
    const [ email, setEmail ] = React.useState('');
    const [ send_link, setSendLink ] = React.useState(false);
    const [ in_office, setInOffice ] = React.useState(false);

    const _userValid = () => {
        return (name || '').length > 0 && MatchPhone(phone) && MatchEmail(email);
    }

    const _setName = (event) => {
        const value = event?.target?.value?.substring(0, 255);
        setName(value);
    }

    const _setEmail = (event) => {
        const value = CleanEmailInput(event?.target?.value?.substring(0, 255));
        setEmail(value);
    }

    const _setPhone = (event) => {
        const value = CleanPhoneInput(event?.target?.value?.substring(0, 32));
        setPhone(value);
    }

    const _setSendLink = (event) => {
        setSendLink(event?.target?.checked);
    }

    const _setInOffice = (event) => {
        setInOffice(event?.target?.checked);
    }

    const _clearForm = (freeFunc) => {
        return () => {
            setName('');
            setPhone('');
            setEmail('');
            setSendLink(false);
            setInOffice(false);
            if (freeFunc) freeFunc();
        }
    }

    if (!show) return null;

    return (
    <Box flex={1}>
        <Box direction='row'
             flex={1}
             style={newPatientStyle}
             justify="start"
        >
            <Box direction="row-responsive"
                 background='#FBFBFB'
                 flex={2}
                 justify="evenly"
                 pad={'1.5em'}
                 style={{borderRadius: '1em', border: '1px solid #ededed'}}
            >
                <Box direction='column'
                     className="priority-5-block"
                     flex={1}
                >
                    <Box flex={1}>
                        <FormField label={<Text size="12px">Name</Text>}
                                   htmlFor="name"
                                   style={fieldStyle}
                        >
                            <TextInput
                                placeholder='John Doe'
                                value={name || ''}
                                name='name'
                                onChange={_setName}
                            />
                        </FormField>
                    </Box>
                    <Box direction='row' className="priority-5-block" flex={1}>
                        <Box flex={1}>
                            <FormField label={<Text size="12px">Phone Number</Text>}
                                       htmlFor="phone"
                                       style={fieldStyle}>
                                <TextInput placeholder='2565551234'
                                           value={PrettyPhone(phone)}
                                           name='phone'
                                           onChange={_setPhone}
                                />
                            </FormField>
                        </Box>
                        <Box flex={1}>
                            <FormField label={<Text size="12px">Email</Text>}
                                       htmlFor="email"
                                       style={fieldStyle}>
                                <TextInput placeholder='email@example.com'
                                           value={email || ''}
                                           name='email'
                                           onChange={_setEmail}
                                />
                            </FormField>
                        </Box>
                    </Box>
                </Box>


                <Box direction="column"
                     height='100%'
                     flex={1}
                     justify="evenly"
                     align="start"
                     margin={{left: '1em'}}
                >
                    <Box flex={2}
                         height='60px'
                         justify={'between'}
                         margin={{left: '0.25em'}}>
                        <CheckBox
                            named="send_link"
                            checked={send_link}
                            label={(
                                <Box direction='column' justify='center'>
                                    <Text size='12px'>Send app download link.</Text>
                                </Box>)}
                            onChange={_setSendLink}
                        />
                        <CheckBox
                            named="send_link"
                            checked={in_office}
                            label={(
                                <Box direction='column' justify='center'>
                                    <Text size='12px'>Patient is present.</Text>
                                </Box>
                            )}
                            onChange={_setInOffice}
                        />
                    </Box>
                    <Box direction='row'
                         flex={1}
                    >
                        <Button
                            label="Cancel"
                            onClick={_clearForm(onCancel)}
                            style={cancelStyle}
                        />
                        <LoadingButton
                            primary
                            highlight={training}
                            color={theme.global.colors.brand}
                            type='submit'
                            label={<Text color={'white'}>Create</Text>}
                            disabled={ !_userValid() }
                            onClick={(done) => {
                                onCreate({name, email, phone, in_office}, send_link, _clearForm(done));
                            }}
                            style={createStyle}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box align="end" width="58em">
            <Text color="#D1626D">{error}</Text>
        </Box>
    </Box>);
};

const newContainerMargin = {top: '1em'};
const fieldStyle = {marginBottom: '1em', marginRight:'1em', marginTop: 0};
const addPatientMargin = {left: '0em', top: '0em'};

const newPatientStyle = { marginTop: '1.5em', maxWidth: '58em', overflow: 'hidden' };
const createStyle = { marginLeft: '0.5em', marginTop: 'auto', marginBottom: 'auto', borderRadius: '0.5em' };
const cancelStyle = { marginRight: '0.5em', marginTop: 'auto', marginBottom: 'auto', borderRadius: '0.5em', color: '#5a5a5a' };

const leftAuto = {left: 'auto'};

const headers = [{
    name: "",
    width: "2em",
    class: "priority-1"
}, {
    name: "Patient",
    width: "14em",
    class: "priority-1"
}, {
    name: "Elapsed Days",
    width: "5em",
    class: "priority-5",
}, {
    name: "% Complete",
    width: "5em",
    class: "priority-5",
}, {
    name: "Patient Onboarding Progress",
    width: "25em",
    class: "priority-1"
}, {
    name: "Actions",
    width: "4em",
    margin: leftAuto,
    class: "priority-1"
}];

// const testPatients = () => {
//     const mark = (x) => {
//         let num = Date.now();
//         if (x < 0)
//             num -= 60*60*24*1000;
//         let d = new Date(num);
//         return x !== 0 ? d.toISOString() : null;
//     }
//
//                    // C   S   L   N  U  R   J
//     const args = [  [ 1,  0,  0,  0, 0, 0,  0, "Await Creation"],
//                     [-1,  0,  0,  0, 0, 0,  0, "Dinq Creation"],
//                     [ 1,  1,  0,  0, 0, 0,  0, "Await Login"],
//                     [ 1, -1,  0,  0, 0, 0,  0, "Dinq Login"],
//                     [ 1,  1,  1,  0, 0, 0,  0, "Await Link"],
//                     [ 1,  1, -1,  0, 0, 0,  0, "Dinq Link"],
//                     [ 1,  1,  1,  1, 0, 0,  0, "Await Journal"],
//                     [ 1,  1,  1, -1, 0, 0,  0, "Dinq Journal"],
//                     [ 1,  1,  1,  1, 0, 0,  1, "Journal"],
//                     [ 1,  1,  1,  0, 1, 0,  0, "Unlinked No Journal"],
//                     [ 1,  1,  1,  0, 0, 1,  0, "Rejected No Journal"],
//                     [ 1,  1,  1,  0, 1, 0,  1, "Unlinked Journal"],
//                     [ 1,  1,  1,  0, 0, 1,  1, "Rejected No Journal"],
//                     [ 1,  0,  0,  1, 0, 0,  0, "[Special] Old User Linked"],
//                     [-1,  0,  0,  1, 0, 0,  0, "[Dinq] Old User Linked"],
//                     [ 1,  0,  0,  0, 1, 0,  0, "[Special] Old User Unlinked"],
//                     [-1,  0,  0,  0, 1, 0,  0, "[Dinq] Old User Unlinked"],
//                     [ 1,  0,  0,  1, 0, 0,  1, "[Special] Old User Linked Journal"],
//                     [-1,  0,  0,  1, 0, 0,  1, "[Dinq] Old User Linked Journal"],
//                     [ 1,  0,  0,  0, 1, 0,  1, "[Special] Old User Unlinked Journal"],
//                     [-1,  0,  0,  0, 1, 0,  1, "[Dinq] Old User Unlinked Journal"]  ];
//
//     return args.map((arg, index) => {
//         let p = {
//             id: index,
//             name: arg[7],
//             email: "A" + index + "@example.com",
//             phone: "+155555555" + String(index).padStart(2, '0'),
//             created_at: mark(arg[0]),
//             signup:     mark(arg[1]),
//             login:      mark(arg[2]),
//             linked:     mark(arg[3]),
//             unlinked:   mark(arg[4]),
//             rejected:   mark(arg[5]),
//             journal:    mark(arg[6])
//         }
//         console.log(p);
//         return p;
//     });
// }

export class Onboarding extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        const values = props.location ? queryString.parse(props.location.search) : {};

        this.state = {
            loading: false,
            refreshing: false,
            failed: false,
            search: '',
            new_user: !!values.add,
            new_error: false,
            patients: [] //testPatients()
        };
    }

    async loadData() {
        try {
            ApiCall(this.context, 'get', '/trackers').then((result) => {
                this.setState({loading: false, refreshing: false, patients: this._preprocessPatients(result)});
            })
            .catch(result => {
                this.setState({loading: true, refreshing: false, failed: true});
            })
        } catch (ex) {
        }
    }

    async refresh() {
        this.setState({
            refreshing: true
        });

        this.loadData();
    }

    async componentDidMount() {
        this.loadData()
    }

    initFinalizeUser(patient) {
        this.setState({finalize_user: patient})
    }

    initLinkUser(patient) {
        let patients = [...this.state.patients];

        for (let x = 0; x < patients.length; ++x) {
            if(patients[x].id === patient.id)
                patients[x].download_links += 1;
        }

        this.setState({ patients });
    }

    _preprocessPatients(patients) {
        const dates = ['signup', 'login', 'linked', 'unlinked', 'journal', 'rejected'];
        for (let x in patients) {
            for (let y in patients[x]) {
                if (patients[x][dates[y]] !== null)
                    patients[x][dates[y]] = ToDate(patients[x][dates[y]])
            }
        }
        return patients;
    }

    _createPatient(patient, success) {
        try {
            ApiCall(this.context, 'post', '/trackers', patient).then((result) => {
                const patients = [result, ...this.state.patients];
                this.setState({new_user: false, new_error: false, patients});
                success(result);
            })
            .catch(result => {
                const error_string = result?.response?.data?.detail || "Unable to create onboarding entry."
                this.setState({new_error: error_string});
            })
        } catch (ex) {
        }
    }

    _sendAppLink(phone, id) {
        ApiCallNoAuth("post", "/auth/app_link", { phone, tracker_id: id }).then(()=>{}).catch(()=>{});
    }

    _cancelNewUser() {
        this.setState({new_user: false, user: {}, new_error: false})
    }

    deletePatient = async (event) => {
        if (!this.state.finalize_user)
            return;

        this._postArchivePatient(this.state.finalize_user)
    }

    searchPatient = (search) => {
        this.setState({search});
    }

    async _postArchivePatient(patient) {
        ApiCall(this.context, 'del', `/trackers/${patient.id}`)
            .then((result) => {
                this.loadData();
                this.setState({finalize_user: false});
            })
    }

    applySearch(patients) {
        if (!this.state.search)
            return patients;
        const searcher = new FuzzySearch(patients, ['name', 'email']);
        return searcher.search(this.state.search);
    }

    render() {
        const isAdmin = this.props.user.role === roleId('admin');
        const adminAction = isAdmin ? () => this.adminDetach() : undefined;
        const { patients, loading, failed, finalize_user, new_user, new_error, refreshing} = this.state;
        const {user} = this.props;
        const filtered_patients = this.applySearch(patients);
        const training = !(!user || !user.provider || roleId("provider") >= user.role || user.provider.num_trackers > 0);

        return (
            <Box breakpoint='medium'
                 flex={true} fill={true}
                 pad={{right: '1.5em'}}
            >
                <OnboardingGuidePrompts user={user} location={this.props.location}/>
                <PageHeader label="Onboarding">
                    <SearchBar onSearch={this.searchPatient}>
                        <SearchBarButton icon={<Box margin={addPatientMargin}><AddPatientIcon size="16px" color='white'/></Box>}
                                         disabled={new_user}
                                         label={" Add Patient"}
                                         highlight={training && !new_user}
                                         primary
                                         onClick={event => {
                                            this.setState({new_user: true});
                                         }}/>
                        <SearchBarButton icon={<Box margin={{top: '0.2em'}}><RefreshIcon size="16" color='#969696'/></Box>}
                                     disabled={refreshing}
                                     onClick={() => this.refresh()}/>
                    </SearchBar>
                </PageHeader>

                <NewUserPane show={new_user}
                             onCreate={(user, link, success) => {
                                 this._createPatient(user, (user) => {
                                     if (link)
                                         this._sendAppLink(user.phone, user.id);
                                     success();
                                 });
                             }}
                             onCancel={() => this._cancelNewUser()}
                             training={training}
                             error={new_error}
                />

                <FinalizeDialog user={finalize_user} adminAction={adminAction}
                                onClose={() => this.setState({finalize_user: false})}
                                onConfirm={this.deletePatient}
                                mobile={this.props.mobile}/>

                <ListContainer>
                    <List flex={false} overlfow={{horizontal: 'scroll'}}>
                        <ListHeader headers={headers}/>
                        {loading && <LoadingItem failed={failed} loading={loading}
                                                 pad='0.5em'
                                                 label="Loading patient information..."
                                                 width='100%'
                                    />
                        }
                        {filtered_patients.length === 0 && !loading &&
                            <ListItem>
                                No patients to display.
                            </ListItem>
                        }
                        {!loading && filtered_patients.map((patient, index) =>
                            <OnboardingRow
                                key={'key-' + patient.id + patient.email}
                                patient={patient}
                                index={index}
                                onFinalize={patient => this.initFinalizeUser(patient)}
                                onLink={patient => this.initLinkUser(patient)}
                            />
                        )}
                    </List>
                </ListContainer>
            </Box>
        );
    }
}
