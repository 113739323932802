import queryString from "query-string";
import {Auth} from "aws-amplify";
import {Alert as AlertIcon, UserAdd as SignupIcon} from "grommet-icons/es6/icons";
import {Box, CheckBox, Form, FormField, Text, TextInput} from "grommet";
import {formatPhoneNumber} from "react-phone-number-input";
import React from "react";

import {AuthenticationPage, AuthLink, AuthLinkBox, FormLabel, FormTitle, MessageBanner, PasswordReq} from ".";
import {ApiCallNoAuth, Clinic, LoadingButton} from "..";

export class SignupForm extends AuthenticationPage {
    constructor(props) {
        super(props);
        const query = queryString.parse(window.location.search);

        this.state = {
            email: "",
            confirm_email: "",
            valid_email: false,
            phone: "",
            valid_phone: false,
            valid_password: false,
            first_name: "",
            last_name: "",
            password: "",
            confirm_password: "",
            submitting: false,
            error: null,
            provider: (query.patient === 'true'),
            optin_communications: true,
            eula_privacy: false,
            optin_reminder: true,
            invite_code: query.code || '',
            provider_info: {},
            data_agreement: ((query.code || '').length === 8),
            data_policy: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.invite_code !== this.state.invite_code) {
            this.updateProviderInfo(this.state.invite_code)
        }

    }

    componentDidMount() {
        this.updateProviderInfo(this.state.invite_code)
    }

    updateProviderInfo(code) {
        if ( code.length === 8 || code.length === 0 ) {
            this.setState({provider_info: {}});
            return;
        }

        if (code.length !== 6) {
            this.setState({provider_info: {}});
            return;
        }

        // Fire and forget.
        ApiCallNoAuth('get', `/providers/public/${code}`)
            .then(provider_info => this.setState({ provider_info,}))
            .catch(() => this.setState({provider_info: {}}))
    }

    validate() {
        return this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.confirm_password.length > 0 &&
            this.state.valid_password &&
            this.state.first_name.length > 0 &&
            this.state.last_name.length > 0 &&
            this.state.eula_privacy === true &&
            (this.state.phone.length === 0 || this.state.valid_phone);
            // &&
            // (this.state.invite_code.length !== 8 ||
            //  this.state.data_policy);
    }

    handlePassword = event => {
        var state = this.state;
        const password = event.target.value;
        state[event.target.name] = password;

        const len = 8 - password.length;
        const cap = 1 - (password.match(/[A-Z]/g) || []).length;
        const num = 1 - (password.match(/[0-9]/g) || []).length;
        const sym = 1 - (password.match(/[^0-9A-Za-z ]/g) || []).length;

        const check = len <= 0 && cap <= 0 && num <= 0 && sym <= 0;
        state.valid_password = (check && state.password === state.confirm_password);
        this.setState(state);
    }

    handleCode = event => {
        var state = this.state;
        state.invite_code = event.target.value;
        state.provider_info = {};
        if (state.invite_code.length === 6)
            this.updateProviderInfo(event.target.value);

        // TOOD: REMOVE
        state.data_agreement = (state.invite_code.length === 8);

        this.setState(state);
    }

    handleSubmit = async (done) => {
        this.setState({ submitting: true, error: false });

        const {
            password, first_name, last_name, phone,
            optin_reminder, optin_communications, eula_privacy,
            invite_code, data_policy
        } = this.state;

        var {email} = this.state;
        email = email.toLowerCase();

        const signUpData = {
            username: email,
            password,
            attributes: {
                email,
                phone_number: phone
            },
            clientMetadata: {
                first_name, last_name,
                optin_reminder: optin_reminder ? "true" : "false",
                optin_communications: optin_communications ? "true" : "false",
                eula_privacy: eula_privacy ? "true" : "false",
                data_policy: data_policy ? "true" : "false",
                invite_code: (invite_code || '000000')
            }
        };

        try {
            await Auth.signUp(signUpData);
            done();
            this.props.onConfirm({ message: "A confirmation code was sent to the phone that was used during account creation. Please enter that code here to verify your account." });
        } catch (e) {
            let error_str = "An unknown error occured.";
            let pebcak = false;
            let error = e.message.match(/{.*}/);
            if (error) {
                let errobj = JSON.parse(error[0]);
                error_str = errobj.error;
                pebcak = errobj.pebcak;
            } else {
                error_str = e.message;
            }

            if (!pebcak)
                error_str += " Please contact info@painpointanalytics.com.";

            this.setState({ error: error_str, submitting: false });
            done();
        }
    }

    render() {
        const {
            error, confirm_password, confirm_email, valid_phone, valid_password, password,
            phone, first_name, last_name, email, eula_privacy, optin_communications, optin_reminder,
            provider_info, invite_code, data_agreement, data_policy, submitting, provider
        } = this.state;
        const {message} = this.props;
        return (
            <>
                <FormTitle title={`${provider ? 'Provider' : 'Patient'} Account Creation`} icon={SignupIcon}/>
                <MessageBanner message={message}/>
                <Form>
                    <FormField
                        id="first_name"
                        name="first_name"
                        label="First Name"
                        value={first_name}
                        onChange={this.handleChange}
                    />
                    <FormField
                        id="last_name"
                        name="last_name"
                        label="Last Name"
                        value={last_name}
                        onChange={this.handleChange}
                    />
                    <FormField
                        id="email"
                        name="email"
                        label={<FormLabel label="E-mail"/>}
                        value={email}
                        onChange={this.handleEmail}
                    />
                    <FormField
                        id="confirm_email"
                        name="confirm_email"
                        label={<FormLabel label="Confirm E-mail"/>}
                        value={confirm_email}
                        onChange={this.handleConfirmEmail}
                    />
                    <FormField
                        label={(<>Mobile Phone{!valid_phone && phone.length > 0 &&
                        <AlertIcon color="red" style={{marginLeft: '0.5em'}}/>}</>)}>
                        <TextInput
                            id="phone"
                            name="phone"
                            type="numeric"
                            value={formatPhoneNumber(phone) || phone}
                            placeholder="(256) 555-1234"
                            onChange={this.handlePhone}/>
                    </FormField>
                    <Box margin={{bottom: '1em'}}>
                        <PasswordReq password={password}/>
                    </Box>
                    <FormField
                        id="password"
                        name="password"
                        label={<FormLabel label="Password"
                                          alert={!valid_password}/>}
                        type="password"
                        value={password}
                        onChange={this.handlePassword}
                    />
                    <Box margin={{bottom: '1em'}}>
                        <PasswordReq password={password} confirm_password={confirm_password}/>
                    </Box>
                    <FormField
                        id="confirm_password"
                        name="confirm_password"
                        label="Confirm Password"
                        type="password"
                        value={confirm_password}
                        onChange={this.handlePassword}
                    />
                    <FormField label="Invite Code (optional)">
                        <TextInput
                            id="invite_code"
                            name="invite_code"
                            value={invite_code}
                            type="numeric"
                            placeholder='6 to 8 digit Invite Code'
                            onChange={this.genCodeHandler(8)} />
                    </FormField>
                    {provider_info.name &&
                        <Box margin={{bottom: '2em'}}>
                            <Clinic provider={provider_info} hideInvalid={true}
                                    margin={{bottom: 'small'}}
                                    pad='small'
                            />
                        </Box>
                    }
                    <Box flex={false} margin={{bottom: '1em'}}>
                        <CheckBox
                            id="optin_reminder"
                            checked={optin_reminder}
                            label={(<span style={{'textTransform': 'capitalize'}}>Opt-in for reminders.*</span>)}
                            onChange={this.handleCheck}
                        />
                        <br/>
                        <CheckBox
                            id="optin_communications"
                            checked={optin_communications}
                            label={(<span style={{'textTransform': 'capitalize'}}>Opt-in for direct communications.*</span>)}
                            onChange={this.handleCheck}
                        />
                        {!optin_communications &&
                            <Box margin={{top: '0.5em'}} round='1em' border pad='1em'>
                                <Text color='error'>
                                    Direct communications allow your provider to use PainPoint to communicate with you
                                    directly. If you do not opt-in for this communication, you will not be able to
                                    receive this one-on-one, direct interaction with your provider through PainPoint.
                                    You will NEVER receive advertisements or automated messages from this service.
                                </Text>
                            </Box>
                        }
                        <br/>
                        <Text size="small" >*Message and Data Rates May Apply.</Text>
                        <br/>
                        <CheckBox
                            id="eula_privacy"
                            checked={eula_privacy}
                            label={(<span style={{'textTransform': 'capitalize'}}>By checking here, I agree that I have read and agree to the terms and conditions set forth in the <a href="https://docs.google.com/document/d/e/2PACX-1vSfvTyVvldw9fQ20JJjxETk6gv58L3sgiUs3jK_lZf1CobOtHXFDRJgHHOt7MuXkP_6w3qXNTuzkeTV/pub" target="_blank" rel="noopener noreferrer">EULA</a> and <a href="https://docs.google.com/document/d/e/2PACX-1vTUyyyxNxvDBAGGKw4zkA6z3jTdVp1yZU8cbPrrSU3xWUQvTEmZ7pyJbDpbFRXqs_d5acHcoDWRWzVc/pub" target="_blank" rel="noopener noreferrer">Privacy Agreement</a> .</span>)}
                            onChange={this.handleCheck}
                        />
                        {data_agreement &&
                            <> <br/>
                                <CheckBox
                                    id="data_policy"
                                    checked={data_policy}
                                    label={(
                                        <span style={{'textTransform': 'capitalize'}}>By checking here, I agree that I have read and agree to the terms and conditions set forth in the <a
                                            href="https://docs.google.com/document/d/e/2PACX-1vSfvTyVvldw9fQ20JJjxETk6gv58L3sgiUs3jK_lZf1CobOtHXFDRJgHHOt7MuXkP_6w3qXNTuzkeTV/pub"
                                            target="_blank" rel="noopener noreferrer">Data Policy</a>.</span>)}
                                    onChange={this.handleCheck}
                                />
                            </>
                        }
                    </Box>
                    <MessageBanner error={error}/>

                    <LoadingButton
                        margin={{top: '1em'}}
                        primary
                        type="submit"
                        label="Create Account"
                        disabled={!this.validate() || submitting}
                        onClick={this.handleSubmit}
                    />
                </Form>
                <AuthLinkBox>
                    <AuthLink label='Login' onClick={this.props.onLogin}/>
                    <AuthLink label='Confirm Sign Up' onClick={this.props.onConfirm} side="right"/>
                </AuthLinkBox>
            </>
        );
    }
}