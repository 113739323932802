

export function ToDate(datestr) {
  if (!datestr)
    return null

  if ( datestr instanceof Date )
    return datestr

  var str = datestr.replace(/-/g, '/').replace(/T/g, ' ') + " UTC";
  return Date.parse(str)
}

export function DaysSinceToday(datestr) {
  if (!datestr)
    return "Never"

  var nd = new Date();
  var y = nd.getFullYear(nd);
  var m = nd.getMonth(nd);
  var d = nd.getDate(nd);
  var utcToday = new Date()
  var utcUser = ToDate(datestr)
  var diffDays = (utcToday-utcUser)/1000/60/60/24
  var days = Math.round(diffDays)
  if (days === 0 || days === -1)
    return "< 1 day"
  else if (days === 1)
    return "1 day"
  else
    return "" + days + " days"
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October",
                "November", "December"];
const suffix = [null, "st", "nd", "rd"];

export function PrettyFullDate(datestr) {
   if (!datestr)
    return null

  let str = datestr.replace(/-/g, '/').replace(/T/g, ' ') + " GMT";
  let dt = new Date(str);

  const day = dt.getDate();
  const month = dt.getMonth();
  const yr = dt.getFullYear();
  const cyr = (new Date()).getFullYear();

  const suff = suffix[day] || 'th';
  const mon = months[month];

  return `${mon} ${day}${suff} ${yr === cyr ? '' : yr} ∙ ${formatAMPM(dt)}`
}

export function Localize(datestr) {
  var str = datestr.replace(/-/g, '/').replace(/T/g, ' ')
  return new Date(str + " GMT").toLocaleString('en-US');
}

export function PrettyDate(date) {
  return Localize(date);
}