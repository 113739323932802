import {grommet} from "grommet";
import {deepMerge} from "grommet/utils";

const brandBlue = '#03A2B5';
const brandDarkBlue = '#014048';
const brandBlueDark = '#002024';
const brandBlueDarkMid = '#006972';
// const brandBlueDarkHighlight = '#014048';
const brandBlueDarker = '#001012';
const brandBlueLight = '#67c7d2';
// const brandBlueHighlight = '#e5f5f7'
const brandGreen = '#679E2A';
// const brandGreenDark = '#43671b';
const brandGreenLight = '#b3ce94';
const brandGreenHighlight = '#E8FFCE'
const brandError = '#b50315';
const iconGrey = '#AAAAAA';


function brandGreenAlpha(val) {
  return brandGreen + val;
}

function brandBlueAlpha(val) {
  return brandBlue + val;
}

export const theme = deepMerge(grommet, {
  dash: {
    bad: '#9E2A2A',
    mid: '#F5C831',
    good: '#679E2A',
    badLight: '#e2bfbf',
    midLight: '#fcefc1',
    goodLight: '#d1e2bf'
  },
  global: {
    colors: {
      brandAlt: brandBlue,
      brandLightAlt: brandBlueLight,
      brandLightAlphaAlt: brandBlueAlpha(22),
      brand: brandGreen,
      error: brandError,
      brandLight: brandGreenLight,
      brandLightAlpha: brandGreenAlpha(22),
      brandHighlight: brandGreenHighlight,
      brandDark: brandDarkBlue,
      brandDarkAlpha: brandDarkBlue + '22',
      active: brandGreen,
      select: brandGreenHighlight,
      focus: brandGreenHighlight,
      hover: brandGreenHighlight,
      placeholder: brandGreenLight,
      backgroundDark: brandBlueDark,
      backgroundDarker: brandBlueDarker,
      backgroundBody: "white",
      titleBackground: brandBlueDarkMid,
      titleText: 'white',
      iconGrey
    },
    elevation: {
      light: {
        small: "0 0 1px 0 rgba(0, 0, 0, 0.40), 0 1px 2px 0 rgba(0,0,0,0.40)",
        medium: "0 0 2px 0 rgba(0,0,0,0.40), 0 2px 4px 0 rgba(0,0,0,0.40)",
        large: "0 0 1px 0 rgba(0,0,0,0.40), 0 4px 8px 0 rgba(0,0,0,0.40)",
        xlarge: "0 0 1px 0 rgba(0,0,0,0.40), 0 8px 16px 0 rgba(0,0,0,0.40)"
      }
    },
    font: {
      size: "16px",
      height: "20px"
    },
    input: {
      weight: 500
    },
    size: {
      avatar: "28px",
      sidebar: "60px"
    }
  },
  icon: {
    size: {
      medium: "18px"
    }
  },
  paragraph: {
    medium: {
      size: "16px",
      height: "20px"
    },
    large: {
      size: "20px",
      height: "24px"
    }
  },
  text: {
    medium: {
      size: "16px",
      height: "20px"
    },
    large: {
      size: "20px",
      height: "24px"
    }
  },
  formField: {
    margin: {
      bottom: 'medium'
    }
  }
});
