import {Box, Button, Heading, Text} from "grommet";
import {Route} from "react-router-dom";
import React from "react";
import {Alert as AlertIcon, Checkmark as CheckIcon} from "grommet-icons/es6/icons";
import {PainPointLogo} from "../../icons";

export function AuthFrame(props) {
    return (<Box
        flex={false}
        fill={false}
        margin={{top: '2em', left: 'auto', right: 'auto'}}
        pad={{bottom: '5em'}}
        width='medium'>
        {props.children}
    </Box>);
}

export function ErrorTrap(result, cb) {
    // A 502 indicates a lambda timeout. Keep trying.
    if (result.response && result.response.status === 502) {
        cb("Operation timed out. Please try again.");
    } else if (result.response && result.response.status === 400 && result.response.data) {
        const {message, code} = result.response.data;
        var msg = (<Text color='error'>{message}</Text>);
        cb(msg, code);
    } else {
        cb("An unknown error occurred while processing your request.");
    }
}

export function MessageBanner({error, message, ...rest}) {
    if (error)
        return (
            <Box margin={{bottom: '1em'}}>
                <Text color='error'>{error}</Text>
            </Box>
        );
    if (message)
        return (
            <Box margin={{bottom: '1em'}}>
                <Text color='brand'>{message}</Text>
            </Box>
        );
    return [];
}

export function FormTitle({title, icon: Icon, required_fields, ...rest}) {
    return (
        <Box
            gridArea="header"
            flex={false}
            align="start"
            margin={{bottom: "medium"}}
        >
            <Box  basis="small"
                  width="100%"
                  justify="center"
            >
                <PainPointLogo size={75}/>
            </Box>
            <Box>
                <Heading level="2" color='backgroundDark'>
                    <Icon size="0.7em" style={{marginRight: '0.5em'}} color='brand'/>
                    {title}
                </Heading>
            </Box>
            {required_fields &&
            <Heading level="6" style={{marginTop: 0}}>
                Inputs marked with an asterisk (*) are required.
            </Heading>
            }
        </Box>
    );
}

export function FormLabel({label, icon: Icon, alert, ...props}) {
    if (!Icon) Icon = AlertIcon;
    return (<>
            {label}
            {
                alert &&
                <Icon color="red" style={{marginLeft: '0.5em'}}/>
            }
        </>);
}

export function AuthLink({label, url, onClick, side, ...props}) {
    if (!side) side = "left";
    let style = {marginTop: '1em', textAlign: side, marginRight: '1em', marginLeft: '1em' };
    if (side === 'right')
        style.marginLeft = 'auto';

    if (url)
        return (<Box style={style}>
            <Route render={({history}) => (
                <Button onClick={() => history.push(url)} color='brand'>{label}</Button>
            )}/>
        </Box>);
    else
        return (<Box style={style}>
            <Button onClick={onClick} color='brand'>{label}</Button>
        </Box>);
}

export function AuthLinkBox(props) {
    return (<Box direction="row" style={{marginTop: '2em'}}>
        {props.children}
    </Box>);
}

export function PasswordValid(password) {
    const len = 8 - password.length;
    const cap = 1 - (password.match(/[A-Z]/g) || []).length;
    const num = 1 - (password.match(/[0-9]/g) || []).length;
    const sym = 1 - (password.match(/[^0-9A-Za-z ]/g) || []).length;
    return len <= 0 && cap <= 0 && num <= 0 && sym <= 0;
}

export function PasswordReq({password, confirm_password, ...props})
{
    password = password || '';

    if (confirm_password !== undefined)
        if (password !== confirm_password || password.length === 0)
            return (
                <Text color='error' margin={{bottom: '0.5em'}}>
                    Passwords must match.
                </Text>
            );
        else
            return (
                <Text color='brandAlt' margin={{bottom: '0.5em'}}>
                        <CheckIcon color='brandAlt'/>Passwords match.
                </Text>
            );

    const len = 8 - password.length;
    const cap = 1 - (password.match(/[A-Z]/g) || []).length;
    const num = 1 - (password.match(/[0-9]/g) || []).length;
    const sym = 1 - (password.match(/[^0-9A-Za-z ]/g) || []).length;

    const reqs = [];
    if (len > 0)
        reqs.push(`${len} characters`);
    if (cap > 0)
        reqs.push(`${cap} capital letter`);
    if (num > 0)
        reqs.push(`${num} number`);
    if (sym > 0)
        reqs.push(`${sym} symbol`);


    if (reqs.length === 0)
        return (
            <Text color='brandAlt' margin={{bottom: '0.5em'}}>
                    <CheckIcon color='brandAlt'/>Password requirements met.
            </Text>
        );
    else
        return (
            <Text color='error' margin={{bottom: '0.5em'}}>
                    Password requirements:<br/> {reqs.join(', ')}
            </Text>
        );
}