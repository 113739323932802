import React from "react";

import {Box, Text} from "grommet";
import {ListItem} from "./ListItem";

export const List = props => <Box tag="ul" {...props} pad={'0em'}/>;

export const SortCharacter = (sorted) => {
    if (sorted === 'ASC')
        return '▲';
    if (sorted === 'DESC')
        return '▼';
    return ' '
};

export const ListContainer = ({children, ...rest}) => (
<Box height="100%"
     width='60em'
     style={{overflow: 'auto'}}
     margin={{bottom: "1em", top: "1em"}}
     {...rest}>
    {children}
</Box>
);

export const ListHeader = ({ headers }) => {
    const children = (headers || []).map( (item, index) => {
                        return (
                            <Box direction="row"
                                 key={"list-head-" + index}
                                 align={item?.align || "start"}
                                 basis={item.width}
                                 flex={!!item?.flex}
                                 className={item.class}
                                 margin={item?.margin}>
                                <Text color="#848484" size="medium">
                                    {item.name}
                                </Text>
                            </Box>
                        );
                    });

        return (<ListItem justify="between" key="header-key">
            <Box border={{ side: "bottom", color: "#EDEDED" }}
                 direction='row'
                 width='100%'
                 flex={false}
                 pad={{ vertical: '0.5em' }}>
                { children }
            </Box>
        </ListItem>);
}


// Compute the edit distance between the two given strings
export const LevDist = (a, b) => {
    if(a.length === 0) return b.length;
    if(b.length === 0) return a.length;

    var matrix = [];

    // increment along the first column of each row
    var i;
    for(i = 0; i <= b.length; i++){
        matrix[i] = [i];
    }

    // increment each column in the first row
    var j;
    for(j = 0; j <= a.length; j++){
        matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for(i = 1; i <= b.length; i++){
        for(j = 1; j <= a.length; j++){
            if(b.charAt(i-1) === a.charAt(j-1)){
                matrix[i][j] = matrix[i-1][j-1];
            } else {
                matrix[i][j] = Math.min(matrix[i-1][j-1] + 1, // substitution
                    Math.min(matrix[i][j-1] + 1, // insertion
                        matrix[i-1][j] + 1)); // deletion
            }
        }
    }

    return matrix[b.length][a.length];
};