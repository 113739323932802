import React, { useState } from "react";

import {Box, Text, Menu} from "grommet";

import {
    ApiCall, ApiCallNoAuth,
    ArchiveDialog,
    DaysSinceToday,
    JournalLink,
    LoadingItem,
    UserContext,
    roleId,
    PageHeader, SearchBar,
    List, ListItem, ListContainer, ListHeader, PrettyPhone, SearchBarButton
} from "../components";

import {
    Trash as TrashIcon,
    Link as LinkIcon,
    Add as AddIcon,
    //Notes as NotesIcon,
    Edit as EditIcon,
    //Checkmark as CheckIcon,
    More
} from 'grommet-icons';
import {Route} from 'react-router-dom';

import FuzzySearch from "fuzzy-search";
import {AddPatientIcon, RefreshIcon} from "../icons";

// function printOutUrl(id) {
//     return window.location.protocol + '//' + window.location.host + '/patients/' + id + '/printout'
// }

function nameFull(patient) {
    return patient.first_name + ' ' + patient.last_name
}

const rightAuto = {right: 'auto'};
const leftAuto = {left: 'auto'};


const ActionMenu = ({patient, archiveUser, context}) => {
   const [ linkReq, setLinkReq ] = useState(0);
   return (<Route render={({history}) => (
            <Menu
                plain
                items={[
                    // {
                    //     label: <Box alignSelf="center">Verify</Box>,
                    //     icon: (
                    //         <Box pad="xsmall">
                    //             <CheckIcon size="small" color="darkgreen"/>
                    //         </Box>
                    //     ),
                    //     onClick: () => history.push('/patients/' + patient.id)
                    // },
                    {
                        label: <Box alignSelf="center">Send App Link</Box>,
                        icon: (
                            <Box pad="xsmall">
                                <LinkIcon size="small" color="darkgreen"/>
                            </Box>
                        ),
                        onClick:
                            () => {
                                ApiCallNoAuth("post", "/auth/app_link", {email: patient.email})
                                    .then(() => {
                                    }).catch(() => {
                                });
                            }
                    },
                    {
                        label: <Box alignSelf="center" style={{color: (linkReq === 2 ? 'red' : '#444444')}}>Send Magic
                            Link</Box>,
                        disabled: (linkReq !== 0),
                        icon: (
                            <Box pad="xsmall">
                                <LinkIcon size="small" color={linkReq === 2 ? 'red' : 'darkgreen'}/>
                            </Box>
                        ),
                        onClick:
                            () => {
                                setLinkReq(true);
                                ApiCallNoAuth("post", "/auth/magic", {email: patient.email})
                                    .then(result => {
                                        setLinkReq(0);
                                    })
                                    .catch(result => {
                                        setLinkReq(2);
                                    });
                            }
                    },
                    {
                        label: <Box alignSelf="center">Refresh Status</Box>,
                        icon: (
                            <Box pad="xsmall">
                                <RefreshIcon size="small" color="darkgreen"/>
                            </Box>
                        ),
                        onClick:
                            () => {
                                ApiCall(context,"post", `/users/${patient.id}/status`, {regenerate: true})
                                    .then(() => {
                                    }).catch(() => {
                                });
                            }
                    },
                    {
                        label: <Box alignSelf="center">Submit Journal</Box>,
                        icon: (
                            <Box pad="xsmall">
                                <AddIcon size="small" color="darkgreen"/>
                            </Box>
                        ),
                        onClick: () => history.push(JournalLink(patient, false))
                    },
                    // {
                    //     label: <Box alignSelf="center">Flier</Box>,
                    //     icon: (
                    //         <Box pad="xsmall">
                    //             <NotesIcon size="small" color="darkgreen"/>
                    //         </Box>
                    //     ),
                    //     onClick:
                    //         event => {
                    //             event.preventDefault();
                    //             window.open(printOutUrl(patient.id));
                    //         }
                    // },
                    {
                        label: <Box alignSelf="center">Edit</Box>,
                        icon: (
                            <Box pad="xsmall">
                                <EditIcon size="small" color="darkgreen"/>
                            </Box>
                        ),
                        onClick: () => history.push('/patients/' + patient.id)
                    },
                    {
                        label: <Box alignSelf="center" style={{color: 'error'}}>Archive</Box>,
                        icon: (
                            <Box pad="xsmall">
                                <TrashIcon size="small" color="error"/>
                            </Box>
                        ),
                        onClick: () => archiveUser(patient)
                    }
                ]}
            >
                <Box pad={{horizontal: "small"}}>
                    <More size={"24px"}/>
                </Box>
            </Menu>
        )}/>
    );
}

const PatientItem = ({patient, index, archiveUser, context, ...rest}) => {

    return (
        <ListItem justify="between"
                  align="start"
                  flex='grow'
                  padding='1em'
                  {...rest}
        >
            <Box direction='column' width='100%'
                 margin={{vertical: '0.75em'}}>
                <Box direction='row' width='100%' align='center'>
                    <Box direction="row"
                         align="start"
                         basis="10em"
                         margin={rightAuto}
                         flex={false}
                         className='priority-1'
                    >
                        <Text size="medium">
                            {nameFull(patient)}
                        </Text>
                    </Box>

                    <Box direction="row"
                         align="start"
                         basis="9em"
                         flex={false}
                         margin={rightAuto}
                         className="priority-3"
                    >
                        <Text size="medium">
                            {PrettyPhone(patient.phone)}
                        </Text>
                    </Box>

                    <Box direction="row"
                         align="start"
                         basis="13em"
                         flex={false}
                         margin={rightAuto}
                         className="priority-4"
                    >
                        <Text size="medium"
                              style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {patient.email}
                        </Text>
                    </Box>

                    <Box direction="row"
                         align="start"
                         basis="2em"
                         margin={rightAuto}
                         flex={false}
                         className='priority-1'
                    >
                        <Text size="medium">
                            {patient.num_events === 0 ? '' : patient.num_events}
                        </Text>
                    </Box>

                    <Box direction="row"
                         align="start"
                         basis="6em"
                         margin={rightAuto}
                         flex={false}
                         className='priority-2'
                    >
                        <Text size="medium">
                            {DaysSinceToday(patient.last_alert)}
                        </Text>
                    </Box>

                    <Box direction="row"
                         align="end"
                         margin={leftAuto}
                         flex={false}
                         className='priority-1'
                    >
                        <ActionMenu patient={patient}
                                    archiveUser={archiveUser}
                                    context={context}
                        />
                    </Box>
                </Box>
            </Box>
        </ListItem>
    );
}

const headers=[
    {
        name: 'Name',
        width: '10em',
        class: 'priority-1',
        margin: rightAuto
    },{
        name: "Phone",
        width: "9em",
        class: "priority-3",
        margin: rightAuto
    },{
        name: "Email",
        width: "13em",
        class: "priority-4",
        margin: rightAuto
    },{
        name: 'Entries',
        width: '2em',
        class: 'priority-1',
        margin: rightAuto
    },{
        name: 'Last Alert',
        align: 'center',
        width: '6em',
        class: 'priority-2',
        margin: rightAuto
    },{
        name: 'Action',
        align: 'end',
        class: 'priority-1',
        margin: leftAuto
    }
];

const addPatientMargin = {left: '0em', top: '0em'};

export class Patients extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            failed: false,
            search: '',
            patients: [],
            refreshing: false
        };
    }

    withShortUuid(patients) {
        // patch the short_uuid.
        for (let x in patients)
            patients[x].short_uuid = patients[x].uuid.substr(0, 2).toUpperCase();
        return patients;
    }

    async loadData() {
        try {
            ApiCall(this.context, 'get', '/users').then((result) => {
                this.setState({
                    loading: false,
                    patients: this.withShortUuid(result),
                    refreshing: false
                })
            })
            .catch(result => {
                this.setState({
                    loading: true,
                    failed: true,
                    refreshing: false
                })
            })
        } catch(ex) {}
    }

    async refresh() {
        this.setState({
            refreshing: true
        });

        this.loadData();
    }

    async componentDidMount() {
        this.loadData()
    }

    initArchiveUser(patient) {
        this.setState({delete_user: patient})
    }

    deletePatient = async (event) => {
        if (!this.state.delete_user)
            return;

        this.postArchivePatient(this.state.delete_user)
    }

    searchPatient = (search) => {
        this.setState({search} );
    }

    async postArchivePatient(patient) {
        ApiCall(this.context, 'post', `/users/${patient.id}`, { disabled: true })
            .then((result) => {
                this.loadData();
                this.setState({delete_user: false});
            })
    }

    applySearch(patients) {
        if (!this.state.search)
            return patients;
        const searcher = new FuzzySearch(patients, ['short_uuid', 'first_name', 'last_name']);
        return searcher.search(this.state.search);
    }

    adminDetach() {
        if (!this.state.delete_user)
            return;

        ApiCall(this.context, 'post', `/users/${this.state.delete_user.id}/detach`)
            .then((result) => {
                this.loadData();
                this.setState({delete_user: false});
            })
    }

    render() {
        const isAdmin = this.props.user.role === roleId('admin');
        const adminAction = isAdmin ? () => this.adminDetach() : undefined;
        const {patients, loading, failed, delete_user, refreshing} = this.state;
        const filtered_entries = this.applySearch(patients);

        return (
            <Box
                breakpoint='medium'
                flex={true}
                fill={true}
                pad={{right: '1.5em'}}
            >
                <PageHeader label="Patients">
                    <SearchBar onSearch={this.searchPatient}>
                        <Route render={({history}) => (
                            <SearchBarButton icon={<Box margin={addPatientMargin}><AddPatientIcon size="16px" color='white'/></Box>}
                                             label={" Add Patient"}
                                             primary
                                             onClick={event => {
                                                 history.push("/onboarding?add=true");
                                             }}/>
                        )}/>
                        <SearchBarButton icon={<Box><RefreshIcon size="16" color='#969696'/></Box>}
                                         disabled={refreshing}
                                         onClick={() => this.refresh()}/>
                    </SearchBar>
                </PageHeader>

                <ArchiveDialog user={delete_user} adminAction={adminAction}
                               onClose={() => this.setState({delete_user: false})}
                               onConfirm={this.deletePatient}
                               mobile={this.props.mobile}/>

                <ListContainer>
                    <List flex={false} overlfow={{horizontal: 'scroll'}}>
                        <ListHeader headers={headers}/>
                        {loading && <LoadingItem failed={failed} loading={!!loading}
                                                 pad='0.5em'
                                                 label="Loading patient information..."
                                                 width='100%'
                                    />
                        }
                        {filtered_entries.length === 0 && !loading &&
                            <ListItem>
                                No patients to display.
                            </ListItem>
                        }
                        {!loading && filtered_entries.map((patient, index) =>
                            <PatientItem
                                key={patient.uuid}
                                patient={patient}
                                index={index}
                                context={this.context}
                                archiveUser={patient => this.initArchiveUser(patient)}
                            />
                        )}
                    </List>
                </ListContainer>
            </Box>
        );
    }
}
