import React, { Component } from "react";

import { Box, Button, Layer, ResponsiveContext } from "grommet";
import { FormClose } from "grommet-icons";

import {
  MenuButton,
  roleString,
  roleId
} from "./";
import queryString from "query-string";


export class Sidebar extends Component {
  static contextType = ResponsiveContext;

  render() {
    const { items = [], onToggleSidebar, showLegend, iconsOnly, mobile, ...rest } = this.props;
    const SidebarComponent = mobile ? Layer : Box;
    const sidebarProps =
        mobile
        ? { fill: true }
        : {
          fill: "vertical",
          padding: { top: '0.75em' }
        };
    const values = queryString.parse(window.location.search);
    if (values.mobile) return (<></>);
    return (
      <SidebarComponent {...sidebarProps} {...rest}
                        className={iconsOnly ? 'sidebar minimized' : 'sidebar'}>
        {mobile && (
          <Box align="end">
            <Button icon={<FormClose />} onClick={onToggleSidebar} />
          </Box>
        )}
        {items.map(({ active, exact, label, path, icon, role }) => {
          if (!role.includes(roleString(this.props.user.role)) && this.props.user.role !== roleId('admin')) {
            return null;
          }
          return (<MenuButton
              active={active}
              exact={exact}
              path={path}
              label={label}
              key={label}
              icon={icon}
              iconOnly={iconsOnly}
            />)}
        )}
      </SidebarComponent>
    );
  }
}
