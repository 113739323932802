import React from 'react';
import {Box, Text} from "grommet";

export function FeatureBlock({user, feature, role, ...props}) {
    if (!user)
        return [];

    if (feature && !user.features.includes(feature))
        return [];

    if (role !== undefined && user.role < role)
        return [];

    return props.children;
}

export function PainPointVersion(props) {
    return (
        <Box {...props}>
            <Text size='10px' color='brand'>PainPoint UI
                Version: {process.env.REACT_APP_SHA.substr(0, 8)}</Text>
        </Box>);
}
export const features = {
    sms_comms: 'sms-comms',
    data_drop: 'data-drop'
};