// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

//https://stackoverflow.com/a/55612041
// this function is called in the service worker reg promise, providing the ServiceWorkerRegistration instance
const registerPwaOpeningHandler = (reg) => {
    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }

    window.document.addEventListener(visibilityChange, () => {
        if (!document[hidden]) {
            // manually force detection of a potential update when the pwa is opened
            reg.update();
        }
    });

    return reg;
};

export function register(cb) {
  if ('serviceWorker' in navigator) {
    const firstInstall = !navigator.serviceWorker.controller;
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(reg => {
        registerPwaOpeningHandler(reg);
        if (reg.waiting) {
          if (firstInstall) {
            reg.waiting.postMessage({type: 'SKIP_WAITING'});
            return;
          }
          cb(() => {
              reg.waiting.postMessage({type: 'SKIP_WAITING'});
              window.location.reload();
          });
          return;
        }

        // eslint-disable-next-line no-param-reassign
        reg.onupdatefound = () => {
          const newWorker = reg.installing;
          newWorker.onstatechange = () => {
            if (newWorker.state === 'installed') {
              if (!newWorker.active) {
                if (firstInstall) {
                  reg.waiting.postMessage({type: 'SKIP_WAITING'});
                  return;
                }
                cb(() => {
                    newWorker.postMessage({type: 'SKIP_WAITING'});
                    window.location.reload();
                });
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(reg => {
      reg.unregister();
    });
  }
}
