import React from "react";
import { Box, Heading, Text } from "grommet";
import { JournalLinkFromUuid } from "../pages"

function InlineImage({source, ...rest}) {
  return (<img src={source} width='20px' height='20px' alt={rest.alt} {...rest}/>)
}

export const AndroidInstructions = ({ patient, ...rest }) => (
  <Box {...rest}>
    <Box pad='0.5em'>
      <Heading
        level="4"
        pad='xsmall'
        style={{
          marginBlockStart: '0.25em',
          marginBlockEnd: '0.25em'
        }}
      >
        Android
      </Heading>
    </Box>
    <Box
      fill border='top' pad='1em' margin='0'
      overflow={{ horizontal: 'hidden' }}
    >
      <Text size="12px">
        <b style={{ marginTop: '1em', marginBottom: 0 }}>By QR Code:</b>
        <ol style={{ marginTop: 0, marginBottom: '1em' }}>
          <li>Open your phone’s camera and point it at the <b>QR Code</b> <InlineImage source='/qr.png' alt='QR code'/> above.</li>
          <li>A notification will appear at the bottom or top displaying the web address.</li>
          <li>Tap on the notification.</li>
          <li>Your web browser will open and automatically navigate the appropriate page.</li>
        </ol>

        <b style={{ marginTop: 0, marginBottom: 0 }}>By Web Address:</b>
        <ol style={{ marginTop: 0, marginBottom: '1em' }}>
          <li>Open your Web Browser.</li>
          <li>Navigate to <Text size='12px' color='black'><b>{JournalLinkFromUuid(patient.uuid)}</b></Text></li>
        </ol>

        <b style={{ marginTop: 0, marginBottom: 0 }}>Add Journal to Home Screen:</b>
        <ol style={{ marginTop: 0, marginBottom: '1em' }}>
          <li>
              While viewing your journal in the browser, click the <Text color='black' size='12px'><b>Settings</b></Text> icon <InlineImage source='/settings.png' alt='Settings' style={{ border: '1px solid darkgrey' }}/> in Chrome browsers or the <Text color='black' size='12px'><b>Add to Home Screen</b></Text> icon <InlineImage source='/add_to_home_ffox.png' alt='Add to Home' style={{ border: '1px solid darkgrey' }}/> in Firefox.
          </li>
          <li>In Chrome, select the <Text size='12px' color='black'><b>Add to Home Screen</b></Text> menu item (Firefox users, goto Step 4)</li>
          <li>In Chrome, the <Text size='12px' color='black'><b>Add to Home Screen</b></Text> dialog appear. Enter the name you want to see on your homescreen and press <Text size='12px' color='blue'><b>Add</b></Text>.</li>
          <li>To finalize, press <Text size='12px' color='steelblue'><b>Add automatically</b></Text> in Chrome or the blue <Text size='12px' color='blue'><b>+ADD TO HOME SCREEN</b></Text> button in Firefox.</li>
          <li>Navigate to your 'Home Screen' and locate the 'Pain Point' icon <InlineImage alt='PainPoint Icon' source='/painpoint-icon.png'/>.</li>
          <li>Activate the 'Pain Point' app.</li>
        </ol>
      </Text>
    </Box>
  </Box>
);
