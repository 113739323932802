import {MatchEmail} from "../FormHelpers";
import {FingerPrint as ConfirmIcon} from "grommet-icons/es6/icons";
import {Form, FormField, Text, TextInput} from "grommet";
import React from "react";

import {AuthenticationPage, ErrorTrap} from ".";
import {FormLabel, AuthLink, AuthLinkBox, MessageBanner, FormTitle} from ".";
import {ApiCallNoAuth} from "../ApiHelpers";
import {LoadingButton} from "../Loading";

export class SignupCode extends AuthenticationPage {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email,
            valid_email: MatchEmail(this.props.email),
            code: "",
            error: null
        };
    }

    validate() {
        return this.state.code.length >= 6 && this.state.valid_email
    }

    handleResendCode = async (done) => {
        const { code, email } = this.state;

        if ( this.state.error )
            this.setState({ error: false });

        ApiCallNoAuth("post", "/auth/verify", { email, code })
            .then((res) => {
              done();
              if ( !res.success ) {
                  this.setState({error: "Invalid code."});
                  return;
              }
              this.props.onConfirmed({
                message: <Text color="brandAlt">Thank you, your authorization code was accepted. Please login below.</Text>
              });
            }).catch((e) => {
                done();
                ErrorTrap(e, (msg) => {
                    this.setState({ error: msg });
                });
            });
    }

    render() {
        const {error, email, code} = this.state;
        const {message} = this.props;
        return (
            <>
                <FormTitle title='Verify Account' icon={ConfirmIcon}/>
                <MessageBanner error={error} message={message}/>
                <Form>
                    <FormField
                        id="email"
                        name="email"
                        label={<FormLabel label="E-mail"/>}
                        value={email}
                        onChange={this.handleEmail}
                    />
                    <FormField label="Confirmation Code">
                        <TextInput id="code"
                                   name="code"
                                   type="numeric"
                                   value={code}
                                   onChange={this.genCodeHandler(6)}/>
                    </FormField>
                    <LoadingButton
                        primary
                        type="submit"
                        label="Submit Verification"
                        disabled={!this.validate()}
                        onClick={this.handleResendCode}
                    />
                </Form>
                <AuthLinkBox>
                    <AuthLink onClick={this.props.onResend} label="Resend Code"/>
                    <AuthLink onClick={this.props.onSignUp} label="Sign Up" side='right'/>
                </AuthLinkBox>
            </>
        );
    }
}
