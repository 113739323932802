import React from "react";

import {Box, Button, Text, TextInput} from "grommet";
import {SearchIcon} from "../icons";
import {Close} from "grommet-icons";

const toolMargin = {left: 'auto', top: 'auto', bottom: '1em'};
const searchBoxMargin = {top: 'auto', bottom: 'auto', left: 'auto'};
const searchStyle = {backgroundColor: "white", height: '2.2em', overflow:'hidden'};
const searchInputStyle = {backgroundColor: 'white', height: "2em"};
const closeStyle = { marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em',
                     cursor: 'pointer' };
const searchBarBtnStyle = { height: '2.2em', width: '2.2em', overflow: 'hidden',
                            borderWidth: '1px', borderRadius: '0.75em', padding: 0,
                            marginLeft: '0.64em'};
const searchBarBtnLabelStyle = { width: 'auto' };

const searchButtons = {left: '0.6em', top: '0.1em'};
const searchLabelMargins = {left: '0.6em', right: '1.2em'};

const SearchPlaceholder = () => (
    <Box direction='row'>
        <Text margin={{ left: '0.1em', top: '0.125em', right: '0.9em' }}>
            <SearchIcon color='#969696' size="15"/>
        </Text>
        <Text color='#969696' margin={{ top: '0.5em' }} size="10.5px">
            Search patients...
        </Text>
    </Box>
);

export const SearchBar = ({onSearch, children, ...rest}) => {
    const [query, setQuery] = React.useState('');

    return (<Box margin={toolMargin} direction='row' {...rest}>
        {!!onSearch &&
            <Box width="10.75em" margin={searchBoxMargin}>
                <Box style={searchStyle}
                     flex={false} fill={false} direction="row"
                     round="0.75em" border={{color: '#d4d4d4'}}>
                    <TextInput placeholder={<SearchPlaceholder/>} plain
                               style={searchInputStyle}
                               value={query}
                               name='search' onChange={(event) => {
                        const text = event.target.value;
                        setQuery(text);
                        onSearch(text);
                    }}/>
                    {query && <Close style={closeStyle} color="darkred" onClick={() => {
                        setQuery('');
                        onSearch('');
                    }}/>}
                </Box>
            </Box>
        }
        {children}
    </Box>);
}

const SearchBarButtonLabel = ({label, primary, ...rest}) => {
    if (!label) return null;
    const color = primary ? 'white' : '#969696';
    return (<Box margin={searchLabelMargins}><Text color={color} size='12px'>{label}</Text></Box>);
}


export const SearchBarButton = ({icon, label, highlight, ...rest}) => {
    const color = rest.primary ? 'brand' : '#d4d4d4';
    const style = !!label ? {...searchBarBtnStyle, ...searchBarBtnLabelStyle } : searchBarBtnStyle;
    return (
        <Button
            label={<Box margin={searchButtons} direction='row'>{icon}<SearchBarButtonLabel primary={rest.primary}
                                                                                           label={label}/></Box>}
            color={color}
            style={style}
            className={highlight ? 'guide-highlight' : ''}
            {...rest}
        />
    );
}