import {Auth} from "aws-amplify";
import {Login as LoginIcon, Checkmark as CheckIcon} from "grommet-icons/es6/icons";
import {Box, Form, FormField} from "grommet";
import React from "react";

import {MatchEmail, Loading, LoadingButton} from "..";

import {AuthenticationPage} from ".";
import {AuthLink, AuthLinkBox, MessageBanner, FormTitle} from ".";
import queryString from "query-string";
import {ApiCallNoAuth} from "../ApiHelpers";

const autoLeftMargin= { marginLeft: 'auto', marginRight: 0 };
// const marginLeft = {left: '0.5em'};

// function ErrorLink({onClick, msg, link, ...props}) {
//     return (<Box direction='row'>
//         <Box pad='0.25em'>
//             {msg}
//         </Box>
//         <Box flex={false} width={'10em'}>
//             <Button onClick={onClick} color='error' pad='0.2em' margin={marginLeft} label={link}/>
//         </Box>
//     </Box>);
// }

export class Login extends AuthenticationPage {
    constructor(props) {
        super(props);
        const query = queryString.parse(window.location.search);

        this.state = {
            email: query.email || "",
            magic: query.magic || "",
            password: "",
            error: null,
            message: '',
            submitted: false,
            requested: false,
            magic_login: false,
            user: {}
        };
    }

    componentDidMount() {
        if ( this.state.email.length > 0 && this.state.magic.length > 0 ) {
            this.handleMagic();
        }
    }

    validate() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    email_valid() {
        return MatchEmail(this.state.email);
    }

    requestMagic = async (done) => {
        const {email} = this.state;
        this.setState({ requested: true, error: false });
        ApiCallNoAuth( 'post', '/auth/magic', { email } )
            .then( response => {
                this.setState({ message: (
                    <Box pad={{bottom: '0.25em'}} direction={'row-responsive'}>
                        <CheckIcon size={'20px'} color={'limegreen'}/>
                        Magic link has been sent to the mobile phone registered with this account, if it exists and is verified.
                    </Box>), submitted: false });
                done();
            })
            .catch( error => {
                this.setState({ message: (
                    <Box pad={{bottom: '0.25em'}} direction={'row-responsive'}>
                        <CheckIcon size={'20px'} color={'limegreen'}/>
                        Magic link has been sent to the mobile phone registered with this account, if it exists and is verified.
                    </Box>), submitted: false });
                // ErrorTrap(error, (msg, code) => {
                //     if ( code === 'invalid-user' )
                //         msg = "This email is not registered. Verify the email is correct and try again, or register for a PainPoint account.";
                //     else if ( code === 'inactive-user' )
                //         msg = "This email is marked as inactive. Please contact info@painpointanalytics.com for assistance.";
                //     else if ( code === 'not-verified' )
                //         msg = (<ErrorLink msg={msg} link='Verify Phone' onClick={this.props.onConfirm}/>);
                //     else
                //         msg = "Login failed. Please verify your email and try again.";
                //
                //     this.setState({
                //         error: msg,
                //         submitted: false,
                //         requested: false
                //     });
                // });
                done();
            });
    }

    handleMagic = async () => {
        this.setState({ magic_login: true }, () => {
            Auth.signIn(this.state.email)
                .then((result) => {
                    // If there are active links, then proceed with the challenge.
                    if ( result.challengeParam.activeLinks === "true" ) {
                        Auth.sendCustomChallengeAnswer(result, JSON.stringify({
                            linkNonce: this.state.magic
                        }))
                            .then((result) => {
                                this.setState({ magic_login: false });
                                this.props.onLoginHasAuth();
                            })
                            .catch((error) => {
                                this.setState({ error: "Invalid magic link.", submitted: false,
                                               magic_login: false });
                            });
                        return;
                    }

                    this.setState({ error: "Invalid magic link.", submitted: false, magic_login: false });
                })
                .catch((error) => {
                    this.setState({ error: "Invalid magic link.", submitted: false, magic_login: false });
                    console.log(error);
                });
        });
    }

    handleLogin = async (done) => {
        if ( this.state.error )
            this.setState({ error: false });

        Auth.signIn(this.state.email.toLowerCase(), this.state.password).then(authUser => {
            done();
            if (authUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.props.onForceChange({
                  user: authUser,
                  email: this.state.email,
                });
            } else {
                this.props.onLoginHasAuth();
            }
        }).catch(e => {
            done();
            this.setState({error: "Login failed.", submitted: false});
        });
        this.setState({ submitted: true });
    }

    render() {

        const {error, password, magic_login, email, submitted, requested } = this.state;
        const {message} = this.props;
        return (
            <>
                <FormTitle title="Login" icon={LoginIcon}/>
                <MessageBanner error={error} message={message || this.state.message}/>
                <Form>
                    <FormField
                        id="email"
                        name="email"
                        label="E-mail"
                        value={email}
                        onChange={this.handleChange}
                    />
                    {!magic_login &&
                    <FormField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={this.handleChange}
                    />
                    }
                    {magic_login &&
                        <Box margin={{bottom: '2.25em', top: '2.25em'}}>
                            <Loading label={"Stand by while we log you in."}
                                     size={'32px'}
                                     failed={false}
                                     fade={false}/>
                        </Box>
                    }
                    <Box direction="row">
                        <LoadingButton
                            primary
                            type='submit'
                            label='Login'
                            disabled={!this.validate() || submitted}
                            onClick={this.handleLogin}
                        />
                        <LoadingButton
                            type="button"
                            label="Request Magic Link"
                            style={autoLeftMargin}
                            disabled={!this.email_valid() || submitted || requested }
                            onClick={this.requestMagic}
                        />
                    </Box>
                    <AuthLinkBox>
                        <AuthLink label="Sign Up" onClick={this.props.onSignUp}/>
                        <AuthLink label="Forgot Password" onClick={this.props.onResetPassword} side="right"/>
                    </AuthLinkBox>
                </Form>
            </>
        );
    }
}
