import {theme} from "../theme";

export const AlphaColorTable =  [['#30ee3044', '#ffff7044', '#ff808b44'],
        ['#30ee3077', '#ffff7077', '#ff808b77'],
        ['#30ee3099', '#ffff7099', '#ff808b99'],
        ['#30ee30aa', '#ffff70aa', '#ff808baa'],
        ['#30ee30ff', '#ffff70ff', '#ff808bff']]

export const RawColorTable = [['#90ee90', '#ffffe0', '#ffd0db'],
        ['#80ee80', '#ffffd0', '#ffc0cb'],
        ['#70ee70', '#ffffc0', '#ffb0bb'],
        ['#60ee60', '#ffffb0', '#ffa0ab'],
        ['#30ee30', '#ffff70', '#ff808b']]

export function ColorTable(index, alpha) {
  if (index < 0)
    index = 0;
  if (index > 4)
    index = 4;

  if (alpha)
    return AlphaColorTable[index]
  else
    return RawColorTable[index]
}

export function LookupColor(lev, index) {
  if (lev === undefined || lev < 0)
    return '#F7F7F7'
  var level = Math.round(lev/10.0);
  var color = [
    theme.dash.good,
    theme.dash.good,
    theme.dash.good,
    theme.dash.good,
    theme.dash.mid,
    theme.dash.mid,
    theme.dash.mid,
    theme.dash.bad,
    theme.dash.bad,
    theme.dash.bad,
    theme.dash.bad
  ];
  return color[level];
}

export function PainDescription(lev) {
  const level = Math.round(lev/10.0);
  if (level < 4) return 'mild';
  if (level < 7) return 'moderate';
  return 'severe';
}

export function PartsList() {
  return {
    'head': 'HE',
    'neck': 'N',
    'shoulder': 'SH',
    'upper-back': 'UB',
    'mid-back': 'MB',
    'low back': 'LB',
    'chest': 'C',
    'arm': 'A',
    'wrist': 'W',
    'hand': 'HA',
    'hip': 'HP',
    'knee': 'K',
    'leg': 'L',
    'foot': 'F'
  }
}

export function PartsFileList(part) {
  const file = {
    'head': 'head.svg',
    'neck': 'neck.svg',
    'shoulder': 'shoulder.svg',
    'upper-back': 'upperback.svg',
    'mid-back': 'midback.svg',
    'low back': 'lowback.svg',
    'chest': 'chest.svg',
    'arm': 'arm.svg',
    'wrist': 'wrist.svg',
    'hand': 'hand.svg',
    'hip': 'hip.svg',
    'knee': 'knee.svg',
    'leg': 'leg.svg',
    'foot': 'foot.svg'
  }[part];
  return file ? file : 'blank.svg'
}
