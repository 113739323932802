import React from "react";
import { Table, Column, AutoSizer } from 'react-virtualized';

import {Box, Text} from "grommet";

import {
    ApiCall,
    ListContainer,
    ListItem,
    LoadingItem,
    PageHeader,
    SearchBar,
    SearchBarButton,
    EventEntry,
    UserContext,
    FullName,
    PrettyFullDate,
} from "../components";

import FuzzySearch from "fuzzy-search";
import {AddPatientIcon, RefreshIcon} from "../icons";
import {Route} from "react-router-dom";

const addPatientMargin = {left: '0em', top: '0em'};

export const MessageEntry = ({entry, index, patient}) => {
    return (
        <Box key={"event-" + entry.id}
             flex={false}
             height='auto'
             border={{color: '#EDEDED'}}
             round='1em'
             pad='1em'
             margin={{top: '1.1em'}}
        >
            <Box direction='row'>
                <Box margin={{left: '10px', bottom: '0.25em'}} direction='column'>
                    <Box direction='row'>
                        <Text weight='bold' margin={{vertical: 'auto'}}>
                            {FullName(patient)}
                        </Text>
                        <Box margin={{left: '0.5em'}}>
                        </Box>
                    </Box>
                    <Box>
                        <Text color='#848484' size='14px'>{PrettyFullDate(entry.created_at)}</Text>
                    </Box>
                </Box>
                <Box margin={{left: 'auto', right: '0.5em'}}>
                </Box>
            </Box>
            <Box direction='row'>
                <Box margin={{left: '0.5em', bottom: '0.25em'}} direction='column'>
                    <Box style={{'textTransform': 'capitalize', overflow: 'wrap'}}
                         margin={{top: '0.5em'}}>
                        <Text color='#656565' size='14px'>
                            {entry.event}
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>);
}


export const FeedItem = ({entry, index}) => {
    const patient = { first_name: entry.user_first_name,
                      last_name: entry.user_last_name,
                      id: entry.user_id };

    if (entry.event_type === 'journal')
        return (<EventEntry key={"entry-" + index}
                    entry={entry} index={index}
                    event_id={entry.id} patient={patient}
                    comments={true}/>);

    if (entry.event_type === 'message')
        return (<MessageEntry index={index}
                              entry={entry}
                              patient={patient}/>);

    return (<Box>{entry.event_type}</Box>);
}

const FeedContents = ({loading, failed, events, ...props}) => {
  if (loading || failed)
    return <ListContainer>
            <LoadingItem failed={failed} loading={loading}
                            pad='0.5em'
                            label="Loading patient information..."
                            width='100%'
                            />
        </ListContainer>

  return (
    <ListContainer>
        <AutoSizer>
        {({width, height}) => {
            return <Table
               noRowsRenderer={() => <ListItem> No patients found. </ListItem>}
               overscanRowCount={10}
               height={height}
               headerHeight={0}
               rowGetter={({index}) => events[index]}
               rowCount={events.length}
               rowHeight={({ index }) => {
                   if (events[index].event_type === 'message')
                       return 150;
                   return 180;
               }}
               width={width} estimatedRowSize={width}>
                <Column
                    dataKey="name"
                    cellDataGetter={(rowData)=> rowData}
                    headerRenderer={() => null}
                    cellRenderer={({rowIndex, rowData: event}) => {
                        return <FeedItem entry={event} index={rowIndex}/>
                    }}
                    width={width}
                />
            </Table>; }
        }
        </AutoSizer>
    </ListContainer>
  );
}

function journal_or_message(event) {
    return event.event_type === 'message' || event.event_type === 'journal'
}

export class Feed extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            refreshing: false,
            loading: true,
            failed: false,
            events: [],
            search: ''
        };
    }

    shouldAutoReload = false;

    async refresh() {
        this.setState({
            refreshing: true
        });

        this.loadData();
    }

    async componentDidMount() {
        this.props.setLegend(true);
        this.loadData();
    }

    componentWillUnmount() {
        this.props.setLegend(false)
        this.shouldAutoReload = false
    }

    loadData() {
        return ApiCall(this.context, 'get', '/providers/current/events?staff=false&limit=50&offset=0')
            .then(result => {
                this.setState({
                    refreshing: false,
                    loading: false,
                    events: result.filter(journal_or_message)
                });
                setTimeout(() => {
                    this.shouldAutoReload && this.refresh();
                }, 60000);
            })
            .catch(result => {
                console.log(result);
                if (!result.response && result.message === "Network Error") {
                    this.setState({failed: true})
                    return;
                }
                if (result.response && result.response.data.errorMessage) {
                    this.setState({failed: result.response.data.errorMessage})
                    return;
                }
                // A 502 indicates a lambda timeout. Keep trying.
                if (result.response && result.response.code === 502) {
                    this.setState({loading: true})
                    setTimeout(() => this.loadData(), 5000)
                } else this.setState({ failed: true })
            })
    }


    searchPatient = (search) => {
        this.setState({search});
    }

    shouldDisplay = (patient) => {
        return true;
    }

    filterPatients = (patients) => {
        if (this.state.search) {
            const searcher = new FuzzySearch(patients, ['short_uuid', 'first_name', 'last_name']);
            return searcher.search(this.state.search);
        }
        return patients;
    }

    render() {
        const {refreshing, loading, failed, events} = this.state;

        return (
            <Box
                breakpoint='medium'
                flex={true}
                fill={true}
                height='auto'
                background="backgroundBody"
            >

                <PageHeader label="Notification Feed">
                    <SearchBar onSearch={(event) => this.searchPatient(event)}>
                        <Route render={({history}) => (
                            <SearchBarButton icon={<Box margin={addPatientMargin}><AddPatientIcon size="16" color='#969696'/></Box>}
                                             onClick={event => {
                                                history.push("/onboarding?add=true");
                                             }}/>
                        )}/>
                        <SearchBarButton icon={<Box margin={{top: '0.2em'}}><RefreshIcon size="16" color='#969696'/></Box>}
                                     disabled={refreshing}
                                     onClick={() => this.refresh()}/>
                    </SearchBar>
                </PageHeader>

                <FeedContents
                    loading={loading}
                    failed={failed}
                    events={events}
                    border={{color: "light-4", side: "bottom"}}/>
            </Box>
        );
    }
}
