import React from "react";
import {UserContext} from "./Contexts";
import {Redirect, Route} from "react-router-dom";
import {NotFound} from "../pages";
import {API, Auth} from "aws-amplify";

export function RouteFilter ({ any, props: cProps, ...rest }) {
  const context = React.useContext(UserContext);

  const triggerOrder = (role) => {
      let visit = ['no_auth'];
      if (role === undefined || role < 1)
          return visit;

      visit.unshift('patient');
      if (role === 1)
          return visit;

      visit.unshift('provider');
      if (role === 2)
          return visit;

      visit.unshift('admin');
      return visit;
  };

  const render = (prop) => {
      if (typeof(prop) === "string") {
          return <Redirect to={prop}/>
      }
      const Comp = prop;
      return (<Comp {...rest} {...cProps} />);
  }

  return (<Route {...rest} render={props => {
      const order = triggerOrder(context.role);
      for (let x in order) {
        const prop = rest[order[x]];
        if (prop) return render(prop);
      }

      if (any) return render(any);
      return (<NotFound {...props} {...cProps} />);
  }}/>);
}

export const GlobalContext = {
    token: async () => {
        const session = await Auth.currentAuthenticatedUser();
        return session.getSignInUserSession().getIdToken();
    }
};

export async function MakeRequest(context, noauth) {
    if (context.token === undefined && !noauth)
        throw Error("No context.");

    let token = await context.token();
    const jwtToken = token.getJwtToken();
    return {
        headers: {
            Authorization: "bearer " + jwtToken
        }
    };
}

export async function ApiCall(context, method, url, body) {
    let request = {};
    if (body)
        request.body = body;

    return API[method]('PainPointMVP', url, request).catch((error) => {
        if ( error?.response?.status === 403 &&
             error?.response?.data?.detail === "Session invalidated." ) {
            // Auth.signOut();
            // window.location.reload();
        }
        throw error;
    });
}

export async function ApiCallNoAuth(method, url, body) {
    let request = {};
    if (body)
        request.body = body;

    return API[method]('PainPointMVP-NoAuth', url, request);
}

export function roleId(role) {
    const role_id = {'no_auth': 0, 'patient': 1, 'provider': 2, 'staff': 2, 'admin': 3}[role]
    return role_id || 0;
}

export function roleString(role) {
    let id = parseInt(role);
    let name = ['no_auth', 'patient', 'provider', 'admin'][id];
    return name ? name : 'undefined';
}
