import React, {useState} from 'react';

import {
    Box,
    Text,
    Form,
    FormField,
    TextInput,
    Grid,
    Button,
    CheckBox,
    Image
} from "grommet";

import {
    Loading, CleanPhoneInput, MatchPhone, MatchEmail, ApiCall,
    PrettyPhone, PainPointVersion, VerifyBox, LinkBox, SettingsBox,
    roleId, roleString, ConfirmDialog, NoticeDialog, LoadingButton, PageHeader
} from "../components";

import {PasswordReq, PasswordValid} from "../components/auth";
import {Mail, Phone} from 'grommet-icons';
import {shallowEqualObjects} from "shallow-equal";

import {Auth} from 'aws-amplify';
import {theme} from '../theme';

// function sex(input) {
//     if (input === 1)
//         return 'Male'
//     if (input === 2)
//         return 'Female'
//     return ''
// }

function fromSex(input) {
    if (input === 'Male')
        return 1
    if (input === 'Female')
        return 2
    return 0
}

const PasswordResetForm = (props) => {
    const [current_password, setCurrentPassword] = useState('');
    const [new_password, setNewPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [notification, notify] = useState('');

    const clearPassword = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }

    const validated = () => {
        return new_password === confirm_password &&
            PasswordValid(new_password) &&
            current_password.length > 0;
    }

    const onChange = (func) => {
        return (event) => {
            func(event.target.value);
        }
    }

    const onSubmit = async (done) => {
        const session = await props.user.session();
        Auth.changePassword(session, current_password, new_password)
            .then(data => {
                done();
                if (data === 'SUCCESS') {
                    clearPassword();
                    notify(<Text color='brandAlt'>Password updated successfully</Text>);
                }
                setSubmitting(false);
            })
            .catch(err => {
                done();
                notify(<Text color={'error'}>{err.message}</Text>);
                setSubmitting(false);
            });
    }

    return (<SettingsBox title={"Change Password"}
                         color={theme.global.colors.brandLight}
                         text={'#5a5a5a'}>
        <Form margin={0} pad={0}>
            <FormField label={<Text size="18px">Current Password</Text>}>
                <TextInput
                    name="current_password"
                    value={current_password}
                    onChange={onChange(setCurrentPassword)}
                    type="password"
                />
            </FormField>
            <FormField label={<Text size="18px">New Password</Text>}>
                <TextInput
                    name="new_password"
                    value={new_password}
                    onChange={onChange(setNewPassword)}
                    type="password"
                />
            </FormField>
            <Box margin={{bottom: '1em'}}>
                <PasswordReq password={new_password}/>
            </Box>
            <FormField label={<Text size="18px">Confirm New Password</Text>}>
                <TextInput
                    name="confirm_password"
                    value={confirm_password}
                    onChange={onChange(setConfirmPassword)}
                    type="password"
                />
            </FormField>
            <Box margin={{bottom: '1em'}}>
                <PasswordReq password={new_password} confirm_password={confirm_password}/>
            </Box>

            {notify.length > 0 && notification}

            <Grid>
                <LoadingButton
                    primary
                    disabled={!validated()}
                    label={submitting ? (
                        <Box direction='row' fill flex>
                            <Box margin={{left: 'auto'}}/>
                            <Box width='1em' height='1em' margin={{right: '0.5em', top: '2px'}}>
                                <Image src="/loading.gif" fit="contain"/>
                            </Box>
                            Saving...
                            <Box margin={{right: 'auto'}}/>
                        </Box>
                    ) : 'Update Password'}
                    onClick={onSubmit}
                />
            </Grid>
        </Form>
    </SettingsBox>);
};

const UserForm = ({user, create, submitting, validated, hasChanged, onChange, onSubmit, ...rest}) => {
    const [notification, notify] = useState('');
    return (
        <SettingsBox title={"User Information"}
                     color={theme.global.colors.brandLight}
                     text={'#5a5a5a'}>
            <Form border='1px' margin={'1em'}>
                <Grid margin={{horizontal: "small"}} gap="xsmall" columns="15em" flex={false}>
                    <FormField label={<Text size="18px">First Name*</Text>}>
                        <TextInput
                            name="first_name"
                            value={user.first_name}
                            onChange={onChange}
                        />
                    </FormField>
                    <FormField label={<Text size="18px">Last Name*</Text>}>
                        <TextInput
                            name="last_name"
                            value={user.last_name}
                            onChange={onChange}
                        />
                    </FormField>
                </Grid>
                {user.role > 1 &&
                <Grid margin={{horizontal: "small"}} gap="xsmall" columns="15em" flex={false}>
                    <FormField label={<Text size="18px">Display Name</Text>}>
                        <TextInput
                            name="alias"
                            value={user.alias}
                            onChange={onChange}
                        />
                    </FormField>
                </Grid>
                }
                <Grid margin={{horizontal: "small"}} gap="xsmall" columns="15em" flex={false}>
                    <FormField marigin={{right: '1em'}}
                               label={<><Mail size="18px" style={{paddingRight: '0.5em', paddingTop: '0.2em'}}/><Text
                                   size="18px">Email</Text></>}>
                        <TextInput
                            name="email"
                            value={user.email}
                            onChange={onChange}
                        />
                    </FormField>
                    <FormField label={<><Phone size="18px" style={{paddingRight: '0.5em', paddingTop: '0.2em'}}/><Text
                        size="18px">Phone</Text></>}>
                        <TextInput
                            name="phone"
                            value={PrettyPhone(user.phone)}
                            onChange={onChange}
                            placeholder="+1#######"
                        />
                    </FormField>
                </Grid>

                {
                    // <Box width="medium"
                    //      pad='small'
                    //      margin={{horizontal: 'auto', top: 'medium'}}
                    //      border={'bottom'} flex={false}
                    // >
                    //     <RadioButtonGroup
                    //         gap='medium'
                    //         direction="row"
                    //         name="sex"
                    //         options={['Male', 'Female']}
                    //         value={sex(user.sex)}
                    //         onChange={onChange}
                    //     />
                    // </Box>
                }

                <Box width="medium"
                     pad='small'
                     margin={{horizontal: 'auto', top: 'medium'}}
                     border={'bottom'} flex={false}
                >
                    { roleString(user.role) === 'staff' &&
                        <Box margin={{bottom: 'small'}} flex={false}>
                            <CheckBox
                                name='allow_admin'
                                checked={!!user.allow_admin}
                                label='Allow provider limited administrative access'
                                onChange={onChange}
                                margin={{
                                    top: 'large',
                                    bottom: 'small',
                                    left: 'small',
                                    right: 'small'
                                }}
                            />
                        </Box>
                    }
                    <Box margin={{bottom: 'small'}} flex={false}>
                        <CheckBox
                            name='reminder'
                            checked={!!user.reminder}
                            label='Opt-in for Reminders'
                            onChange={onChange}
                            margin={{
                                top: 'large',
                                bottom: 'small',
                                left: 'small',
                                right: 'small'
                            }}
                        />
                    </Box>
                    <Box margin={{bottom: 'small'}} flex={false}>
                        <CheckBox
                            name='com_notifications'
                            checked={!!user.com_notifications}
                            label='Opt-in for Communications'
                            onChange={onChange}
                            margin={{
                                top: 'large',
                                bottom: 'small',
                                left: 'small',
                                right: 'small'
                            }}
                        />
                    </Box>
                </Box>

                <Box pad={{top: 'xxsmall', bottom: '1em'}} width='100%' flex={false}>
                    <Box pad='small'>
                        {notify.length > 0 && notification}
                        {hasChanged() &&
                        <Text color={'brandAlt'}>Changes have not been saved.</Text>
                        }
                        {!hasChanged() &&
                        <Text color={'brandAlt'}>No new changes to save.</Text>
                        }
                    </Box>
                </Box>
                <Grid>
                    <LoadingButton
                            primary
                            disabled={!validated() || submitting}
                            label={'Save'}
                            onClick={(done) => {
                                onSubmit((msg) => {
                                    notify(msg);
                                    done();
                                });
                            }}/>
                </Grid>
            </Form>
        </SettingsBox>
    );
}


const span_margin = { marginHorizontal: '1em', marginBottom: '1em' };
const delete_margin = { top: '1em' };

function DeleteBrief({role}) {
    if ( roleString(role) === 'provider' )
    {
        return (<>
            <span style={span_margin}>
                This action is permanent and <em>cannot be undone</em>.<br/>Upon deleting your account, access to
                your PainPoint provider will be lost. Any personal journals will be deleted. If you wish to proceed
                and delete your account, type "<em>delete me</em>" below and then press "Delete Account".
            </span>
            <Text color={'status-error'} style={span_margin}>
                This does not delete your provider account.
                Please contact <a href="mailto:info@painpointanalytics.com">info@painpointanalytics.com</a> to
                cancel your PainPoint provider account.
            </Text>
        </>);
    }

    return (
        <span style={span_margin}>
            This action is permanent and <em>cannot be undone</em>.<br/>Upon deleting your account, all journal history will
            be permanently destroyed. If you wish to proceed and delete your account, type "<em>delete me</em>" below and then press
            "Delete Account".
        </span>
    );
}

function UserDeleteForm({user, ...props}) {
    const [confirmText, setConfirmText] = useState('');
    const [confirmAlert, setConfirmAlert] = useState(false);
    const [notify, setNotify] = useState("");
    const [deleteError, setError] = useState(false);

    return (
        <SettingsBox title={"Delete Account"}
                     color={'darkred'}
                     text={'white'}>

            <DeleteBrief role={user.role}/>

            { confirmAlert &&
                <ConfirmDialog title={"Are you sure?"}
                               body={"This will permanently remove all journal entries. Do you wish to delete your account?"}
                               confirmLabel={"Yes, permanently delete all my information."}
                               cancelLabel={"No, I want to keep my account."}
                               onConfirm={() => {
                                   setConfirmText('')
                                   ApiCall( user,'del', '/users/current').then(() => {
                                       setConfirmAlert(false);
                                       setNotify("Your account has been deleted. Thank you for using PainPoint.");
                                   }).catch(error => {
                                       console.log(error);
                                       setError(true);
                                       setConfirmAlert(false);
                                       setNotify(<span>
                                           We were unable to delete your account.
                                           Please contact <a href="mailto:info@painpointanalytics.com">info@painpointanalytics.com</a> for more information.
                                       </span>);
                                   });
                               }}
                               onClose={() => {
                                   setConfirmAlert(false)
                                   setConfirmText('')
                               }}/>
            }

            { notify &&
                <NoticeDialog title={"Account Deletion"}
                               body={notify}
                               closeLabel={"Okay"}
                               onClose={() => {
                                   setNotify('');
                                   if (!deleteError) {
                                       user.logout();
                                   } else {
                                       setConfirmAlert(false)
                                       setConfirmText('')
                                   }
                               }}/>
            }

            <FormField>
                <TextInput value={confirmText}
                           placeholder={<Text color='pink'>delete me</Text>}
                           onChange={(event)=> setConfirmText(event.target.value)}/>
            </FormField>

            <Button
                primary
                color={'status-error'}
                margin={delete_margin}
                disabled={confirmText !== 'delete me'}
                label={'Delete Account'}
                onClick={() => { setConfirmAlert(true) }}
                flex={true}
                style={{ borderRadius: '0.75em', height: '2.2em'}}
            />
        </SettingsBox>
    );
}

export class Settings extends React.Component {
    constructor(props) {
        super(props);

        let user = {};
        const fields = ['first_name', 'last_name', 'email', 'phone', 'sex', 'reminder', 'com_notifications', 'allow_admin'];
        for (let k in fields)
            user[fields[k]] = props.user[fields[k]];

        this.state = {
            failed: false,
            submitting: false,
            verified: props.user.verified_phone,
            user,
            orig: Object.assign({}, user)
        };
    }

    verify = () => {
        setTimeout(() => this.setState({ verified: true }), 2000 );
    }

    handleEvent = (event) => {
        const user = this.state.user;

        var value;

        if (event.target.name === "phone") {
            value = CleanPhoneInput(event.target.value)
        } else if (event.target.name === "sex") {
            value = fromSex(event.target.value)
        } else if (event.target.type === "checkbox") {
            value = event.target.checked
        } else {
            value = event.target.value
        }

        user[event.target.name] = value
        this.setState({user: user})
    }

    hasChanged = () => {
        return !shallowEqualObjects(this.state.orig, this.state.user);
    }

    validated = () => {
        if (Object.keys(this.state.user).length < 4)
            return false;

        if (!this.hasChanged())
            return false;

        return this.state.user.first_name.length > 0 &&
            this.state.user.last_name.length > 0 &&
            MatchEmail(this.state.user.email) &&
            (this.state.user.phone.length === 0 || MatchPhone(this.state.user.phone))
    }

    submit = async (notify) => {
        this.submitPatient(this.state.user, notify)
    }

    async submitPatient(user, notify) {
        const phone_updated = (user.phone !== this.state.orig.phone);
        ApiCall(this.props.user, 'post', '/users/current', user)
            .then((result) => {
                if (result.try_again) {
                    setTimeout(() => this.submitPatient(user), 30000)
                    return;
                }
                notify(<Text color='brandAlt'>User information saved successfully.</Text>);
                this.setState({
                        submitting: false,
                        orig: Object.assign({}, this.state.user),
                        verified: (this.state.verified && !phone_updated)
                    },
                    () => setTimeout(() => notify(""), 5000));
                this.props.loadUser();
            })
            .catch(result => {
                // A 502 indicates a lambda timeout. Keep trying.
                if (result.response && result.response.status === 502) {
                    this.setState({submitting: true, failed: false})
                    setTimeout(() => this.submitPatient(user), 20000)
                } else if (result.response && result.response.status === 400) {
                    notify(
                        <Box margin={{left: 'medium', bottom: 'large'}}>
                            <Loading failed={<Text color='error'>{result.response.data.errorMessage}</Text>}/>
                        </Box>
                    );
                    this.setState({submitting: false});

                } else {
                    console.log(result.response);
                    this.setState({submitting: false, failed: true});
                }
            })

        this.setState({submitting: true})
    }
    render() {
        const {create, submitting, verified} = this.state;

        return (
            <Box fill background="backgroundBody" style={{ overflow: 'scroll' }}>
                <Box flex={false} background="backgroundBody">

                    <PageHeader label={`Settings`}/>
                    <Box margin={{bottom: '1em', horizontal: '1em'}}>
                        <Text color='DarkRed'>Fields marked with an asterisk (*) are required.</Text>
                    </Box>
                    <Box direction="row-responsive">
                        <Box direction="column" flex={false}>
                            <UserForm
                                user={this.state.user}
                                create={create}
                                submitting={submitting}
                                onSubmit={this.submit}
                                onChange={this.handleEvent}
                                validated={this.validated}
                                hasChanged={this.hasChanged}
                            />
                            <UserDeleteForm user={this.props.user}/>
                        </Box>
                        <Box direction="column" flex={false}>
                            { !verified &&
                                <VerifyBox use_aws={true}
                                           patient_id={"current"}
                                           email={this.props.user.email}
                                           context={this.props.user}
                                           onVerified={this.verify}
                                />
                            }
                            { this.props.user.role === roleId("patient") &&
                                <LinkBox patient_id={"current"}
                                     context={this.props.user}
                                     current_code={this.props.user.provider.code}
                                     onUpdate={() => {
                                         this.props.loadUser();
                                     }}/>
                            }
                            <PasswordResetForm user={this.props.user}/>
                        </Box>
                    </Box>
                    <PainPointVersion style={{position: 'absolute', top: '5em', right: '1em'}}/>
                </Box>
            </Box>
        );
    }
}
