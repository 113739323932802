import React from "react";
import PropTypes from "prop-types";
import { matchPath, withRouter } from "react-router";
import { Button } from "grommet";

// A simple component that shows the pathname of the current location
class RoutedButton extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired
  };

  onClick = event => {
    const { history, path } = this.props;
    event.preventDefault();
    history.push(path);
  };

  render() {
    const {
      active: displayed,
      exact,
      match,
      location,
      history,
      path,
      strict,
      children,
      ...rest
    } = this.props;
    const query = path.indexOf("?");
    const npath = path.substr(0, query > 0 ? query : path.length);
    const pathMatch = matchPath(location.pathname, { exact, path: npath, strict });
    const active = (displayed && !!pathMatch);

    return (
      <Button primary
              color={active ? '#679E2A' : 'white'}
              align={'center'}
              {...rest}
              onClick={this.onClick}>
        { React.cloneElement(this.props.children, { active } ) }
      </Button>
    );
  }
}

export default withRouter(RoutedButton);
