import React from "react";

import { Box } from "grommet";

import { roleId } from '../components'

export const Contact = (props)=> (
  <Box
    flex={true}
    fill={true}
    pad={'1.5em'}
    height='auto'
    background="backgroundBody"
  >
    {props.user.role >= roleId('provider') &&
      <iframe
          title="Provider Contact Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSe_-JjH5-p3ItvAJ8TTRXX0rGiKnQdTiSGv0UrCeBdVKLQjpg/viewform?embedded=true"
          width="640" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading...
      </iframe>
    }
    {props.user.role === roleId('patient') &&
      <iframe
          title="Patient Contact Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSeabl4fLKe_l-2ZpZQVxiSQDq_7NCNKeBmggaiiPfk23Zhvig/viewform?embedded=true"
          width="640" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
    }
  </Box>
)
