import React, {useState, useEffect} from "react";
import {Box, Button, Grid, Layer, Text} from "grommet";
import {Route} from "react-router-dom";
import ReactGA from 'react-ga4';

import {FullName} from "./PatientHelpers";
import {HistoryLink} from "./LinkHelpers";
import {EventInfo} from "./Events";

import {CloseIcon} from "../icons";
import {} from "../"

import {theme} from "../theme";

export const DialogButtons = ({children, ...props}) => {
    const len = children.length;
    let bchildren = []
    if ( len === undefined ) {
        bchildren = [
            <Box width={'100%'} key="k0">
                {children}
            </Box>
        ]
    } else {
        bchildren = [
            <Box key="k0" margin={{left: 'auto'}}>
                {children[0]}
            </Box>
        ]

        if (len > 2)
            bchildren.push(
                <Box margin={{left: '1em'}} key="k1">
                    {children[1]}
                </Box>, <Box key="k2" margin={{horizontal: '1em'}}>
                    {children[2]}
                </Box>
            )

        if (len === 2)
            bchildren.push(
                <Box margin={{horizontal: '1em'}} key="k1">
                    {children[1]}
                </Box>
            )
    }

    return (
        <Box direction="row"
             fill='horizontal'
             pad={{bottom: '1.5em', top: '1.5em'}}
             border={{side: 'top', size: '1px', color: '#EDEDED'}}
             flex={false}>
            {bchildren}
        </Box>
    );
}

const dialogStyle = {
    borderRadius: '1.5em',
    overflow: 'hidden'
};


export const Dialog = ({onClose, children, mobile, ...props}) => (
    <Layer
        onEsc={onClose}
        onClickOutside={onClose}
        flex={false}
        responsive={false}
        style={ mobile ? {width: '100%', ...dialogStyle}
                       : {minWidth: '500px', ...dialogStyle}}
        {...props}
    >
    {children}
    </Layer>
);

export const Title = ({children, onClose, ...props}) => (
    <Box pad={'1em'}
         flex={false}
         fill='horizontal'
         direction='row'
         margin={{top: '2em', left: '1em'}}
         style={capitalize}
    >
        <Text size='25px'>{children}</Text>
        { !!onClose &&
            <Box margin={{ left:'auto', right: '2em' }}
                 width='2em'
                 height='2em'
                 style={{cursor: 'pointer'}}
                 flex={false}
                 pad='0.5em'
                 onClick={onClose}>
                <CloseIcon/>
            </Box>
        }
    </Box>
);

export function EventDialog({event, onClose, ...props}) {
    useEffect(() => {
        if (event)
            ReactGA.send({ hitType: "pageview", page: "/event-dialog" });
    }, [event]);

    if (!event)
        return [];

    return (
        <Dialog onClose={onClose} mobile={props.mobile}>
            <Title onClose={onClose}>Latest {event.location} Entries</Title>
            <Box style={{overflow: 'auto'}}>
                <EventInfo {...event} multimedia={true} comments={true}/>
            </Box>

            <DialogButtons>
                <Button
                    label={<Text size='14px' color='#707070'>Close</Text>}
                    style={{ padding: '1em', borderRadius: '0.75em' }}
                    color='#EDEDED'
                    primary
                    onClick={onClose}
                />
                <Route render={({history}) => (
                    <Button
                        label={<Text size='14px' color='white'>See Patient History</Text>}
                        style={{ padding: '1em', borderRadius: '0.75em' }}
                        primary
                        onClick={(ev) => {
                            onClose();
                            history.push(HistoryLink(event.patient, false));
                        }}
                        margin={{right: '1em'}}
                    />
                )}/>
            </DialogButtons>
        </Dialog>
    )
}

export function VerificationDialog({content, onClose, onConfirm, ...props}) {
    useEffect(() => {
        if (Object.keys(content).length !== 0)
            ReactGA.send({ hitType: "pageview", page: "/alerts-dialog" });
    }, [content])

    if (Object.keys(content).length === 0)
        return [];

    return (
        <Dialog onClose={onClose} mobile={props.mobile}>
            <Title onClose={onClose}>Clear Alerts</Title>
            <Box flex={false}
                 pad={{left: '2em'}}
                 style={{overflow: 'wrap'}}
                 width='100%'
            >
                <Text color='#6a6a6a'>
                    Are you sure you want to clear alerts for {FullName(content.patient)}?
                </Text>
            </Box>

            <Box style={{overflow: 'auto'}}>
                <EventInfo {...content} multimedia={false} comments={true}/>
            </Box>

            <DialogButtons>
                <Button
                    label={<Text size='14px' color='#707070'>Cancel</Text>}
                    primary
                    color='#EDEDED'
                    style={{ padding: '1em', borderRadius: '0.75em' }}
                    onClick={onClose}
                />
                <Button
                    label={<Text size='14px' color='white'>Yes, Clear All Alerts</Text>}
                    primary
                    onClick={onConfirm}
                    style={{ padding: '1em', borderRadius: '0.75em' }}
                    margin={{right: '1.5em'}}
                />
            </DialogButtons>
        </Dialog>);
}

export function ArchiveDialog({user, onClose, onConfirm, adminAction, ...props}) {
    useEffect(() => {
        if (user)
            ReactGA.send({ hitType: "pageview", page: "/archive-dialog" });
    }, [user])

    const [btnClicked, setBtnClicked] = useState(-1);

    if (!user)
        return [];

    return (<Dialog onClose={onClose} mobile={props.mobile}>
        <Title>Are you sure you want to archive this user?</Title>
        <Text weight='bold' margin={{horizontal: 'auto', vertical: '1em'}} width='120em' flex={false} fill={false}>
            {FullName(user)}
        </Text>

        <DialogButtons>
            <Button
                label={`Yes, Archive ${FullName(user)}`}
                primary
                color='status-critical'
                onClick={(e) => { onConfirm(e); setBtnClicked(0); }}
                margin={{right: '1em'}}
                disabled={btnClicked===0}
            />
            {adminAction &&
                <Button
                    label={`[ADMIN] Detach ${FullName(user)}`}
                    color='status-critical'
                    onClick={(e) => { adminAction(e); setBtnClicked(1); }}
                    margin={{right: '1em'}}
                    disabled={btnClicked===1}
                />
            }
            <Button
                label='Close'
                onClick={onClose}
            />
        </DialogButtons>
    </Dialog>);
}

export function FinalizeDialog({user, onClose, onConfirm, adminAction, ...props}) {
    const [btnClicked, setBtnClicked] = useState(-1);

    useEffect(() => {
        setBtnClicked(-1);
        if (user)
            ReactGA.send({ hitType: "pageview", page: "/finalize-dialog" });
    }, [user])

    if (!user)
        return [];

    return (<Dialog onClose={onClose} mobile={props.mobile}>
        <Title onClose={onClose}>Remove User</Title>
        <Box flex={false}
             pad={{left: '2em'}}
             margin={{bottom: '2em'}}
             style={{overflow: 'wrap'}}
             width='100%'
        >
            <Text color='#6a6a6a'>
                Remove {user.name} from your onboarding list?<br/><br/>
                This will not remove {user.name} from your dashboard.<br/>
                It will only remove {user.name} from this onboarding list.
            </Text>
        </Box>
        <DialogButtons>
            <Button
                style={{ padding: '1em', borderRadius: '0.75em' }}
                color='#EDEDED'
                primary
                label='Cancel'
                onClick={onClose}
            />
            <Button
                label={<Text size='14px' color='white'>Yes, Remove {user.name}</Text>}
                style={{ padding: '1em', borderRadius: '0.75em' }}
                color={theme.dash.bad}
                primary
                onClick={(done) => {
                    onConfirm(done);
                    setBtnClicked(0);
                }}
                margin={{right: '1em'}}
                disabled={btnClicked===0}
            />
        </DialogButtons>
    </Dialog>);
}


const capitalize = {textTransform: 'capitalize'};
const centerMargins = {margin: 0};
const tlSmallbMedium = {top: 'small', left: 'small', bottom: 'medium'};
const btmSmall = {bottom: 'xxsmall'};
const spanRight = {left: 'auto', right: '0.5em', vertical: '0.5em'};
const radioStyle = {height: '25px', width: '25px', color: theme.global.colors.brandLight};
const right1 = {right: '1em'};

export function LocalRadio({name, value, onChange, style, ...props}) {
    return (<label>
                <Box align="center">
                    <Box width="1em" align="center" margin={btmSmall}>{value}</Box>
                    <div style={radioStyle} className="container">
                        <input type="radio"
                               name="netpromo"
                               value={value}
                               style={{...style, ...radioStyle}}
                               onChange={onChange}
                        />
                        <span className="checkmark"></span>
                    </div>
                </Box>
            </label>);
}

export function NetPromoterDialog({onClose, onConfirm, show, clinicName, ...props}) {
    const [value, setValue] = useState(-1);
    if (!show) return [];

    const updateOption = event => {
        setValue(event.target.value);
    };

    let radio_buttons = [];
    for (let x = 0; x < 11; ++x)
        radio_buttons.push(
            <Box key={`netpromo-${x}`} align='center'>
                <LocalRadio style={{...capitalize, ...centerMargins}}
                            name={"netpromo"}
                            value={`${x}`}
                            onChange={updateOption}/>
            </Box>
        );


    return (<Dialog onClose={() =>  onConfirm({netpromoter: -1})} mobile={props.mobile}>
        <Title>How likely is it that you would recommend <wbr/>{clinicName} to a friend or colleague?</Title>

        <Box direction='row'>
            <Box margin='0.5em'>Very Unlikely</Box>
            <Box margin={spanRight}>Very Likely</Box>
        </Box>

        <Grid margin={tlSmallbMedium}
              columns={{
                count: 11,
                size: 'auto',
              }}
        >
            {radio_buttons}
        </Grid>

        <DialogButtons>
            <Button
                label='Close'
                margin={right1}
                onClick={() => onConfirm({netpromoter: -1})}
            />
            <Button
                label='Submit'
                primary
                color='status-critical'
                onClick={() => {
                    onConfirm({netpromoter: value});
                    setValue(-1);
                }}
                disabled={value === -1}
            />
        </DialogButtons>
    </Dialog>);
}


export function ConfirmDialog({title, body, onClose, onConfirm, confirmLabel, cancelLabel, ...props}) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/confirm-dialog" });
    }, []);

    return (
        <Dialog onClose={onClose} mobile={props.mobile}>
            <Title>{title}</Title>
            <Box pad={{left: '1em'}}
                 fill='horizontal'
            >
                <Box margin={{top: '1em', bottom: '2em', horizontal: 'small'}} direction='row'>
                    {body}
                </Box>
            </Box>
            <DialogButtons>
                <Button
                    label={cancelLabel || "No"}
                    margin={{left: '1em'}}
                    onClick={onClose}
                />
                <Button
                    label={confirmLabel || "Yes"}
                    color="status-critical"
                    primary
                    onClick={onConfirm}
                />
            </DialogButtons>
        </Dialog>);
}


export function NoticeDialog({title, body, onClose, closeLabel, ...props}) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/notice-dialog" });
    }, []);

    return (
        <Dialog onClose={onClose} mobile={props.mobile}>
            <Title>{title}</Title>
             <Box pad={{left: '1em'}}
                 fill='horizontal'
            >
                <Box margin={{top: '1em', bottom: '2em', horizontal: 'small'}} direction='row'>
                    {body}
                </Box>
            </Box>
            <DialogButtons>
                <Button
                    label={closeLabel || "Okay"}
                    margin={{horizontal: '2em', top: '0.5em'}}
                    onClick={onClose}
                />
            </DialogButtons>
        </Dialog>);
}