import React, {Component} from "react";

import {
    AuthFrame,
    ForceChange,
    Login,
    ResetConfirm,
    ResetPassword,
    SignupCode,
    SignupForm,
    SignupResend
} from "../components/auth";

export class Authentication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            auth_state: props.auth_state || 'login',
            error: null,
            message: null
        };

        this.location = props.history.location.pathname;
    }

    statePath = (state) => {
        const result = {
            signup: '/signup',
            resend: '/signup/resend',
            confirm: '/signup/confirm',
            login: '/login',
            reset: '/login/reset',
            confirm_reset: '/login/confirm',
            force_change: '/login/change',
            authorized: '/'
        }[state];

        if (!result)
            return '/';
        return result;
    }

    pathState = (path) => {
        const result = {
            '/signup': 'signup',
            '/signup/resend': 'resend',
            '/signup/confirm': 'confirm',
            '/login/reset': 'reset',
            '/login/confirm': 'confirm_reset',
            '/login/change': 'force_change',
        }[path];

        if (!result)
            return 'login';
        return result;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.history.action === 'PUSH') {
            if (prevProps.history.location.pathname !== this.location) {
                this.location = this.props.history.location.pathname;
                this.setState({auth_state: this.pathState(prevProps.history.location.pathname)});
            }
        }
    }

    updateState = (state, arg, arg2) => {
        var temp_state = {auth_state: state};

        if (typeof (arg) === 'object')
            temp_state = Object.assign(temp_state, arg);

        if (typeof (arg2) === 'object')
            temp_state = Object.assign(temp_state, arg2);

        this.setState(temp_state, () => this.props.history.push(this.statePath(state)));

        if (typeof (arg) === "function")
            arg();

        if (typeof (arg2) === "function")
            arg2();
    }

    updateStateTo = (state, arg) => (arg2) => this.updateState(state, arg, arg2);

    hasAuth = () => {
        this.props.userHasAuthenticated('/');
    };

    render() {
        const {auth_state, email} = this.state;

        /**
         * states:
         *   "login"
         *   "force_change"
         *   ---
         *   "signup"
         *   "confirm"
         *   "resend"
         *   ---
         *   "reset"
         *   "confirm_reset"
         *  =====
         *      ,--"login" <--------.
         *     /   |/   /|          ,`.
         *    /\ "force_change"    /   \
         *   |  \                 /     \
         *   |   `->"reset"      /       \
         *   |       |          /        |
         *    \   "confirm_reset"        |
         *     \                         |
         *      `----->"signup"          |
         *             |/    /\          |
         *       "confirm" <- "resend"  /
         *             `.______________/
         */

        const user = this.state.user,
            onEmailChange = (email) => this.setState({email}),
            onSignUp = this.updateStateTo("signup"),
            onResend = this.updateStateTo("resend"),
            onConfirm = this.updateStateTo("confirm"),
            onConfirmed = this.updateStateTo("login"),
            onLogin = this.updateStateTo("login"),
            onConfirmReset = this.updateStateTo("confirm_reset"),
            onConfirmHasAuth = this.updateStateTo("confirm", this.hasAuth),
            onForceChange = this.updateStateTo("force_change"),
            onResetPassword = this.updateStateTo("reset"),
            onLoginHasAuth = this.updateStateTo('authorized', this.hasAuth);

        const default_props = {email, user, onEmailChange, message: this.state.message, error: this.state.error };

        const states = {
            confirm: {
                Render: SignupCode,
                transitions: {onSignUp, onConfirmed, onResend}
            },
            resend: {
                Render: SignupResend,
                transitions: {onSignUp, onConfirm}
            },
            signup: {
                Render: SignupForm,
                transitions: {onConfirm, onLogin}
            },
            reset: {
                Render: ResetPassword,
                transitions: {onLogin, onSignUp, onConfirmReset}
            },
            confirm_reset: {
                Render: ResetConfirm,
                transitions: {onResetPassword, onLogin}
            },
            force_change: {
                Render: ForceChange,
                transitions: {onConfirmHasAuth, onLogin}
            },
            login: {
                Render: Login,
                transitions: {onSignUp, onForceChange, onConfirm, onResetPassword, onLoginHasAuth}
            }
        };

        const state_data = states[auth_state];
        if (!state_data) {
            console.log("Invalid login state.");
            return (<></>);
        }

        const Render = state_data.Render;
        const properties = state_data.transitions;

        return (
            <AuthFrame>
                <Render {...default_props} {...properties}/>
            </AuthFrame>
        );
    }
}
