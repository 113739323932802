import React from "react";
import { Table, Column, AutoSizer } from 'react-virtualized';

import {Box} from "grommet";

import {
    ApiCall,
    EventDialog,
    ListContainer, ListHeader, ListItem, LoadingItem, PageHeader, PatientItem,
    fillWithDefaults, PatientMobileItem, SearchBar, SearchBarButton,
    ToDate,
    UserContext,
    VerificationDialog,
    DashboardGuidePrompts
} from "../components";

//import {Close} from 'grommet-icons';
import FuzzySearch from "fuzzy-search";
import {AddPatientIcon, /*PrinterIcon,*/ RefreshIcon} from "../icons";
import {Route} from "react-router-dom";

const rightAuto = {right: 'auto'};

const headers=[
    {
        name: 'Alert',
        class: 'priority-1'
    },{
        name: "Name",
        class: "priority-3"
    },{
        name: "Last Entry",
        class: "priority-4"
    },{
        name: 'Entries',
        class: 'priority-1',
        margin: rightAuto
    },{
        name: 'Well-being',
        align: 'end',
        class: 'priority-1',
        margin: rightAuto
    },{
        name: 'Pain',
        align: 'center',
        class: 'priority-2',
        margin: rightAuto
    }
];

const addPatientMargin = {left: '0em', top: '0em'};

const DashboardContents = ({headers, ...props}) => {
  const {mobile, loading, failed, patients, onClear, onInspect, printing, widths} = props;
  const DashItem = mobile ? PatientMobileItem : PatientItem;

  if (loading || failed)
    return <ListContainer>
            <LoadingItem failed={failed} loading={loading}
                            pad='0.5em'
                            label="Loading patient information..."
                            width='100%'
                            />
        </ListContainer>

  return (
    <ListContainer>
        <AutoSizer>
        {({width, height}) => {
            return <Table
               noRowsRenderer={() => <ListItem> No patients found. </ListItem>}
               overscanRowCount={10}
               height={height}
               headerHeight={mobile ? 0 : 49}
               rowGetter={({index}) => patients[index]}
               rowCount={patients.length}
               rowHeight={mobile ? 230 : 90}
               width={width} estimatedRowSize={width}>
                <Column
                    dataKey="name"
                    cellDataGetter={(rowData)=> rowData}
                    headerRenderer={() => <ListHeader headers={headers}/>}
                    cellRenderer={({rowIndex, rowData: patient}) => {
                        return <DashItem mobile={mobile}
                                         patient={fillWithDefaults(patient)}
                                         onClear={onClear}
                                         onInspect={onInspect}
                                         printing={printing}
                                         index={rowIndex}
                                         widths={widths}/>
                    }}
                    width={width}
                />
            </Table>; }
        }
        </AutoSizer>
    </ListContainer>
  );
}

export class Dashboard extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            refreshing: false,
            loading: true,
            failed: false,
            verify: {},
            patients: [],
            sorted: 'DESC',
            search: ''
        };
    }

    shouldAutoReload = false;

    async refresh() {
        this.setState({
            refreshing: true
        });

        this.loadData();
    }

    async componentDidMount() {
        this.props.setLegend(true);
        this.loadData();
    }

    componentWillUnmount() {
        this.props.setLegend(false)
        this.shouldAutoReload = false
    }

    loadData() {
        return ApiCall(this.context, 'get', '/users/status')
            .then(result => {
                this.setState({
                    refreshing: false,
                    loading: false,
                    patients: this.sortByEntry(this.state.sorted, result)
                });
                setTimeout(() => {
                    this.shouldAutoReload && this.refresh();
                }, 60000);
            })
            .catch(result => {
                console.log(result);
                if (!result.response && result.message === "Network Error") {
                    this.setState({failed: true})
                    return;
                }
                if (result.response && result.response.data.errorMessage) {
                    this.setState({failed: result.response.data.errorMessage})
                    return;
                }
                // A 502 indicates a lambda timeout. Keep trying.
                if (result.response && result.response.code === 502) {
                    this.setState({loading: true})
                    setTimeout(() => this.loadData(), 5000)
                } else this.setState({ failed: true })
            })
    }

    sortEntries = () => {
        let next = {"ASC": "DESC", "DESC": "ASC"}[this.state.sorted];
        this.setState({sorted: next, patients: this.sortByEntry(next, this.state.patients)});
    }

    resetAlertLevel = async (id) => {
        const verify = {};

        ApiCall(this.context,'post', `/users/${id}/clear/alerts`)
            .then(result => {
                const patients = this.state.patients.map(patient => {
                    if (patient.id === id) {
                        patient.alert_level = 0;
                        return patient;
                    }
                    return patient;
                });

                this.setState({patients, verify});
            })
            .catch(result => {
                this.setState({failed: true, verify})
            })
    }

    verifyReset = async (patient, alerts) => {
        this.setState({
            verify: {
                patient: patient,
                limit: alerts,
                alerts: true
            }
        })
    }

    inspectEvent = (patient, eid, location) => {
        this.setState({
            event_selected: {
                event_id: eid,
                patient: patient,
                limit: 5,
                location: location
            }
        });
    }

    searchPatient = (search) => {
        this.setState({search});
    }

    shouldDisplay = (patient) => {
        return true;
    }

    filterPatients = (patients) => {
        if (this.state.search) {
            const searcher = new FuzzySearch(patients, ['short_uuid', 'first_name', 'last_name']);
            return searcher.search(this.state.search);
        }
        return patients;
    }

    sortByEntry = (sort, patients) => {
        if (sort === '')
            return patients;

        const pts = [...patients]
        const descend = (sort === "DESC");
        const descend_val = descend ? -1 : 1;
        console.log(`Sort: ${sort}`);

        return pts.sort((a, b) => {
            if (a.message_count > b.message_count) {
                return descend_val;
            }

            if (a.message_count < b.message_count) {
                return -1 * descend_val;
            }

            if (a.alert_level > b.alert_level) {
                return descend_val;
            }

            if (a.alert_level < b.alert_level) {
                return -1 * descend_val;
            }

            if (a.peak > b.peak) {
                return descend_val;
            }

            if (a.peak < b.peak) {
                return -1 * descend_val;
            }

            if (a.last_event === null) {
                return 1;
            }

            if (b.last_event === null) {
                return -1;
            }

            const l = ToDate(a.last_event), r = ToDate(b.last_event)

            // shuffle nevers to the bottom
            if (a.last_event === l) return 1
            if (b.last_event === r) return -1

            return descend_val * (l - r)
        })
    }

    render() {
        const {refreshing, verify,
               event_selected,
               loading, failed} = this.state;
        const {mobile, printing, /*setPrinting,*/ user} = this.props;
        const widths = (mobile ? ["auto", "auto", "auto", "auto", "auto", "auto"] :
            (printing ? ["2em", "8em", "4em", "10em", "10em", "3em"] :
                ["3.4em", "10em", "6em", "15.5em", "15.5em", "3em"]));

        const local_headers = headers.map((header, i) => {
            header.width = widths[i];
            return header;
        });

        const sorted_patients  = this.filterPatients(this.state.patients);

        return (
            <Box
                breakpoint='medium'
                flex={true}
                fill={true}
                height='auto'
                background={printing ? "white" : "backgroundBody"}
            >
                <DashboardGuidePrompts user={user} location={this.props.location}/>

                <PageHeader label="Pain Summaries">
                    {!printing &&
                        <SearchBar onSearch={(event) => this.searchPatient(event)}>
                        {/*!mobile && <>
                            <SearchBarButton icon={<PrinterIcon size="16" color='#969696'/>}
                                             onClick={event => setPrinting(!printing)}/>
                         </>*/}
                        <Route render={({history}) => (
                            <SearchBarButton icon={<Box margin={addPatientMargin}><AddPatientIcon size="16px" color='white'/></Box>}
                                             label={" Add Patient"}
                                             primary
                                             onClick={event => {
                                                 history.push("/onboarding?add=true");
                                             }}/>
                        )}/>
                        <SearchBarButton icon={<Box margin={{top: '0.2em'}}><RefreshIcon size="16" color='#969696'/></Box>}
                                     disabled={refreshing}
                                     onClick={() => this.refresh()}/>
                    </SearchBar>
                    }
                    {/* printing &&
                        <SearchBar onSearch={false}>
                            <SearchBarButton
                                icon={<Close size="1em" color='brand'/>}
                                id='no-print'
                                onClick={event => setPrinting(false)}
                            />
                        </SearchBar>
                    */}
                </PageHeader>

                <EventDialog event={event_selected}
                             onClose={() => this.setState({event_selected: false})}
                             mobile={mobile}/>
                <VerificationDialog content={verify}
                                    onClose={() => this.setState({verify: {}})}
                                    onConfirm={() => this.resetAlertLevel(verify.patient.id)}
                                    mobile={mobile}/>

                <DashboardContents mobile={mobile}
                                   headers={local_headers}
                                   loading={loading}
                                   failed={failed}
                                   patients={sorted_patients}
                                   onClear={this.verifyReset}
                                   onInspect={this.inspectEvent}
                                   border={{color: "light-4", side: "bottom"}}
                                   printing={printing}
                                   widths={widths}/>
            </Box>
        );
    }
}
