import React, {Component} from "react";

import {
    Box,
    Heading,
    Select,
    Text,
    Button,
    Tabs,
    Tab,
    TextInput,
    Anchor,
    DateInput
} from "grommet";

import {
    ApiCall,
    LoadingButton,
    ListContainer,
    ListItem,
    FullName,
    DaysSinceToday, ListHeader
} from "../components";

import {AutoSizer, Column, Table} from "react-virtualized";
import {theme} from "../theme";

const roleMap = [
    { name: 'Patient', role: 1 },
    { name: 'Provider', role: 2 },
    { name: 'Admin', role: 3 },
];


const ProvidersList = ({providers, onSwitch, provider_id, ...props}) => {
    const vals = Object.values(providers);
    if (vals.length === 0)
        return null;

    return (<ListContainer>
        <AutoSizer>
            {({width, height}) => {
                return <Table
                    noRowsRenderer={() => <ListItem> No Providers found. </ListItem>}
                    overscanRowCount={10}
                    height={height}
                    headerHeight={0}
                    rowGetter={({index}) => vals[index]}
                    rowCount={vals.length}
                    rowHeight={60}
                    width={width} estimatedRowSize={width}>
                    <Column
                        dataKey="name"
                        cellDataGetter={(rowData)=> rowData}
                        headerRenderer={() => {}}
                        cellRenderer={({rowIndex, rowData: provider}) => {
                            return <ProviderItem provider={provider}
                                                 index={rowIndex}
                                                 onSwitch={onSwitch}
                                                 current={provider.id === provider_id}/>
                        }}
                        width={width}
                    />
                </Table>; }
            }
        </AutoSizer>
    </ListContainer>);
};

const FieldTag = ({label, value, state, ...props}) => (
            <Box margin={{right: '1em'}}
                 flex={false}
                 direction={'row'}
                 round='0.5em'
                 pad='0.5em'
                 border={{color: '#969696'}}
                 background={ state ? theme.dash.good : '#E0E0E0' }
                 align='center'
                 height='1.5em'>
                <Text color={ state ? 'white' : 'black' }>
                    <Text margin={{right: '0.5em'}}>{label}</Text> {value}
                </Text>
            </Box>);

const ProviderItem = ({provider, onSwitch, current, ...props}) => {
    return(<Box direction='row'>
        <Box pad='0.25em'>
            <Button label={<Text color='white'>Switch</Text>}
                    onClick={() => { onSwitch(provider) }}
                    primary
                    disabled={current}
            />
        </Box>
        <Box margin={{vertical: '0.5em', left: '2em'}} width='20em'>
            {provider.name}
        </Box>
        <Box direction='row'
             align='center'
             flex={false}
             fill={false}>
            <FieldTag label={"Trackers:"} value={provider.num_trackers} state={provider.num_trackers > 0}/>
            <FieldTag label={"Patients:"} value={provider.num_patients} state={provider.num_patients > 0}/>
        </Box>
    </Box>)
}

const StatsHeader = (props) => {
    return(<Box direction='row'>
        <Box margin={{vertical: '0.5em'}} width='20em' flex={false}>
            Provider
        </Box>
        <Box direction='row'
             align='center'
             flex={false}
             fill>
            <StatTag value={"No Signup"} header/>
            <StatTag value={"No Login"} header/>
            <StatTag value={"No Journal"} header/>
            <StatTag value={"Success"} header/>
            <StatTag value={"Pt"} header/>
            <StatTag value={"Ob"} header/>
        </Box>
    </Box>)
}


const ProvidersStats = ({providers, stats, start_date, end_date, onChange, ...props}) => {
    const getFormattedDate = (date) => {
        let year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    const [start, setStart] = React.useState(getFormattedDate(start_date));
    const [end, setEnd] = React.useState(getFormattedDate(end_date));

    const getDate = (s) => {
        return new Date(Date.parse(s));
    }

    const vals = Object.values(providers);
    if (vals.length === 0)
        return null;

    const dateFormat = new Intl.DateTimeFormat(undefined, {
        month: 'short',
        day: 'numeric',
    });

    return (<ListContainer>
        <Box direction={'row'} margin={{bottom: '1.5em'}}>
            <Box margin={{right: '2em'}} flex={false} width={'20em'}>
                <DateInput
                    value={[start,end]}
                    buttonProps={{
                      margin: '1em',
                      round:  '0.75em',
                      label:  `${dateFormat.format(getDate(start),)} - ${dateFormat.format(getDate(end))}`,
                    }}
                    onChange={(e) => {
                        if (e.value !== undefined ) {
                            setStart(e.value[0])
                            setEnd(e.value[1])
                        }
                    }}
                />
            </Box>
            <Box margin={{horizontal: '2em', top: '1em'}}>
                <LoadingButton label={"Update"} onClick={(done) => {
                    onChange(getDate(start), getDate(end), done);
                }}/>
            </Box>
        </Box>
        <AutoSizer>
            {({width, height}) => {
                return <Table
                    noRowsRenderer={() => <ListItem> No Providers found. </ListItem>}
                    overscanRowCount={10}
                    height={height}
                    headerHeight={40}
                    rowGetter={({index}) => { return { provider: vals[index], stat: stats[vals[index].id] || {} }; }}
                    rowCount={vals.length}
                    rowHeight={60}
                    width={width} estimatedRowSize={width}>
                    <Column
                        dataKey="name"
                        cellDataGetter={(rowData)=> rowData}
                        headerRenderer={() => <StatsHeader/>}
                        cellRenderer={({rowIndex, rowData: row}) => {
                            return <StatItem provider={row.provider} stat={row.stat}/>
                        }}
                        width={width}
                    />
                </Table>
            }
            }
        </AutoSizer>
    </ListContainer>);
};

const StatTag = ({label, value, header=false, bad=false, ...props}) => {
    const state = value > 0 || (value?.length > 0 && value[0] !== '-');
    return (
        <Box flex={false}
             direction={'row'}
             pad='0.1em'
             width='6em'
             height='1.5em'>
            <Text textAlign='center'
                  margin={{horizontal: 'auto'}}
                  color={ header ? 'black': (state ? (bad ? theme.dash.bad : theme.dash.good) : 'lightgrey') }
                  weight={state ? 600 : (header ? 400 : 1)}>
                {value}
            </Text>
        </Box>);
}

const StatItem = ({provider, stat, ...props}) => {
    const label = (val) => {
        if (stat.trackers === 0) return '---';
        return `${parseInt(parseInt(val) / parseInt(stat.trackers) * 100)}%`;
    };

    return(<Box direction='row'>
        <Box margin={{vertical: '0.5em'}} width='20em' flex={false}>
            {provider.name}
        </Box>
        <Box direction='row'
             align='center'
             flex={false}
             fill>
            <StatTag value={label(stat.trackers - stat.signups)} bad/>
            <StatTag value={label(stat.trackers - stat.logins)} bad/>
            <StatTag value={label(stat.trackers - stat.journaled)} bad/>
            <StatTag value={label(stat.journaled)}/>
            <StatTag value={provider.num_patients} state={provider.num_patients > 0}/>
            <StatTag value={stat.trackers}/>
        </Box>
    </Box>)
}

const NoProvider = { name: "None", id: null };

const UserSearch = ({providers, stats, ...props}) => {
    const [ query, setQuery ] = React.useState("");
    const [ handle, setHandle ] = React.useState();
    const [ users, setUsers ] = React.useState([]);
    const [ searching, setSearching ] = React.useState(false);
    const [ provider, setProvider ] = React.useState(NoProvider);
    const [ role, setRole ] = React.useState(1);
    const [ user, setUser ] = React.useState(false);
    const [ cognito, setCognito ] = React.useState(false);

    const run_search = () => {
        let url_query = [`query=${query}`];
        const provider_id = provider.id;

        if (!!provider_id)
            url_query.push(`provider_id=${provider_id}`)
        if (!!role)
            url_query.push(`role=${role}`)

        url_query = url_query.join('&');

        if (query.length === 0 && !provider_id)
            return;

        setSearching(true);
        ApiCall(props.user, "get", `/admin/user/search?${url_query}`)
            .then((result) => {
                setSearching(false);
                setUsers(result)
            }).catch((ex) => {
                console.log(ex);
            });
    }

    const search_change = (event) => {
        const text = event.target.value;
        setQuery(text);
    }

    const provider_change = ({option}) => {
        setProvider(option);
    }

    React.useEffect(() => {
        clearTimeout(handle);
        setHandle(null);
        try {
            clearTimeout(handle);
        } catch(ex) {}
        const hdl = setTimeout(run_search, 250);
        setHandle(hdl);
    }, [provider, query, role])

    React.useEffect(() => {
        if (!user)
            return;

        ApiCall(props.user, "get", `/admin/user/${user.id}`)
            .then((result) => {
                setCognito(result);
            }).catch((ex) => {
            console.log(ex);
        });
    }, [user])

    return (
        <Box fill>
            <Select
                options={[NoProvider, ...Object.values(providers)]}
                value={provider.name}
                valueKey={{key: 'name', reduce: true}}
                labelKey={'name'}
                style={{textTransform: 'capitalize'}}
                onChange={provider_change}
            >
                {(rolem, ind) => {
                    return (
                        <Text margin='small' style={{textTransform: 'capitalize'}}>{rolem.name}</Text>);
                }}
            </Select><br/>
            <RoleSelect onChange={({option}) => setRole(option.role)}
                        options={roleMap}
                        value={role}
                        keyName={'role'}
                        valueName={'name'}
                        {...props}/><br/>
            <TextInput placeholder="User details"
                       value={query}
                       name='search'
                       onChange={search_change}/>
            { searching &&
                <Text margin={{left: '1em', vertical:'1em'}}>Waiting...</Text>
            }
            <Box fill flex direction='row'>
                { users.length > 0 &&
                    <Box width='36em' flex={false}>
                        <UserList users={users} user={user} setUser={setUser}/>
                    </Box>
                }
                { user &&
                    <Box fill flex={false} margin={{top: '1em'}}>
                        <UserInfo user={user} cognito={cognito}/>
                    </Box>
                }
            </Box>
        </Box>
    )
}

const UserInfo = ({user, cognito, ...props}) => {
    return (<Box direction='column' flex={false} fill>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            <Text>{FullName(user)}</Text>
            {!cognito ? <Text color='red'>+-> Cognito: [No User]</Text> : <Text>{cognito.enabled ? "+-> Cognito: [Enabled]" : "+-> Cognito: [Disabled]"}</Text>}
        </Box>
        {!!cognito &&
        <>
            <Box margin={{bottom: '0.15em'}} flex={false}>
                <Text>{user.email || "[No Email]"}</Text>
                {!!cognito && user.email !== cognito.email && <Text color='red'>
                    <br/>
                    Cognito Email: [{cognito.email}]
                </Text>}
                {cognito?.email_verified ? <Text>+-> [Verified]</Text> : <Text color="orange">+-> [Unverified]</Text>}
            </Box>
            <Box margin={{bottom: '0.15em'}} flex={false}>
                <Text>{user.phone || "[No Phone]"}</Text>
                {!!cognito && user.phone !== cognito.phone_number && <Text color='red' margin='0'>
                    Cognito Phone: [{cognito.phone_number}]
                </Text>}
                {cognito?.phone_number_verified ? <Text>+-> [Verified]</Text> : <Text color="orange">+-> [Unverified]</Text>}
            </Box>
        </>
        }
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Provider: {user.provider_id}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            {user.allow_admin ? "Allow Admin" : "Disallow Admin"}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Last Alert: {DaysSinceToday(user.last_alert)}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Last Reminder: {DaysSinceToday(user.last_reminder)}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Push Token: {user.push_token || "[No Token]"}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Alerts: {user.alert_level}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Messages: {user.message_count}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Notifications: {user.com_notifications ? "True" : "False"}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            Reminders: {user.reminder ? "True" : "False"}
        </Box>
    </Box>)
}

const UserItem = ({user, onSwitch, current, setUser, active, ...props}) => {
    return(<Box direction='column' flex={false} background={active && "#EEE"}>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            <Anchor label={FullName(user)} onClick={() => setUser(user)}/>
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            {user.email || "[no email]"}
        </Box>
        <Box margin={{bottom: '0.15em'}} flex={false}>
            {user.phone || "[no phone]"}
        </Box>
        <Box direction='row'
             align='center'
             flex={false}
             fill={false}
             margin={{top: '0.25em'}}>
            <FieldTag label={"Msg:"} value={user.num_messages} state={user.num_messages > 0}/>
            <FieldTag label={"Staff Msg:"} value={user.num_staff_messages} state={user.num_staff_messages > 0}/>
            <FieldTag label={"Links:"} value={user.num_links} state={user.num_links > 0}/>
            <FieldTag label={"Metas:"} value={user.num_metas} state={user.num_metas > 0}/>
            <FieldTag label={"Journals:"} value={user.num_journals} state={user.num_journals > 0}/>
        </Box>
    </Box>)
}


const UserList = ({users, user, setUser, ...props}) => {
    if (users.length === 0)
        return null;

    return (<ListContainer>
        <AutoSizer>
            {({width, height}) => {
                return <Table
                    noRowsRenderer={() => <ListItem> No users found. </ListItem>}
                    overscanRowCount={10}
                    height={height}
                    headerHeight={0}
                    rowGetter={({index}) => users[index]}
                    rowCount={users.length}
                    rowHeight={120}
                    width={width} estimatedRowSize={width}>
                    <Column
                        dataKey="name"
                        cellDataGetter={(rowData)=> rowData}
                        headerRenderer={() => {}}
                        cellRenderer={({rowIndex, rowData: list_user}) => {
                            return <UserItem user={list_user}
                                             active={list_user.id === user.id}
                                             setUser={setUser}
                                             index={rowIndex}/>
                        }}
                        width={width}
                    />
                </Table>; }
            }
        </AutoSizer>
    </ListContainer>);
}

const RoleSelect = ({options, value, keyName, valueName, onChange}) => (
    <Select
        options={options}
        value={value}
        valueKey={{key: keyName, reduce: true}}
        labelKey={valueName}
        style={{textTransform: 'capitalize'}}
        onChange={onChange}
    >
        {(rolem, ind) => {
            return (
                <Text margin='small' style={{textTransform: 'capitalize'}}>{rolem[valueName]}</Text>);
        }}
    </Select>);

const RoleBox = ({ changeRole, user, ...props }) => {
    const [ role, setRole ] = React.useState(user.role);
    return(
        <Box direction={'row-responsive'} width='40em' flex={false} fill={false} margin='1em'>
            <Box
                background='white'
                border="all"
                pad="1.5em"
                flex={false}
                fill={false}
                width='20em'
                height='8em'
                round="0.75em"
            >
                <Box width="large">
                    <RoleSelect onChange={({option}) => setRole(option.role)}
                                options={roleMap}
                                value={role}
                                keyName={'role'}
                                valueName={'name'}
                                {...props}/>
                    <LoadingButton margin={{top: '0.5em'}} label="Temporarily Change Role"
                                   loading_label="Updating User Info"
                                   onClick={() => changeRole(role)} primary/>
                </Box>
            </Box>
        </Box>);
}


export class AdminTabs extends Component {
    constructor(props) {
        super(props);

        const date = new Date();
        let dateOffset = (24*60*60*1000) * 30;
        let offset = date.getTime() - dateOffset;
        this.state = {
            stats: [],
            providers: {},
            provider: null,
            role: props.user.role,
            start: new Date(offset),
            end: date
        };
    }

    _toObj(x) {
        let obj = {};

        for ( let y in x )
            obj[x[y].id] = x[y];

        return obj;
    }

    componentDidMount() {

        ApiCall(this.props.user, 'get', '/providers')
            .then(result => {
                const prop = result.find((p) => p.id === this.props.user.provider.id)
                this.setState({
                    provider: prop,
                    providers: this._toObj(result)
                })
            })
            .catch(e => this.setState({error: e.message}));

        this._loadStats();
    }

    _loadStats(done) {
        const {start, end} = this.state;
        const endt = end.toISOString();
        const startt = start.toISOString();

        ApiCall(this.props.user, 'get', `/providers/stats?start=${startt}&end=${endt}`)
            .then(stats => {
                this.setState({ stats: this._toObj(stats) });
                if (done) done();
            })
            .catch(e => {
                this.setState({error: e.message});
                if (done) done();
            });
    }

    changeProvider = (done) => {
        ApiCall(this.props.user, 'post', '/users/current', {provider_id: this.state.provider.id} )
            .catch(e => this.setState({error: e.message}))
            .finally(()=>done());
    };

    changeRole = (role, done) => {
        this.props.updateRole(role);
    }

    render() {
        const { providers, stats, start, end } = this.state;
        const { user } = this.props;

        return (<Tabs alignControls="start" flex fill>
            <Tab title="Provider Stats">
                <ProvidersStats providers={providers} stats={stats}
                                start_date={start}
                                end_date={end}
                                onChange={(start, end, done) => {
                                    this.setState({start, end}, () => {
                                        this._loadStats(done);
                                    });
                                }}/>
            </Tab>
            <Tab title="Users">
                <UserSearch stats={stats} providers={providers} {...this.props}/>
            </Tab>
            <Tab title="Providers">
                <ProvidersList providers={providers} provider_id={user.provider.id} onSwitch={(provider) => {
                    this.setState({provider}, () => {
                        this.changeProvider(() => {
                            user.update_provider(provider);
                        })
                    });
                }}/>
            </Tab>
            <Tab title="Role Options">
                <RoleBox changeRole={this.changeRole} {...this.props}/>
            </Tab>
        </Tabs>);
    }
}


export class Admin extends Component {
    render() {
        return (
            <Box
                breakpoint='medium'
                flex={true}
                fill={true}
                pad={{left: '1.5em', right: '1.5em'}}
                height='auto'
                background="backgroundBody"
            >
                <Box direction="row-responsive" flex={false} width='100%' height="7em">
                    <Heading level="2" margin={{left: "small", top: 'auto', bottom: 'auto'}}>
                        Administrator Console
                    </Heading>
                </Box>
                <AdminTabs {...this.props}/>
            </Box>
        );
    }
}
